import { useSelector } from 'react-redux';
import ERC721 from '../Abi/erc721.json'
import DETH from '../Abi/erc20.json'
import Market from '../Abi/market.json'
import Stake from '../Abi/stake.json'
import config from '../views/config/config'
import Web3 from 'web3';
import Web3Utils from 'web3-utils'

var web3s = new Web3(config.RPC_URL)
// console.log("web3s@123",web3s);


export default function useContractProviderHook() {
    const { accountAddress, web3, web3p, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees, buyerFees } = useSelector(state => state.LoginReducer.ServiceFees);
   

    const Contract_Base_Validation = () => {
        if (!web3) return 'Connect Your Wallet'
        if (!accountAddress) return 'Connect Your Wallet'
        if (!coinBalance) return "You Don't have Enough Balance"
        else return ''
    }

    const contrat_connection = async (...data) => {
        if (web3) {
            var contract_value = await new web3.eth.Contract(
                ...data
            );
            return contract_value;
        }
    }

    const EnableStake = async (ownerAdd, ...data) => {
        try {
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            var contract_Method_Hash = await ConnectContract?.methods.nftStack(...data).send({
                from: ownerAdd
            })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (err) {
            console.log("stakeerr", err)
            return false
        }

    }

    const ClaimReward = async (ownerAdd, id) => {
        try {
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            var contract_Method_Hash = await ConnectContract?.methods.claim(id).send({
                from: ownerAdd
            })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (err) {
            console.log("claimerr", err)
            return false
        }

    }

    const NFTWithdraw = async (ownerAdd,...data) => {
        try {
            console.log('idddd',ownerAdd);
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            var contract_Method_Hash = await ConnectContract?.methods.nftWithdraw(...data).send({
                from: ownerAdd
            })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (err) {
            console.log("claimerr", err)
            return false
        }

    }

    const Getadminaddress = async () => {
        try {
            var ConnectContract = await contrat_connection(Market, config.TradeContract)
            console.log('gfdjiogoiydfoighdf111111',ConnectContract,config.TradeContract)
            var owneradd = await ConnectContract.methods.owner().call();
            console.log('gfdjiogoiydfoighdf',owneradd)
            return owneradd;
        }
        catch (err) {
            console.log("admingeterr",err)
            return false
        }
    }

    const GetPoll = async () =>{
        try {
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            var pooldata = await ConnectContract?.methods?.poolStatus(config.poolid).call()
            return pooldata;
        }catch(err){
            console.log("getpioolerr",err)
            return false
        }
    }

    const Getdayreward = async (...data) => {
        try {
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            var reward = await ConnectContract?.methods.getDayReward(...data).call()
            return ({ success: true, reward });
        }
        catch (err) {
            console.log("ERRINGETREWARDs",err)
            return ({ success: false });
        }
    }

    const Getreward = async (id,add,poolid,date) => {
        // console.log("GETREWARDDDDD",...data)
        try {
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            var reward = await ConnectContract?.methods.getReward(id,add,poolid,date).call()
            console.log("GETREWARDDDDDsssss",reward)

            return ({ success: true, reward });
        }
        catch (err) {
            console.log("ERRINGETREWARD",err)
            return ({ success: false });
        }
    }


    const GetApproveStatus = async ( Addr, stake) => {
        try {
            var ConnectContract = await contrat_connection( ERC721, Addr);
            var contract_Method_Hash;
            if (stake) {
                contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .isApprovedForAll(accountAddress, config.StakeAddress)
                        .call()
            }
            else {
                contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .isApprovedForAll(accountAddress, config.TradeContract)
                        .call()
            }

            return contract_Method_Hash

        }
        catch (e) {
            return 'error'
        }
    }
    const SetApproveStatus = async (data, Addr, stake) => {
        try {
            var ConnectContract = await contrat_connection( ERC721, Addr)
                ;
            var contract_Method_Hash;
            if (stake) {
                contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .setApprovalForAll(config.StakeAddress, true)
                        .send({
                            from: accountAddress
                        }).on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            else {
                contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .setApprovalForAll(config.TradeContract, true)
                        .send({
                            from: accountAddress
                        }).on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;

        }
        catch (e) {
            return false
        }
    }
    const get_receipt = async (HashValue) => {
        var receipt = await web3s.eth.getTransactionReceipt(HashValue);
        console.log("sdsadshadsadhfsa", receipt, HashValue)
        if (receipt) {
            return receipt
        }
        else {
            get_receipt(HashValue)
        }
    }
    const minting_721_1155 = async (...data) => {
        console.log("Multipledata", data);
        try {
            const ConnectContract = await contrat_connection(Market, config.TradeContract)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .minting(...data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            console.log('reciepppttttt', receipt)
            var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[2].topics[2])
            if (TokenCOunts) {
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                    tokenCounts: TokenCOunts
                }
                return need_data
            }
        }
        catch (e) {
            console.log("Contract Error", e)
            return false
        }


    }
    const approve_721_1155 = async (token_address, ...data) => {
        console.log("approve data", token_address, ...data)
        try {
            const ConnectContract = await contrat_connection(DETH, token_address)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .approve(...data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("err in approve", e)
            return false
        }
    }
    const Token_Balance_Calculation = async (token_Address, data) => {

        try {
            console.log('adddrreeeessss', token_Address, data)
            const ConnectContract = await contrat_connection(DETH, token_Address)
            var bidAMt = await ConnectContract.methods.balanceOf(data).call();

            return Number(web3.utils.fromWei(String(bidAMt)))
        }
        catch (e) {

            return 0
        }
    }
    var buy_bid_price_calculation = (val, decimal) => {
        console.log("val", val, decimal)
        var toMid = val * 1000000
        var serfee = (toMid / 100000000) * (web3p.utils.fromWei(String(buyerFees ? buyerFees : 1)) * 1000000)
        var totfee = serfee + toMid
        var tot2cont = web3.utils.toWei(String(Number(totfee / 1000000)).length > 18 ? String(Number(totfee / 1000000).toFixed(18)) : String(Number(totfee / 1000000)))
        var dec = decimal == 18 ? 18 : 18 - (decimal);
        var aprrove = ((tot2cont) / 10 ** dec)
        return (aprrove)
    }
    const cancel_order_721_1155 = async (data) => {
        try {
            var ConnectContract = await contrat_connection(Market, config.TradeContract)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .cancelOrder(data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (e) {
            return false
        }

    }
    var price_calculation = (data, roy) => {
        try {
            var price = web3.utils.toWei(data);
            var service_from_val = ((price * (sellerFees)) / 1e20)
            var royal_from_val = ((price * (roy * 1e18)) / 1e20)
            var my_val = 0, my_val_royal = 0, getVal = 0;
            if (String(service_from_val).includes('.') && String(service_from_val).split('.').pop().length > 18)
                my_val = service_from_val.toFixed(18)
            else
                my_val = service_from_val

            if (String(royal_from_val).includes('.') && String(royal_from_val).split('.').pop().length > 18)
                my_val_royal = royal_from_val.toFixed(18)
            else
                my_val_royal = royal_from_val
            var whole_val = (((price)) - my_val) / 1e18
            if (String(whole_val).includes('.') && String(whole_val).split('.').pop().length > 18)
                getVal = whole_val.toFixed(18)
            else
                getVal = whole_val
            return getVal

        }
        catch (e) {
            return false
        }
    }
    const place_order_721_1155 = async (...data) => {
        try {
            var ConnectContract = await contrat_connection(Market, config.TradeContract)
            var contract_Method_Hash = await
                ConnectContract.methods
                    .orderPlace(...data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("enakenemnfsd", e)
            return false
        }

    }
    const buy_721_1155 = async (Send, CoinName, ...data) => {
        try {
            const ConnectContract = await contrat_connection(Market, config.TradeContract)
            if (CoinName === "BNB" || CoinName === "ETH") {
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleToken(...data)
                        .send({
                            from: accountAddress,
                            value: Send
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            else {
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleWithToken(CoinName, ...data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            return false
        }

    }
    const allowance_721_1155 = async (token_Address, data) => {

        try {
            const ConnectContract = await contrat_connection(DETH, token_Address)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .allowance(data, config.TradeContract)
                    .call()
            return contract_Method_Hash

        }

        catch (e) {
            return false
        }

    }
    const accept_721_1155 = async (...data) => {
        try {
            if (web3 != null) {
                const ConnectContract = await contrat_connection(Market, config.TradeContract)
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .acceptBId(...data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                return need_data
            }
        }
        catch (e) {

            return false
        }

    }

    const GetOwner = async (data, Addr, Tokenaddr) => {
        console.log('functioninputtt',  Addr, Tokenaddr)
        try {
            var ConnectContract = await contrat_connection( ERC721 , Addr)
            console.log('coooonnnnn', ConnectContract)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .ownerOf(Tokenaddr)
                    .call()
            return contract_Method_Hash

        }
        catch (e) {
            console.log('errrorrrr', e)
            return 'error'
        }
    }

    const GetContractOwner = async (data, Addr) => {
        console.log('functioninputtt', Addr)
        try {
            var ConnectContract = await contrat_connection( ERC721 , Addr)
            console.log('coooonnnnn', ConnectContract)
            var contractowner = await
                ConnectContract
                    .methods
                    .owner()
                    .call()
            return contractowner

        }
        catch (e) {
            console.log('errrorrrr', e, String(e))
            return 'error'
        }
    }

    const Current_NFT_Balance = async (ownerdet, data) => {

        try {
            var currbalance;
            if ((data.ContractType === "721" || data.ContractType === 721)) {
                const ConnectContract = await contrat_connection(ERC721, data.ContractAddress)
                currbalance = await ConnectContract.methods.ownerOf(ownerdet.NFTId).call();
                console.log('ballllaanneceeee1111', currbalance)
                if ((String(currbalance).toLowerCase()) === (String(ownerdet.NFTOwner).toLowerCase())) { return '1'; }
                else {
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: "0",
                    }
                    // var balupd = await NftbalanceUpdate(payload);
                }
                console.log('ballllaanneceeee', currbalance)
            }
            return String(currbalance);
        }
        catch (e) {
            console.log('errorrrr,e', e)
            return 0
        }
    }

    const lazyminting_721_1155 = async (CoinName, Send, ...data) => {
        console.log("VANTHADATA",CoinName,Send, ...data)
        var ConnectContract = "";
        var contract_Method_Hash
        try {
            ConnectContract = await contrat_connection(Market,config.TradeContract)

            if(CoinName == "BNB" || CoinName == "ETH"){
                console.log("METODASS", ConnectContract.methods)
                contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .lazyMinting(...data)
                            .send({
                                from: accountAddress,
                                value: Send
                            })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
            }else{
                console.log("METODASSaa", ConnectContract.methods,"valuenotsend")
                contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .lazyMinting(...data)
                            .send({
                                from: accountAddress,
                            })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
            }



            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            console.log("BUYMINTHASH", receipt)
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
                Tokenid: Web3Utils.hexToNumber(Number(receipt.logs[0].topics[3]))
            }
            return need_data
        }
        catch (err) {
            console.error("err in lazymint", err)
        }

    }

    return {
        lazyminting_721_1155,
        Contract_Base_Validation,
        GetApproveStatus,
        SetApproveStatus,
        minting_721_1155,
        approve_721_1155,
        Token_Balance_Calculation,
        buy_bid_price_calculation,
        cancel_order_721_1155,
        price_calculation,
        place_order_721_1155,
        buy_721_1155,
        allowance_721_1155,
        accept_721_1155,
        GetOwner,
        GetContractOwner,
        Current_NFT_Balance,
        Getadminaddress,
        EnableStake,
        ClaimReward,
        Getreward,
        Getdayreward,
        NFTWithdraw,
        GetPoll
    };




}
