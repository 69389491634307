import React, { useState, useEffect, useMemo, useCallback } from "react";
import MetaMask from "../assets/images/svg/metamask.svg";
import Coinbase from "../assets/images/svg/coinbase.svg";
import Torus from "../assets/images/svg/torus.svg";
import Fortmatic from "../assets/images/svg/fortmatic.svg";
import Upload from "../assets/images/uploadimg.png";
import BNB from "../assets/images/ethe.svg";
import Wallet1 from "../assets/images/svg/icon-wallet-1.svg";
import Wallet2 from "../assets/images/svg/icon-wallet-2.svg";
import Wallet3 from "../assets/images/walletconnect.png";
import Wallet4 from "../assets/images/TWT.png";
import createitems from "../assets/images/createitemm.png";
import Cardmodel from '../views/carduploadpreview'
import randomInteger from 'random-int';
import Web3 from 'web3'
import excelfile from '../assets/images/joyimg/joyfun1excel.xlsx'
// import leftcurve from '../assets/images/joyimg/leftcurve.png';
// import rightcurve from '../assets/images/joyimg/rightcurve.png';

import { NavLink, useLocation } from "react-router-dom";
import Header from "../separate/Header1.js";
import Footer from "../separate/Footer1";
import Countdown from "react-countdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "./Card";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useSelector } from "react-redux";
import useContractProviderHook from "../actions/contractProviderHook";
import config from "./config/config";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import { ImgValidation } from "../actions/validations";
import { CollectionByCreator, nftCreate, imgageupload } from "../actions/axioss/nft.axios";
import { createImg } from "../actions/axioss/user.axios";

import leftcurve from '../assets/images/joyimg/leftcurve.png';
import rightcurve from '../assets/images/joyimg/rightcurve.png';
import { Circles } from 'react-loader-spinner'
// import { Link } from "react-router-dom";
import {
    nftNameValidation,
    NFTImageUpload,
    CreateNFT,
} from "../actions/axioss/nft.axios";
import { useNavigate, Link } from "react-router-dom";
import CreateCollection from "./seperatemodals/CreateCollection";
import moment from "moment";
import Select from "react-select";
import CLockModal from "../views/seperatemodals/ClockModal";

// var fs = require('fs'),
// http = require('http'),
// https = require('https');
// var Stream = require('stream').Transform;

import preview from "../assets/images/joyimg/Uplaodbrandnew.png";
import bannerz from "../assets/images/banner.png";
import endless from "../assets/images/endless.png";
import product52 from "../assets/images/product52.jpg";
import imageprev from "../assets/images/imgpreview.png";

export default function Create() {

    const location1 = useLocation();
    const { state } = location1;
    console.log("ghh", state);



    const [loading, setloading] = useState();

    const { sellerFees, buyerFees } = useSelector(
        (state) => state.LoginReducer.ServiceFees
    );
    const { web3p, web3, accountAddress } = useSelector(
        (state) => state.LoginReducer.AccountDetails
    );

    const { payload } = useSelector(
        (state) => state.LoginReducer.User
    );
    const { AdminAddress, Logedin } = useSelector(
        (state) => state.LoginReducer
    );

    useEffect(() => {
        console.log("LOGEDIN", Logedin);
        admincheck()

    }, [Logedin])

    //     const  getadminaddress=async()=>{
    //         var data= await ContractCall.Getadminaddress()
    //       console.log("GETADDMINADDRESS",data);
    //         dispatch({
    //           type: "Admin_Section",
    //           Admin_Section: {
    //               AdminAddress : String(data).toLowerCase(),
    //           },
    //       });
    //    }


    // const add= useCallback(
    //     ()=>{
    //         console.log("KUTTY")
    //         if( payload && AdminAddress && AdminAddress !== payload?.WalletAddress){
    //             console.log("Patthi kangal");
    //             setTimeout(() => {
    //                     toast.error("Sorry Admin only allowed")
    //                    push('/')
    //             }, 2000);
    //         }
    //     },[payload]
    // )

    const admincheck = async () => {
     
        console.log("AdminAddress !== payload?.WalletAddress", AdminAddress !== payload?.WalletAddress, payload?.WalletAddress, AdminAddress, Logedin);
        if (payload && AdminAddress && AdminAddress !== payload?.WalletAddress) {
            setTimeout(() => {
                toast.error("Sorry Admin only allowed")
                push('/')
            }, 2000);
        }

    }
    const [preview, setPreview] = useState(false)
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [filename, setFileName] = useState('')
    const [collection, setCollection] = React.useState(1);

    let renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Auction Completed!</span>;
        } else {
            return (
                <span>
                    {" "}
                    <span className="hourds">{formatTime(hours)} h</span>{" "}
                    <span className="semicolan">:</span>{" "}
                    <span className="hourds">{formatTime(minutes)} m</span>{" "}
                    <span className="semicolan">:</span>{" "}
                    <span className="hourds">{formatTime(seconds)} s</span>{" "}
                </span>
            );
        }
    };
    let formatTime = (time) => {
        return String(time).padStart(2, "0");
    };
    const push = useNavigate();

    const [aitext, setAitext] = useState('')
    const { currency, Categorys } = useSelector((state) => state.LoginReducer);
    console.log('currrencccyy', currency, Categorys)
    const initialTokenValue = {
        NFTName: "",
        NFTQuantity: 1,
        NFTOrginalImage: "",
        NFTOrginalImagePreview: "",
        NFTThumpImage: "",
        NFTThumpImagePreview: "",
        NFTOrginalImageIpfs: "",
        NFTThumpImageIpfs: "",
        CompressedFile: "",
        CompressedThumbFile: "",
        NFTDescription: "",
        PutOnSaleType: "UnlimitedAuction",
        PutOnSale: false,
        NFTPrice: "",
        NFTMinimumBid: "",
        ClockTime: "",
        EndClockTime: "",
        NFTRoyalty: "",
        NFTProperties: [],
        NFTCreator: accountAddress,
        NFTOwner: accountAddress,
        HashValue: "",
        MetFile: "",
        MetaData: "",
        ContractAddress: "",
        ContractType: "",
        Category: Categorys.length > 0 && Categorys[0].label,
        CoinName: "",
        UnlockContent: "",
        CollectionName: "DITMAX",
        CollectionNetwork: config.COIN_NAME,
        CollectionSymbol: "",
        isMessageapprove: "",
        isPricenotification: "",
        isPromotion: "",
        islegalalert: "",
        imgfile: []
    };

    const [checked, setChecked] = useState(false)

    const [NFTFormValue, setNFTFormValue] = useState(initialTokenValue);
    const [ValidateError, SetValidateError] = useState({});
    const [FormButton, SetFormButton] = useState("start");
    const [ApproveButton, SetApproveButton] = useState("start");
    const [UploadButton, SetUploadButton] = useState("stop");
    const [MintButton, setMintButton] = useState("stop");
    const [theme, setTheme] = useState(false);
    const [show8, setShow8] = useState(false);
    const [location, SetLocation] = useState("");
    const [OpenPopup, SetOpenPopup] = useState("");
    const [CreateCollectionState, SetCreateCollectionState] = useState([]);
    const [aiurl, setAiurl] = useState('')
    const modelexcel = "https://docs.google.com/spreadsheets/d/1_i9m41edB9thF1mVIEv5GALAY8gzvixxHu8Ir-aFFJM/edit?usp=sharing";
    const [listforsalenow, setlistforsalenow] = useState(false);
    const [list, setlist] = useState("timed_auction");
    const [start, setstart] = useState("");
    const [end, setend] = useState("");
    const [number, setNumber] = useState([0]);
    const [key, setKey] = useState({});
    const [Value, setValue] = useState({});
    const ContractCall = useContractProviderHook();
    const options = [
        { value: "BNB", label: "BNB" },
        { value: "WETH", label: "WETH" },
    ];
    const startingdate = [
        { value: "List Immediately", label: "List Immediately" },
        { value: "Scheduled Listing", label: "Scheduled Listing" },
    ];
    const enddate = [
        { value: "1 day", label: "1 day" },
        { value: "2 days", label: "2 days" },
        { value: "Scheduled Listing", label: "Scheduled Listing" },
    ];
    const handleClose8 = () => setShow8(false);
    const [selectedOption, setSelectedOption] = useState("");
    useEffect(() => {
        let path = collection == 1 ? "Single" : "Multiple";
        SetLocation(path);
        let type = collection == 1 ? 721 : 1155;
        let Addr = collection == 1 ? config.ERC721 : config.ERC1155;
        setNFTFormValue({
            ...NFTFormValue,
            ...{
                ["ContractAddress"]: Addr,
                ["ContractType"]: type,
            },
        });
        CollectionByCreate({
            Type: collection == 1 ? 721 : 1155,
            Creator: accountAddress,
        });
    }, [collection, location, accountAddress]);

    const DateSelection = (e, data) => {
        // console.log('datttteeeee',e.value,data,e.value == "Scheduled Listing")
        if (data == "start") {
            if (e.value == "List Immediately")
                setNFTFormValue({
                    ...NFTFormValue,
                    ...{
                        ["ClockTime"]: moment(new Date()).format("YYYY-MM-DD h:mm:ss a"),
                    },
                });
            else if (e.value == "Scheduled Listing") SetOpenPopup("ClockTime");
        } else {
            if (e.value == "1 day") {
                console.log('dateeeee', new Date(new Date(NFTFormValue.ClockTime).setDate(new Date(NFTFormValue.ClockTime).getDate() + 1)))
                if (NFTFormValue.ClockTime === "") {
                    setNFTFormValue({
                        ...NFTFormValue,
                        ...{
                            ["EndClockTime"]: moment(
                                new Date(new Date().setDate(new Date().getDate() + 1))
                            ).format("YYYY-MM-DD h:mm:ss a"),
                        },
                    });
                }
                else {
                    setNFTFormValue({
                        ...NFTFormValue,
                        ...{
                            ["EndClockTime"]: moment(
                                new Date(new Date(NFTFormValue.ClockTime).setDate(new Date(NFTFormValue.ClockTime).getDate() + 1))
                            ).format("YYYY-MM-DD h:mm:ss a"),
                        },
                    });
                }
            } else if (e.value == "2 days") {

                if (NFTFormValue.ClockTime === "") {
                    setNFTFormValue({
                        ...NFTFormValue,
                        ...{
                            ["EndClockTime"]: moment(
                                new Date(new Date().setDate(new Date().getDate() + 2))
                            ).format("YYYY-MM-DD h:mm:ss a"),
                        },
                    });
                }
                else {
                    setNFTFormValue({
                        ...NFTFormValue,
                        ...{
                            ["EndClockTime"]: moment(
                                new Date(new Date(NFTFormValue.ClockTime).setDate(new Date(NFTFormValue.ClockTime).getDate() + 2))
                            ).format("YYYY-MM-DD h:mm:ss a"),
                        },
                    });
                }
            }
            else if (e.value == "Scheduled Listing") SetOpenPopup("EndClockTime");
        }
    };

    const YouWillGet = useMemo(() => {
        return ContractCall.price_calculation(NFTFormValue.NFTPrice);
    }, [NFTFormValue.NFTPrice]);
    const setClockValue = (data, date) => {
        setNFTFormValue({
            ...NFTFormValue,
            ...{ [data]: moment(date).format("YYYY-MM-DD h:mm:ss a") },
        });
    };

    const CollectionByCreate = async (data) => {
        // console.log('fhbddghshgdh',data)
        data.from = "create"
        let Resp = await CollectionByCreator(data);
        SetCreateCollectionState(Resp?.data);
    };

    // input field onchange function
    // const onChange = (e, acceptedfile, type) => {
    //     // if(){

    //     // }
    //     // console.log('ffgdvgfdaf',accountAddress)
    //     if (accountAddress) {
    //         SetFormButton("start");
    //         SetValidateError({})
    //         if (e && e.target) {
    //             const { files, value, id, name } = e.target;
    //             if (id == "NFTRoyalty" || id == "NFTPrice" || id == "NFTMinimumBid") {
    //                 const checkprice = /^\d*\.?\d*$/;
    //                 if (checkprice.test(value)) setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
    //             }
    //             else {
    //                 setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
    //             }
    //             // console.log("form data", id, value);
    //         }
    //         if (acceptedfile) {
    //             console.log("Fileeeeeeee", acceptedfile[0], "type", type);
    //             // var reader = new FileReader()
    //             var file = acceptedfile[0];
    //             var validExtensions = [
    //                 "png",
    //                 "gif",
    //                 "webp",
    //                 "mp4",
    //                 "PNG",
    //                 "jpg",
    //                 "JPEG",
    //                 "jpeg",
    //                 "JPG",
    //                 "mp3",
    //                 "aac",
    //                 "AAC",
    //                 "flac",
    //                 "FLAC",
    //                 "WEBM",
    //                 "webm",
    //                 "ogv",
    //                 "OGV",
    //             ];
    //             var validExtensionsthump = [
    //                 "png",
    //                 "webp",
    //                 "PNG",
    //                 "jpg",
    //                 "JPEG",
    //                 "jpeg",
    //                 "JPG",
    //             ];
    //             var fileName = file.name;
    //             var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    //             // console.log(
    //             //   "kgsdgh",
    //             //   fileNameExt,
    //             //   validExtensions,
    //             //   validExtensions.some((val) => fileNameExt === val)
    //             // );
    //             if (!validExtensions.some((val) => fileNameExt === val)) {
    //                 toast.error(
    //                     "Only these file types are accepted : " + validExtensions.join(", ")
    //                 );
    //             }
    //             if (type == "Orginal") {
    //                 console.log("original", acceptedfile[0]);
    //                 setNFTFormValue({
    //                     ...NFTFormValue,
    //                     ...{ ["NFTOrginalImage"]: acceptedfile[0] },
    //                 });
    //                 // reader.readAsDataURL(acceptedfile[0]);
    //                 // console.log("Orginal calling", reader)
    //                 // reader.onloadend = function (e) {
    //                 //     setNFTFormValue({ ...NFTFormValue, ...{ ['NFTOrginalImagePreview']: reader.result } })
    //                 // }.bind(this)
    //             }
    //             if (type == "Thump") {
    //                 console.log("THUM{P")
    //                 if (!validExtensionsthump.some((val) => fileNameExt === val)) {
    //                     toast.error(
    //                         "Only these file types are accepted : " + validExtensionsthump.join(", ")
    //                     );
    //                 } else {
    //                     setNFTFormValue({
    //                         ...NFTFormValue,
    //                         ...{ ["NFTThumpImage"]: acceptedfile[0] },
    //                     });
    //                 }
    //                 // reader.readAsDataURL(acceptedfile[0]);
    //                 // reader.onloadend = function (e) {
    //                 //     setNFTFormValue({ ...NFTFormValue, ...{ ['NFTThumpImagePreview']: reader.result } })
    //                 // }.bind(this)
    //             }
    //         }
    //         if (NFTFormValue.NFTCreator == "") {
    //             setNFTFormValue({
    //                 ...NFTFormValue,
    //                 ...{ ["NFTCreator"]: accountAddress, ["NFTOwner"]: accountAddress },
    //             });
    //         }
    //     }
    //     else {
    //         toast.error("Connect Wallet To create NFT");
    //     }
    // };
const [previeww ,setdispreview]=useState(false)

    const previewchange = async () => {
        if (NFTFormValue?.imgfile?.length > 0) {
            setPreview(!preview)
        }
        else {
            toast.warning("please upload the image folder")
            setdispreview(true)
        }
    }
    const onChange = (e, acceptedfile, type) => {

        SetFormButton("start")
        // const { files, value, id, name } = e.target;
        if (!type) {
            const { files, value, id, name } = e.target;

            if (id == "NFTRoyalty" || id == "NFTPrice" || id == "NFTMinimumBid") {
                const checkprice = /^\d*\.?\d*$/;
                if (checkprice.test(value)) setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
            }
            else {
                setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
            }
        }
        if (type == 'Orginal') {
            // console.log("FILESS",e,acceptedfile,acceptedfile.length)
            setNFTFormValue({
                ...NFTFormValue,
                ...{ ["imgfile"]: acceptedfile },
            });
            setdispreview(false)

            toast.success("Image Folder Uploaded")
            // (acceptedfile)imageUpload
        }

        if (type == "excel") {
            console.log("EXCEL", e.target.files)
            var fileNameExt = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf(".") + 1);
            console.log('====================================');
            console.log(fileNameExt);
            console.log('====================================');
            if (fileNameExt == "xlsx") {
                setFileName(e.target.files[0].name)
                setNFTFormValue({
                    ...NFTFormValue,
                    ...{ ["excelfile"]: e.target.files[0] },
                });
                toast.success("Excel file Uploaded")
            }
            else {
                toast.warning("Please upload Excel file only")
            }
        }


    };

    const Validation = async (data) => {
        let ValidateError = {};
        const {

            NFTPrice,

            NFTRoyalty,

            CoinName,

            excelfile,
            imgfile
        } = data;

        if (!NFTRoyalty) ValidateError.NFTRoyalty = "Royalty Required";
        else if (isEmpty(NFTRoyalty))
            ValidateError.NFTRoyalty = "Royalty Must Be Greate Than 0";
        else if (isNaN(NFTRoyalty) === true)
            ValidateError.NFTRoyalty = "Royalty must be a number";
        else if (Number(NFTRoyalty) < 0) ValidateError.NFTRoyalty = "Royalty must be Greater than 0"
        else if (Number(NFTRoyalty) > 20) ValidateError.NFTRoyalty = "Royalty Must be less than 20";
        else if (Number(NFTRoyalty) % 1 !== 0) ValidateError.NFTRoyalty = "Royalty must be a Whole Number"

        if (
            isEmpty(NFTPrice)
        )
            ValidateError.NFTPrice = "NFTPrice Required";
        if (
            !CoinName
        )
            ValidateError.CoinName = "CoinName Required";

        if (imgfile.length == 0) {
            ValidateError.NFTOrginalImage = "File/Folder Required";
        }

        if (!excelfile) {
            ValidateError.excelfile = "Excel file Required";
        }

        if (isNaN(NFTPrice) === true)
            ValidateError.NFTPrice = "NFT Price Should Be a Number";
        else if (Number(NFTPrice) <= 0 && PutOnSale == true)
            ValidateError.NFTPrice = "NFTPrice should be above Zero";

        return ValidateError;
    };



    //NFT Initial Approve
    const TokenApproveCall = async () => {
        SetApproveButton("process");
        const id = toast.loading("Approve in process");
        const cont = await ContractCall.SetApproveStatus(
            location,
            NFTFormValue.ContractAddress
        );
        toast.update(id, {
            render: cont ? "Approved Successfully" : "Approved Failed",
            type: cont ? "success" : "error",
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
        });
        if (cont.status) {
            SetApproveButton("done");
            SetUploadButton("start");
        } else SetApproveButton("try");
    };


    async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
        const response = await fetch(url, { mode: 'no-cors' });
        console.log("rssssss", response)
        const data = await response.blob();
        console.log("daaaattttttaaaaaa", data);
        return new File([data], name, {
            type: data.type || defaultType,
        });



    }




    //Upload image in IPFS
    async function UploadIPFScall() {
        const {
            NFTCreator,
            NFTThumpImage,
            // NFTOrginalImage,
            NFTName,
            NFTDescription,
            NFTProperties
        } = NFTFormValue;
        SetUploadButton("process");
        const id = toast.loading("Uploading  File");
        var NFTOrginalImage = NFTFormValue.NFTOrginalImage;
        // var file;
        var Resp;

        if (NFTOrginalImage.toString().indexOf('https') == 0) {
            // NFTOrginalImage = await getFileFromUrl(NFTOrginalImage, 'example.jpg')
            console.log("nft ai", NFTOrginalImage)
            Resp = await NFTImageUpload({
                'ai': true,
                NFTCreator: NFTCreator ? NFTCreator : accountAddress,
                NFTThumpImage,
                NFTOrginalImage,
                NFTName,
                NFTDescription,
                NFTProperties: JSON.stringify(NFTProperties)
            });

            //   console.log("fileeeee",file);
        }
        else {
            Resp = await NFTImageUpload({
                NFTCreator: NFTCreator ? NFTCreator : accountAddress,
                NFTThumpImage,
                NFTOrginalImage,
                NFTName,
                NFTDescription,
                NFTProperties: JSON.stringify(NFTProperties)
            });
        }
        // let Resp = await NFTImageUpload({
        //   NFTCreator: NFTCreator ? NFTCreator : accountAddress,
        //   NFTThumpImage,
        //   file,
        //   NFTName,
        //   NFTDescription,
        //   NFTProperties: JSON.stringify(NFTProperties)
        // });
        if (Resp.success == "success") {
            // console.log("Resp.data", Resp.data);
            setNFTFormValue({ ...NFTFormValue, ...Resp.data });
            SetUploadButton("done");
            setMintButton("start");
        } else {
            SetUploadButton("try");
        }
        toast.update(id, {
            render: Resp.msg,
            type: Resp.success,
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
        });
    }

    //NFT Mint Function
    async function MintCall() {
        const id = toast.loading("Minting Processing");
        console.log("Minting Process", NFTFormValue, new Date(NFTFormValue.ClockTime));
        setMintButton("process");
        var _data = NFTFormValue;
        // console.log("NFTFormValue", NFTFormValue);
        _data.activity = "Mint";
        _data.NFTPrice == 0 ? _data.NFTPrice = '' : _data.NFTPrice = _data.NFTPrice;
        let ENc = window.btoa(JSON.stringify(_data));
        const cont = await ContractCall.minting_721_1155(
            // config.IPFS + NFTFormValue.MetaData,
            NFTFormValue.MetaData,
            // [accountAddress, accountAddress],
            [
                NFTFormValue.NFTQuantity,
                NFTFormValue.ContractType,
                web3?.utils.toWei(NFTFormValue.NFTRoyalty),
                web3.utils.toWei(
                    (NFTFormValue.PutOnSaleType == "FixedPrice"
                        ? NFTFormValue.NFTPrice
                        : "0"
                    ).toString()
                ),
                // 0,
            ],
            // [web3?.utils.toWei("100")],
            // [accountAddress],
            ENc
        );
        if (cont) {
            if (NFTFormValue.PutOnSaleType === "TimedAuction") {
                _data.ClockTime = new Date(NFTFormValue.ClockTime);
                _data.EndClockTime = new Date(NFTFormValue.EndClockTime)
            }
            _data.HashValue = cont.HashValue;
            _data.NFTId = cont?.tokenCounts?.toString();
            _data.click = `${config.FRONT_URL}/info/${NFTFormValue.CollectionNetwork
                }/${NFTFormValue.ContractAddress
                }/${accountAddress}/${cont?.tokenCounts?.toString()}`;

            let Resp = await CreateNFT(_data);
            toast.update(id, {
                render: Resp.msg,
                type: Resp.success,
                isLoading: false,
                autoClose: 1000, closeButton: true, closeOnClick: true
            });
            if (Resp.success == "success") {
                setMintButton("done");
                toast.update(id, {
                    render: "Token Minted successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 1000, closeButton: true, closeOnClick: true
                });
                push(`/profile/${payload?.CustomUrl}`, { state: { Tab: 'owned' } });
                // push("/user/" + payload.CustomUrl ? payload.CustomUrl  : payload.ProfileUrl  );
            } else {
                toast.update(id, {
                    render: "Transaction Failed",
                    type: "error",
                    isLoading: false,
                    autoClose: 1000, closeButton: true, closeOnClick: true
                });
                setMintButton("try");
            }
        } else {
            toast.update(id, {
                render: "Transaction Failed",
                type: "error",
                isLoading: false,
                autoClose: 1000, closeButton: true, closeOnClick: true
            });
            setMintButton("try");
        }
    }

    useEffect(() => {
        CurrecyChange();
    }, [currency, NFTFormValue.PutOnSaleType])//NFTFormValue.PutOnSaleType,

    const CurrecyChange = () => {
        if (NFTFormValue.PutOnSaleType === "FixedPrice")
            setNFTFormValue({ ...NFTFormValue, ...{ ["CoinName"]: currency[0]?.label } })
        else if (NFTFormValue.PutOnSaleType === "TimedAuction")
            setNFTFormValue({ ...NFTFormValue, ...{ ["CoinName"]: currency[1]?.label } });
        else
            setNFTFormValue({ ...NFTFormValue, ...{ ["CoinName"]: "" } });
    }



    const PutOnSale = () => {
        // CurrecyChange();
        if (NFTFormValue.PutOnSale === false) {
            setNFTFormValue({
                ...NFTFormValue,
                ...{
                    ["PutOnSale"]:
                        !NFTFormValue.PutOnSale,
                    ["PutOnSaleType"]: "FixedPrice"
                },
            })
        }
        else {
            setNFTFormValue({
                ...NFTFormValue,
                ...{
                    ["PutOnSale"]:
                        !NFTFormValue.PutOnSale,
                    ["PutOnSaleType"]: "UnlimitedAuction",
                    ["NFTPrice"]: 0,
                    ["NFTMinimumBid"]: 0,
                    ["ClockTime"]: "",
                    ["EndClockTime"]: "",
                },
            })
        }
    }
    const CloseModal = () => {
        SetOpenPopup("")
    }
    var validStart = function (current) {
        var yesterday = moment().subtract(1, 'day')
        //return current.isAfter(new Date())&&current.isBefore(new Date(EndClocktime));
        return current.isAfter(yesterday);
    }
    var validEnd = function (current) {
        return current.isAfter(NFTFormValue.ClockTime ? new Date(NFTFormValue.ClockTime) : undefined);
    }




    const ChooseCollection = (symbol, category) => {
        if (NFTFormValue.CollectionSymbol !== symbol) {
            setNFTFormValue({ ...NFTFormValue, ...{ ["CollectionSymbol"]: symbol, ["Category"]: category, }, })
        }
        else {
            setNFTFormValue({ ...NFTFormValue, ...{ ["CollectionSymbol"]: "", ["Category"]: Categorys.length > 0 && Categorys[0].label } })
        }
    }





    const imageUpload = async () => {
        var Error = await Validation(NFTFormValue);
        SetValidateError(Error)
        console.log("Form error", Error);
        if (isEmpty(Error)) {

            const id = toast.loading("Minting Processing");

            setMintButton("process");
            console.log("NFTFORMVALUE", NFTFormValue);

            var sendfiles = new FormData()
            var hash = await _signcall()
            console.log("signnnnndddd",hash)
            // debugger

            if (NFTFormValue.imgfile.length > 0) {
                var data = await Promise.all(NFTFormValue.imgfile.map((img) => {
                    sendfiles.append("imgfile", img)
                }))
                sendfiles.append("excelfile", NFTFormValue?.excelfile)
                sendfiles.append("creatoraddress", accountAddress)
                sendfiles.append("royalty", NFTFormValue.NFTRoyalty)
                sendfiles.append("price", NFTFormValue.NFTPrice)
                sendfiles.append("Hash", hash.signhash)
                sendfiles.append("nonce", hash.tot)
                sendfiles.append("randomname", hash.password)
                sendfiles.append('coinname', NFTFormValue.CoinName)

                sendfiles.append("conctractaddress", config.ERC721)
                var Resp = await imgageupload(sendfiles)
                toast.update(id, {
                    render: Resp.msg,
                    type: Resp.success,
                    isLoading: false,
                    autoClose: 1000, closeButton: true, closeOnClick: true
                });
                if (Resp.status) {
                    setMintButton("done");
                    toast.update(id, {
                        render: "Token Minted successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 1000, closeButton: true, closeOnClick: true
                    });
                    push(`/profile/${payload?.CustomUrl}`, { state: { Tab: 'owned' } });
                    // push("/user/" + payload.CustomUrl ? payload.CustomUrl  : payload.ProfileUrl  );
                } else {
                    toast.update(id, {
                        render: Resp.msg,
                        type: "error",
                        isLoading: false,
                        autoClose: 1000, closeButton: true, closeOnClick: true
                    });
                    setMintButton("try");
                }

            }
        }


    }

    const _signcall = async () => {
        if (web3) {
            var generator = require('generate-password');
            const passwords = require('secure-random-password');
            var TokenPrice = NFTFormValue.NFTPrice
            var randomNum =
                randomInteger(10000000, 100000000);
            console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
            var password;
            try {
                password = passwords.randomPassword({ length: 10, characters: [passwords.lower, passwords.upper, passwords.digits] })
                    ;
            }
            catch (err) {
                console.log('lkfadjlfjd', err)
            }
            console.log("passwordddd", password, TokenPrice);
            if (web3) {
                var web3RpcPro = new Web3(web3.providers);
                console.log("asddfsfsdvbvbvcb", web3RpcPro, payload.WalletAddress);
                const to = payload.WalletAddress
                const _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3.utils.toWei(String(TokenPrice));
                console.log("ajhghjas", _amount);
                const _nonce = Date.now();
                var tot = _nonce + Number(randomNum);
                console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
                const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
                console.log("asdsadasfdafaf", result);
                const signhash = await web3.eth.personal.sign(result, to);
                console.log("Signature", result, signhash)
                if (signhash) {
                    return ({ signhash: signhash, tot: tot, password: password })
                }
            }
        }
    }




    return (
        <>
            {preview ? <>

                <div className="inheader">
                    <Header />
                    <div className="faq">
                        <section className="tf-faq faqsection add-nft-inner">
                            {/* <img src={leftcurve} className="leftcurve" alt="golds" />
                        <img src={rightcurve} className="rightcurve" alt="golds" /> */}
                            <div className="tf-container">
                                <h2 className="headingzz pt-4 pb-5">Upload<span style={{ color: 'black' }}>Files</span></h2>

                                <div className="bottom-button pr-0 text-left  mb-3">
                                    <Button onClick={() => setPreview(!preview)}
                                        className="tf-button active mb-5 mx-0"
                                    >
                                        Back
                                    </Button>
                                </div>


                                <div className="row">
                                    {NFTFormValue?.imgfile.map((img) => {
                                        var objurl = URL.createObjectURL(img)

                                        return (
                                            <div className="col-md-3">

                                                <Cardmodel
                                                    product={objurl}
                                                    price = {`${NFTFormValue.NFTPrice}`}
                                                    coin = {`${NFTFormValue.CoinName}`}
                                                    from = {`preview`}
                                                />



                                            </div>
                                        )
                                    })}

                                </div>

                            </div>

                        </section>

                    </div>
                    <Footer />
                </div>
            </>

                :
                <>

                    <div className="inheader">

                        <Header />
                        <div className="create">
                            <img src={leftcurve} className="leftcurve" alt="golds" />
                            <img src={rightcurve} className="rightcurve" alt="golds" />




                            <section className="tf-add-nft">
                                <div className="tf-container">
                                    <div className="add-nft-inner padright">
                                        <div className="tf-tab">
                                            <div className="content-tab">
                                                <div className="content-inner active d-block">
                                                    <div className="tab-create-collection">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-6">
                                                                <div id="viewer"></div>
                                                                <h6 className="title selectitem">Upload</h6>
                                                                <p className="sub">
                                                                    PNG or WEBP. Max 50mb.
                                                                </p>



                                                                {(NFTFormValue?.NFTOrginalImage?.type?.includes("video") ||
                                                                    NFTFormValue?.NFTOrginalImage?.type?.includes("audio")
                                                                ) ? (
                                                                    <div className="dropboxsplit">
                                                                        <Dropzone
                                                                            onDrop={(acceptedFiles) =>
                                                                                onChange(null, acceptedFiles, "Orginal")
                                                                            }
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div
                                                                                    className="drag-upload"
                                                                                    {...getRootProps()}
                                                                                >
                                                                                    <input
                                                                                        type="file"
                                                                                        id="NFTOrginalImage"

                                                                                        // {...getInputProps()}
                                                                                        // autoComplete="off"
                                                                                        webkitdirectory="true"
                                                                                        multiple
                                                                                    />
                                                                                    {/* <img src={Upload} alt="Image" /> */}
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="208" height="208" viewBox="0 0 208 208" fill="none">
                                                                                        <path d="M208 137.817C208 140.105 206.856 142.54 204.568 145.123L167.374 188.959C164.201 192.723 159.754 195.915 154.035 198.535C148.316 201.154 143.021 202.464 138.15 202.464H17.7116C15.2024 202.464 12.97 201.985 11.0144 201.025C9.05872 200.066 8.08089 198.479 8.08089 196.265C8.08089 193.977 9.22478 191.542 11.5125 188.959L48.7067 145.123C51.8801 141.359 56.3264 138.168 62.0457 135.548C67.7651 132.928 73.0602 131.618 77.9308 131.618H198.369C200.878 131.618 203.111 132.098 205.067 133.057C207.022 134.017 208 135.603 208 137.817ZM170.031 99.7374V117.449H77.9308C70.9938 117.449 63.7246 119.202 56.1234 122.707C48.5223 126.212 42.4709 130.622 37.9692 135.935L0.664161 179.771L0.110732 180.436C0.110732 180.14 0.0922768 179.679 0.0553661 179.052C0.0184554 178.425 0 177.963 0 177.668V71.3988C0 64.6094 2.43533 58.7793 7.306 53.9085C12.1767 49.0379 18.0067 46.6025 24.7962 46.6025H60.2192C67.0087 46.6025 72.8387 49.0379 77.7095 53.9085C82.5801 58.7792 85.0155 64.6093 85.0155 71.3988V74.941H145.235C152.024 74.941 157.854 77.3764 162.725 82.247C167.595 87.1177 170.031 92.9478 170.031 99.7373V99.7374Z" fill="#CE8DFA" />
                                                                                    </svg>
                                                                                    <h6 className="upload-title">
                                                                                        Upload File
                                                                                    </h6>
                                                                                    <h6 className="title">
                                                                                        Drag your item to upload
                                                                                    </h6>
                                                                                    <p className="sub-title p-1">
                                                                                        PNG Or WebP. Maximum File
                                                                                        Size 50 Mb.
                                                                                    </p>
                                                                                    {ValidateError.NFTOrginalImage && (
                                                                                        <span className="text-danger img-file">
                                                                                            {ValidateError.NFTOrginalImage}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <Dropzone
                                                                            onDrop={(acceptedFiles) =>
                                                                                onChange(null, acceptedFiles, "Thump")
                                                                            }
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div
                                                                                    className="drag-upload"
                                                                                    {...getRootProps()}
                                                                                >
                                                                                    <input
                                                                                        type="file"
                                                                                        id="NFTThumpImage"
                                                                                        // accept="image/*"
                                                                                        {...getInputProps()}
                                                                                        autoComplete="off"
                                                                                        webkitdirectory="true"
                                                                                        multiple
                                                                                    />
                                                                                    {/* <img src={Upload} alt="Image" /> */}
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="208" height="208" viewBox="0 0 208 208" fill="none">
                                                                                        <path d="M208 137.817C208 140.105 206.856 142.54 204.568 145.123L167.374 188.959C164.201 192.723 159.754 195.915 154.035 198.535C148.316 201.154 143.021 202.464 138.15 202.464H17.7116C15.2024 202.464 12.97 201.985 11.0144 201.025C9.05872 200.066 8.08089 198.479 8.08089 196.265C8.08089 193.977 9.22478 191.542 11.5125 188.959L48.7067 145.123C51.8801 141.359 56.3264 138.168 62.0457 135.548C67.7651 132.928 73.0602 131.618 77.9308 131.618H198.369C200.878 131.618 203.111 132.098 205.067 133.057C207.022 134.017 208 135.603 208 137.817ZM170.031 99.7374V117.449H77.9308C70.9938 117.449 63.7246 119.202 56.1234 122.707C48.5223 126.212 42.4709 130.622 37.9692 135.935L0.664161 179.771L0.110732 180.436C0.110732 180.14 0.0922768 179.679 0.0553661 179.052C0.0184554 178.425 0 177.963 0 177.668V71.3988C0 64.6094 2.43533 58.7793 7.306 53.9085C12.1767 49.0379 18.0067 46.6025 24.7962 46.6025H60.2192C67.0087 46.6025 72.8387 49.0379 77.7095 53.9085C82.5801 58.7792 85.0155 64.6093 85.0155 71.3988V74.941H145.235C152.024 74.941 157.854 77.3764 162.725 82.247C167.595 87.1177 170.031 92.9478 170.031 99.7373V99.7374Z" fill="#CE8DFA" />
                                                                                    </svg>
                                                                                    <h6 className="title">
                                                                                        Upload Thumbnail
                                                                                    </h6>
                                                                                    <h6 className="title">
                                                                                        Drag your item to upload
                                                                                    </h6>
                                                                                    <p className="sub-title p-1">
                                                                                        Image only. Maximum File
                                                                                        Size 50 Mb.
                                                                                    </p>
                                                                                    {ValidateError.NFTThumpImage && (
                                                                                        <span className="text-danger img-file">
                                                                                            {ValidateError.NFTThumpImage}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                ) : (
                                                                    <>

                                                                        <Dropzone

                                                                            // noClick={true}
                                                                            onDrop={(acceptedFiles) =>
                                                                                onChange(null, acceptedFiles, "Orginal")
                                                                            }
                                                                            multiple
                                                                            webkitdirectory="true" mozdirectory directory
                                                                        // onFileDialogOpen={(e)=>onFileDialogCancel()}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div
                                                                                    className="drag-upload"
                                                                                    {...getRootProps()}
                                                                                >


                                                                                    {/* <input
                        type="file"
                        id="NFTOrginalImage"
                        multiple
                        webkitdirectory="true"
                        // style={{ display: 'none' }}
                        // {...getInputProps()}
                        onChange={{...getInputProps()}}
                      /> */}


                                                                                    {/* <img src={Upload} alt="Image" /> */}
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="208" height="208" viewBox="0 0 208 208" fill="none">
                                                                                        <path d="M208 137.817C208 140.105 206.856 142.54 204.568 145.123L167.374 188.959C164.201 192.723 159.754 195.915 154.035 198.535C148.316 201.154 143.021 202.464 138.15 202.464H17.7116C15.2024 202.464 12.97 201.985 11.0144 201.025C9.05872 200.066 8.08089 198.479 8.08089 196.265C8.08089 193.977 9.22478 191.542 11.5125 188.959L48.7067 145.123C51.8801 141.359 56.3264 138.168 62.0457 135.548C67.7651 132.928 73.0602 131.618 77.9308 131.618H198.369C200.878 131.618 203.111 132.098 205.067 133.057C207.022 134.017 208 135.603 208 137.817ZM170.031 99.7374V117.449H77.9308C70.9938 117.449 63.7246 119.202 56.1234 122.707C48.5223 126.212 42.4709 130.622 37.9692 135.935L0.664161 179.771L0.110732 180.436C0.110732 180.14 0.0922768 179.679 0.0553661 179.052C0.0184554 178.425 0 177.963 0 177.668V71.3988C0 64.6094 2.43533 58.7793 7.306 53.9085C12.1767 49.0379 18.0067 46.6025 24.7962 46.6025H60.2192C67.0087 46.6025 72.8387 49.0379 77.7095 53.9085C82.5801 58.7792 85.0155 64.6093 85.0155 71.3988V74.941H145.235C152.024 74.941 157.854 77.3764 162.725 82.247C167.595 87.1177 170.031 92.9478 170.031 99.7373V99.7374Z" fill="#CE8DFA" />
                                                                                    </svg>
                                                                                    <h6 className="upload-title">
                                                                                        {NFTFormValue.imgfile.length > 0 && "Uploaded"}
                                                                                    </h6>
                                                                                    <h6 className="title">
                                                                                        Drag your item to upload
                                                                                    </h6>
                                                                                    <p className="sub-title p-1">
                                                                                        PNG Or WebP. Maximum File
                                                                                        Size 50 Mb.
                                                                                    </p>
                                                                                    {ValidateError.NFTOrginalImage && (
                                                                                        <span className="text-danger img-file">
                                                                                            {ValidateError.NFTOrginalImage}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>

                                                                    </>
                                                                )}
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="d-flex justify-content-between align-items-start">
                                                                    <div className="bottom-button pr-0  mb-3">
                                                                        <a href={`${modelexcel}`} target="_blank"> <Button
                                                                            // onClick={()=>modelexcel()}
                                                                            className="tf-button active mb-5 mx-0"
                                                                        >
                                                                            Model Excel
                                                                        </Button></a>
                                                                    </div>
                                                                    <div className="bottom-button mb-3">
                                                                        <Button
                                                                            onClick={() => previewchange()}
                                                                            disabled={previeww}
                                                                            className="tf-button active mb-5 mx-0"
                                                                        >
                                                                            Preview
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <label className="mb8 selectitem1 mb-3">
                                                                    Price <span className="redreq">*</span>
                                                                </label>
                                                                <div className="d-flex justify-content-between align-items-center fixedborder">

                                                                    <input
                                                                        type="text"
                                                                        className="set-items"
                                                                        id="NFTPrice"
                                                                        placeholder="Ex: 0.001"
                                                                        onChange={onChange}
                                                                        value={NFTFormValue.NFTPrice}
                                                                        // placeholder="e.g.10"
                                                                        autoComplete="off"
                                                                        maxLength={7}
                                                                    />
                                                                    <Select
                                                                        onChange={(e) =>
                                                                            setNFTFormValue({
                                                                                ...NFTFormValue,
                                                                                ...{ ["CoinName"]: e.label },
                                                                            })
                                                                        }
                                                                        value={
                                                                            {
                                                                                label: NFTFormValue.CoinName,
                                                                                value: NFTFormValue.CoinName,
                                                                            }
                                                                        }
                                                                        options={
                                                                            currency
                                                                        }
                                                                        classNamePrefix="react-select"
                                                                        isSearchable={false}
                                                                    />

                                                                </div>
                                                                <div>
                                                                    {ValidateError.NFTPrice && (
                                                                        <span className="text-danger img-file">
                                                                            {ValidateError.NFTPrice}
                                                                        </span>
                                                                    )}
                                                                    {ValidateError.CoinName && (
                                                                        <span className="text-danger img-file coin">
                                                                            {ValidateError.CoinName}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                {/* <fieldset style={{ width: "100%" }}>
                                                        <label className="mb8 selectitem1">
                                                            Price
                                                        </label>
                                                        <input
                                                            type="number"
                                                            id="NFTPrice"
                                                            placeholder="Price of NFT"
                                                            onChange={(e)=>onChange(e)}
                                                            autoComplete="off"
                                                        />
                                                    </fieldset> */}
                                                                <div>
                                                                    <fieldset>
                                                                        <label className="mb8 mt-4 mb-3 selectitem1">
                                                                            Upload Excel <span className="redreq">*</span>
                                                                        </label>
                                                                        <p className="excelname">{filename}</p>
                                                                    </fieldset>
                                                                    {ValidateError.excelfile && (
                                                                        <span className="text-danger img-file">
                                                                            {ValidateError.excelfile}
                                                                        </span>
                                                                    )}
                                                                    <div className="mb-3">
                                                                        <div class="browseinput bottom-button text-left mt-3"> <Button
                                                                            className="tf-button active"
                                                                        >
                                                                            Upload
                                                                        </Button><input type="file" accept=".csv" class="file" onChange={(e) => onChange(e, "dummy", "excel")} /></div>
                                                                    </div>
                                                                </div>
                                                                <fieldset style={{ width: "100%" }}>
                                                                    <label className="mb8 mt-4 selectitem1">
                                                                        Set Royalty%
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        onChange={onChange}
                                                                        id="NFTRoyalty"
                                                                        placeholder="E.G. 5"
                                                                        value={NFTFormValue.NFTRoyalty}
                                                                        autoComplete="off"
                                                                    />
                                                                    {ValidateError.NFTRoyalty && (
                                                                        <span className="text-danger img-file">
                                                                            {ValidateError.NFTRoyalty}
                                                                        </span>
                                                                    )}
                                                                </fieldset>

                                                                <div className="bottom-button">
                                                                    <Button
                                                                        className="tf-button active"
                                                                        onClick={() => imageUpload()}
                                                                    >
                                                                        {MintButton == "stop" && "Start"}
                                                                        {MintButton == "start" && "Start Minting"}
                                                                        {MintButton == "process" && "In-Progress"}
                                                                        {MintButton == "try" && "Try-Again"}
                                                                        {MintButton == "done" && "Minted"}
                                                                        {/* Start Minting */}
                                                                    </Button>
                                                                </div>

                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>






                        </div>
                        <Footer />
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={show8}
                        >
                            <Modal.Header>
                                <button
                                    type="button"
                                    class="btn-close"
                                    aria-label="Close"
                                    onClick={handleClose8}
                                ></button>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Follow Steps
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {ApproveButton != "stop" && (
                                    <div className="ifpsmetadata mt-4">
                                        <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
                                            <i className="fa fa-check"></i>
                                            <div className="text-left ml-5">
                                                <h6 className="receives">Approve Call</h6>
                                                <p className="receives">One Time Process</p>
                                            </div>
                                        </div>
                                        <Button
                                            className="connectwallet primary my-3"
                                            disabled={
                                                ApproveButton == "process" || ApproveButton == "done"
                                                    ? true
                                                    : false
                                            }
                                            onClick={
                                                ApproveButton == "start" || ApproveButton == "try"
                                                    ? TokenApproveCall
                                                    : null
                                            }
                                            disableRipple
                                        >
                                            {ApproveButton == "start" && "Start"}
                                            {ApproveButton == "process" && "In-Progress"}
                                            {ApproveButton == "try" && "Try-Again"}
                                            {ApproveButton == "done" && "Done"}
                                        </Button>
                                    </div>
                                )}

                                <div className="ifpsmetadata">
                                    <div className="ifpsmetadatasec  d-flex justify-content-start align-items-center">
                                        <i className="fa fa-check"></i>
                                        <div className="text-left ml-5">
                                            <h6 className="receives">IPFS Metadata</h6>
                                            <p className="receives">Generates IPFS Metadata for your NFT</p>
                                        </div>
                                    </div>
                                    <Button
                                        className="connectwallet primary my-3"
                                        disabled={
                                            UploadButton == "process" || UploadButton == "done" || UploadButton == "stop"
                                                ? true
                                                : false
                                        }
                                        onClick={
                                            UploadButton == "start" || UploadButton == "try"
                                                ? UploadIPFScall
                                                : null
                                        }
                                        disableRipple
                                    >
                                        {UploadButton == "stop" && "Start"}
                                        {UploadButton == "start" && "Start"}
                                        {UploadButton == "process" && "In-Progress"}
                                        {UploadButton == "try" && "Try-Again"}
                                        {UploadButton == "done" && "Done"}
                                    </Button>
                                </div>

                                <div className="ifpsmetadata mt-4">
                                    <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
                                        <i className="fa fa-check"></i>
                                        <div className="text-left ml-5">
                                            <h6 className="receives">Mint NFT</h6>
                                            <p className="receives">Call contract method</p>
                                        </div>
                                    </div>
                                    <Button
                                        className="connectwallet primary my-3"
                                        disabled={(MintButton === "stop" || MintButton == "process" || MintButton == "done") ? true : false}
                                        onClick={MintButton == "start" || MintButton == "try"
                                            ? MintCall
                                            : null}
                                        disableRipple
                                    >
                                        {MintButton == "stop" && "Start"}
                                        {MintButton == "start" && "Start"}
                                        {MintButton == "process" && "In-Progress"}
                                        {MintButton == "try" && "Try-Again"}
                                        {MintButton == "done" && "Minted"}
                                    </Button>
                                    {/* <Button className="connectwallet primary my-3" 
                    
                    disabled={
                      MintButton == "process" ||
                      MintButton == "done" 
                        ? true
                        : false
                    }
                    onClick={
                        MintButton == "start" || MintButton == "try"
                        ? MintCall
                        : null
                    }
                    disableRipple >
                        {MintButton == "start" && "Start"}
                        {MintButton == "process" && "In-Progress"}
                        {MintButton == "try" && "Try-Again"}
                        {MintButton == "done" && "Done"}
                    </Button> */}
                                </div>
                                {/* <div className="ifpsmetadata mt-4">
                    <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
                        <i className="fa fa-check"></i>
                        <div className="text-left ml-5">
                            <h6>Sign Sell Order</h6>
                            <p>Sign sell order using tour wallet</p>
                        </div>
                    </div>
                    <button className="connectwallet primary my-3" onClick={() => setTheme(!theme)}>Done</button>
                </div>
                <div className='ifpsmetadata mt-4'>
                    <div className='ifpsmetadatasec d-flex justify-content-start align-items-center'>
                        <i className="fa fa-check"></i>
                        <div className="text-left ml-5">
                            <h6>Sign lock order</h6>
                            <p>Sign lock order using tour wallet</p>
                        </div>
                    </div>
                    <button className="connectwallet primary my-3" onClick={() => setTheme(!theme)}>Done</button>
                </div> */}
                            </Modal.Body>
                        </Modal>
                        {OpenPopup == "collection" && (
                            <CreateCollection
                                SetOpenPopup={SetOpenPopup}
                                Creator={accountAddress}
                                Network={NFTFormValue.CollectionNetwork}
                                Type={NFTFormValue.ContractType}
                                Address={NFTFormValue.ContractAddress}
                                GetCollection={() => CollectionByCreate({
                                    Type: collection == 1 ? 721 : 1155,
                                    Creator: accountAddress
                                })}
                                collection={collection}
                                AccountAddress={accountAddress}
                            />
                        )}
                        {((OpenPopup == "ClockTime" ||
                            OpenPopup == "EndClockTime") && (
                                <CLockModal
                                    SetOpenPopup={SetOpenPopup}
                                    modal={OpenPopup}
                                    validDate={OpenPopup == "ClockTime" ? validStart : validEnd}
                                    setClockValue={setClockValue}
                                    closeModal={CloseModal}
                                />
                            ))}
                    </div>
                </>

            }
        </>

    );
}
