import React, { useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
import Activity from "./Activity";
import History from "./History";
import Following from "./Following";
import Favourites from "./Favourites";
import EditProfile from "./EditProfile";
import Myitems from "./my_item1.js"
// import Inventory from "../assets/images/svg/activity.svg";
import Inventory from "../assets/images/activity.png";
import Inventory1 from "../assets/images/activityhover.png";

import Followers from "../assets/images/followers.png";
import Followers1 from "../assets/images/followershover.png";

import Followings from "../assets/images/following.png";
import Followings1 from "../assets/images/followinghover.png";

import Item from "../assets/images/myitem.png";
import Item1 from "../assets/images/myitemhover.png";

import account from "../assets/images/account.png";
import account1 from "../assets/images/accounthover.png";

import heart from "../assets/images/heart.png";
import heart1 from "../assets/images/hearthover.png";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import HistoryCon from "../assets/images/svg/history.svg";
import FollowingCon from "../assets/images/svg/following.svg";
import Favcon from "../assets/images/svg/fav.svg";
import Edit from "../assets/images/svg/edit.svg";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../separate/Header1.js";
import Footer from "../separate/Footer1.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import action_config from './config/config';
import { address_showing } from "../actions/common";
import { FollowUnFollowFunc, Token_MyList_Func, userRegister } from "../actions/axioss/user.axios";
import { toast } from 'react-toastify'
import Button from "react-bootstrap/esm/Button";
import Card from "./Card";
import NoData from "./seperatemodals/nodata";
import config from "./config/config"
import ImportCollection from './seperatemodals/importcollection';

import users from '../assets/images/poo.jpg';
import thumb from '../assets/images/frame.png';

import leftcurve from '../assets/images/joyimg/leftcurve.png';
import rightcurve from '../assets/images/joyimg/rightcurve.png';


export default function Board() {
    const location = useLocation()
    const { payload } = useSelector(state => state.LoginReducer.User)
    const { accountAddress } = useSelector(state => state.LoginReducer.AccountDetails)
    const [filter, setFilter] = useState('activity');
    var [filters, setFilters] = useState("LatestDrops");
    const [cursor, setcursor] = React.useState('');
    const [tab, SetTab] = useState('owned')
    const [loading, setLoading] = useState(true)
    const [Follow, SetFollow] = useState('follow');
    const [value, SetTabName] = React.useState('owned')
    const { customurl } = useParams()
    const [userProfile, setUserProfile] = useState({})
    const [Tokens, SetTokens] = useState({ 'activity': { 'loader': true, page: 1, list: [] } })
    const [Tokenss, SetTokenss] = useState({ 'owned': { 'loader': true, page: 1, list: [] } })
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);
    var [loader, setLoader] = useState(true);
    var navigate = useNavigate();
    var [openpopup, setOpenpopup] = useState('');
    // useEffect(() => {
        // console.log('ljfkjfdsdfsdg',location.state.Tab,filter,Tokens,value)
        // if (location?.state?.Tab) {
        //     console.log("locatioj ", location, location.state, location.state.Tab);
        //     setFilter(location.state.Tab)
        //     SetTabName(location.state.Tab)

        // }
        // if (typeof Tokens[filter] == 'undefined' || Tokens[filter].filter !== filter) {
        //     Tokens[filter] = { page: 1, list: [], loader: false };
        //     SetTokens(Tokens);
        //     Explore(1, filter);
        // }
        // if (typeof Tokenss[value] == 'undefined' || Tokenss[value].filter !== filters) {
        //     Tokenss[value] = { page: 1, list: [], loader: false };
        //     SetTokenss(Tokenss);
        //     Exploree(1, value);

        // }
    // }, [value, customurl, filter])


    useEffect(()=>{
        getProfileDetails()
    //    console.log("locatioj ",location,location.state,location.state.Tab);
    //     setFilter(location.state.Tab)
        }
    ,[customurl,value,filter])

    // useEffect(()=>{

    // },[userProfile])


    // useEffect(() => {
        // console.log("VANAKKAAAAAMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM",payload)
        // getProfileDetails()
        // if (typeof Tokens[filter] == 'undefined' || Tokens[filter].filter !== filter) {
            // Tokens[filter] = { page: 1, list: [], loader: false };
            // SetTokens(Tokens);
            // Explore(1, filter);
        // }
        // if (typeof Tokenss[value] == 'undefined' || Tokenss[value].filter !== filters) {
            // Tokenss[value] = { page: 1, list: [], loader: false };
            // SetTokenss(Tokenss);
            // Exploree(1, value);

        // }
        // Explore();
        // Exploree()

    // }, [customurl, accountAddress, userProfile?.WalletAddress,payload])

    const Exploree = async (data, tab) => {
        var page = data ? data : (Tokenss[value]?.page)
        var SendDATA = {
            TabName: tab ? tab : value,
            limit: (tab === "usercollection" || value === "usercollection") ? 100 : 12,
            CustomUrl: customurl,
            NFTOwner: (userProfile?.WalletAddress ? userProfile?.WalletAddress : accountAddress),
            page: page ?? 1,
            filter: filters,
            from: 'myItem',
            cursor: cursor
        }
        let Resp = await Token_MyList_Func(SendDATA)
        console.log('sfgfhgfs', value, tab, Resp, SendDATA)
        if (Resp?.success == 'success' && Resp.data.length > 0) {

            setcursor(Resp?.cursor)
            SetTokenss({
                ...Tokenss, ...{
                    [value]: {
                        list: [...Tokenss[value].list, ...Resp.data],
                        loader: (Resp.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
                        page: Tokenss[value].page,
                        filter: value,
                    }
                }
            })
            setLoading(false)
        } else
        {
            console.log('TOKENSCXXCelsee',Resp?.data)
        SetTokens({
            ...Tokens,
            ...{
              [value]: {
                list: SendDATA?.page == 1 ? [] : Tokens[value].list,
                loader: false,
                page: Tokens[value].page,
                filter: filter
              },
            },
          });
          setLoading(true)
        }
    }


    const getProfileDetails = async () => {
        var SendDATA = {
            CustomUrl: customurl ? customurl : payload?.CustomUrl,
            Type: 'getProfile',
            User: payload?.WalletAddress
        }

        var profileInfo = await userRegister(SendDATA)

        if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
            setUserProfile(profileInfo.data)
            // navigate(`/profile/${payload.CustomUrl}`)
            console.log("profuke", profileInfo.data)
            SetFollow(profileInfo.follow == "follow" ? "unfollow" : "follow")
        }
        else {
            const id = toast.loading("Searching User");
            toast.update(id, { render: 'User Not found', type: 'error', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
            setTimeout(function () {
                navigate('/')
            }, 2000);
        }
    }

    const FollowUnFollow = async () => {
        const id = toast.loading(Follow == "follow" ? "Your Following " + userProfile?.DisplayName : "Your UnFollowing " + userProfile?.DisplayName)
        let SendData = {
            MyItemAddr: userProfile?.WalletAddress,
            ClickAddr: accountAddress,
            From: 'myitem',
            MyItemCustomUrl: userProfile?.CustomUrl,
            ClickCustomUrl: payload?.CustomUrl,
        }
        // console.log('senddataaa',SendData,userProfile,payload)
        let Resp = await FollowUnFollowFunc(SendData)
        if (Resp?.success) {
            SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
            toast.update(id, { render: Resp.msg == 'follow' ? 'Your Following ' + userProfile?.DisplayName + ' Successfully' : 'Your UnFollowing ' + userProfile?.DisplayName + ' Successfully', type: 'success', isLoading: false, autoClose: 1000 })
        }
        else {
            toast.update(id, { render: 'Try Again', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })

        }
    }

    const Explore = async (data, tab) => {
        var page = data ? data : (Tokens[filter]?.page)
        var SendDATA = {
            TabName: tab ? tab : filter,
            limit: 12,
            CustomUrl: userProfile?.CustomUrl,
            NFTOwner: userProfile?.WalletAddress,
            page: page ?? 1,
            from: 'myItem',
            filter: 'LatestDrops',
        }
        let Resp = await Token_MyList_Func(SendDATA)
        
        setLoader((Resp?.data?.length == 0 || (SendDATA?.TabName == "usercollection" && Resp?.cursor == null)) ? false : true)
        console.log("VFFsasaasassa",Resp?.data);
        if (Resp?.success == 'success' && Resp?.data?.length > 0) {

            SetTokens({
                ...Tokens, ...{
                    [filter]: {
                        list: [...Tokens[filter]?.list, ...Resp?.data],
                        loader: (Resp?.data?.length == 0 || (SendDATA?.TabName == "usercollection" && Resp?.cursor == null)) ? false : true,
                        page: Tokens[filter]?.page
                    }
                }
            })
        }
    }

    const LoadMore = () => {
        Tokens[filter].page = Tokens[filter].page + 1;
        SetTokens(Tokens);
        Explore(Tokens[filter].page);
    }

    const CoverImg = async (event) => {
        const toastupd = toast.loading("You Updated Image")
        var reader = new FileReader()
        const { id, files } = event.target;
        var fileNameExt = files[0].name.substr(files[0].name.lastIndexOf(".") + 1);
        if (event.target.files && event.target.files[0]) {
            if (files, id, files[0].type.includes("image")) {
                var file = event.target.files[0];
                var Resp;
                if (id == 'coverupload') {
                    Resp = await userRegister({ Type: 'cover', WalletAddress: payload.WalletAddress, Cover: files[0] })
                    // console.log('coverrr',Resp)
                }
                else {
                    Resp = await userRegister({ Type: 'profileimage', WalletAddress: payload.WalletAddress, Profile: files[0] })
                    // console.log('coverrr',Resp)
                }
                if (Resp?.success == 'success') {
                    getProfileDetails();
                    toast.update(toastupd, { render: Resp.msg, type: 'success', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
                    // window.location.reload()
                }
                // let Resp = await userRegister({Type:'cover',CustomUrl:payload.CustomUrl,Cover:files[0]})
            }
            else {
                toast.update(toastupd, { render: "Profile or Cover Image Must be a Image", type: 'error', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
            }
        }

        // let formData = { ...formValue, ...{ [id]: files[0] } };
    }

    function LikeList(data) {
        setLikedTokenList(data)
    }

    return (
        <>
            <div className="inheader">
                <Header />
                {/* {console.log('photooooo',Tokens,loader)} */}
                <div className="board">
               
                    <section className="tf-page-title pt-0">
                        <div className="tf-container">
                            {/* <div className="row">
                            <div className="col-md-12">
                                <ul className="breadcrumbs" data-aos="fade-right">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Profile</li>
                                </ul>
                            </div>
                        </div> */}
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="thumb-pagetitle">
                                    {/* {console.log('addresss',userProfile,payload)} */}
                                    <img src={userProfile?.Cover ? action_config.IMG_URL + "/user/" + userProfile?.WalletAddress + '/cover/' + userProfile?.Cover : thumb} />
                                    {/* {userProfile?.WalletAddress === payload?.WalletAddress &&
                                        <div className="edit_profile">
                                            <input type="file" id="coverupload" name="upload" onChange={(e) => CoverImg(e)} />
                                            <i className="fa fa-pencil">  </i>
                                        </div>} */}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="tf-dashboard tf-tab">
                        <div className="tf-container">
                            <div className="row ">

                                <div className="col-xl-12 col-lg-12 col-md-12 overflow-table">


                                    <div className="dashboard-user">
                                        <div className="dashboard-infor">
                                            <div className="avatar">
                                                <img src={userProfile?.Profile ? action_config.IMG_URL + "/user/" + userProfile?.WalletAddress + '/profile/' + userProfile?.Profile : users} alt="images" />
                                                {/* {userProfile?.WalletAddress === payload?.WalletAddress &&
                                                    <div className="edit_profile">
                                                        <input type="file" id="profileupload" name="upload" onChange={(e) => CoverImg(e)} />
                                                        <i className="fa fa-pencil" style={{ color: "#000" }}></i>
                                                    </div>} */}
                                            </div>

                                            <p className="itemnames mb-0">{userProfile?.WalletAddress}</p>

                                            {/* <div className="name">{userProfile?.DisplayName}</div> */}
                                            {/* <div className="pax"><i className="fab fa-ethereum"></i>{address_showing(userProfile?.WalletAddress)}</div> */}
                                            <div className="description ml-2">
                                                <p>{userProfile?.Bio}</p>
                                            </div>
                                            {/* {console.log('fgfhgfhfhsfhs',userProfile)} */}
                                            {/* <ul className="social-item">
                                    {userProfile?.Facebook &&<li><a href={userProfile?.Facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li> }
                                    {userProfile?.Twitter &&<li><a href={userProfile?.Twitter} target="_blank"><i className="fab fa-twitter"></i></a></li> }
                                    {userProfile?.Instagram &&<li><a href={userProfile?.Instagram} target="_blank"><i className="fab fa-instagram"></i></a></li> }
                                    {userProfile?.Youtube &&<li><a href={userProfile?.Youtube} target="_blank"><i className="fab fa-youtube"></i></a></li> }
                                    </ul> */}
                                            {/* {console.log("userProfileee",userProfile?.WalletAddress,payload,userProfile)} */}

                                            {/* {userProfile?.WalletAddress != payload?.WalletAddress &&
                                   <div className="text-center">
                                   <Button className="tf-button style-2" onClick={FollowUnFollow}>{Follow}</Button>
                               </div>} */}

                                        </div>
                                    </div>
                                    <div className="downfall pt-1 pb-1 pl-3 pr-3">
                                    <img src={leftcurve} className="leftcurve" alt="golds" />
                                      <img src={rightcurve} className="rightcurve" alt="golds" />
                                        <Myitems />
                                        {/* <div className="collection">
                          
                          <section className="tf-collection-page tf-filter">
                           
                                  <div className="row ">
                                      <div className="col-md-12">
                                      <div className="top-menu row"  data-aos="fade-up">
                                              <ul className="filter-menu">
                                                  <li className={value == "owned" ? "active" : ""} onClick={() => SetTabName("owned")}><a>Owned</a></li>
                                                  <li className={value == "onsale" ? "active" : ""} onClick={() => SetTabName("onsale")}><a>On sale</a></li>
                                                  <li className={value == "gold" ? "active" : ""} onClick={() => SetTabName("gold")}><a>Gold</a></li>
              
                                                  <li className={value == "silver" ? "active" : ""} onClick={() => SetTabName("silver")}><a>Silver</a></li>
                                                  <li className={value == "bronze" ? "active" : ""} onClick={() => SetTabName("bronze")}><a>Bronze</a></li>
              
                                                  <li className={value == "diamond" ? "active" : ""} onClick={() => SetTabName("diamond")}><a>Diamond</a></li>
              
                                                  <li className={value == "ruby" ? "active" : ""} onClick={() => SetTabName("ruby")}><a>Ruby</a></li>
              
              
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="tf-filter-container row"  
                                  >
                                      { !loading  ? 
                                      
                                      <>
                                      <div className="text-centre">
                                          <h3>Loading...</h3>
                                          </div>
                                      </>
                                      :
                                      
                                      
                                      Tokenss[value] &&
                                      Tokenss[value]?.list?.length>0 ? 
                                      Tokenss[value].list.map((val,index) => 
                                      
                                      <div
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                    >
                      {console.log("carddata",val)}
                                      <Card 
                                      from    = { value == 'usercollection' ?  'usercollection' :'marketplace'}
                                      product={ value == 'usercollection' ? 
                                      {
                                          NFTName : val.name,
                                          Category : val.tokenowners_list.Category,
                                          CollectionNetwork   :  action_config.COIN_NAME ,
                                          ContractAddress :   val.colladdress,
                                          NFTOwner    :   userProfile.WalletAddress,
                                          NFTId   :   val.tokenCounts,
                                          Image   :   val.image,
                                          OwnerName : userProfile.DisplayName ,
                                          OwnerProfile : userProfile.Profile ,
                                          type : val.type,
                                          amount : val.amount,
                                          ipfshash:val.ipfshash,
                                          meta:val.meta,
                                          metadata:val.metadata,
                                          Creator_CustomUrl:payload?.CustomUrl,
                                          
                                      }
                                      :val}
                                      type="marketplace"
                                      index={index}
                                      LikeList={LikeList}
                                      LikedTokenList={LikedTokenList}
                                      setLikedTokenList={setLikedTokenList}
                                      LikeForwardRef={LikeForwardRef}/>
                                      </div>
                                      ):<NoData/>}
                                     
                                  </div>
                                 {Tokens[value]?.loader&& <div className="col-md-12">
                                      <div className="btn-loadmore mt6">
                                          <Link to="#" className="tf-button loadmore" onClick={LoadMore}>Load More</Link>
                                      </div>
                                  </div>
                                  }
                          </section>
                      </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}