import React,{useState,useEffect} from "react";
import { Sociallinks , Newsletter} from "../actions/axioss/user.axios";
import config from "../views/config/config"
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";

import discard from "../assets/images/DiscordLogo.png";
import whatsapp from '../assets/images/whatsapp (1).png'
import facebook from '../assets/images/facebook.png'
import telegram from '../assets/images/telegram.png'
import linkedin from '../assets/images/linkedin.png'
import you from "../assets/images/YoutubeLogo.png";
import twitter from "../assets/images/TwitterLogo.png";
import instagram from "../assets/images/InstagramLogo.png";
import { getCmsContent } from "../actions/axioss/cms.axios";

export default function Footer(props){
    var [email,setEmail] = useState('');
    var [Error,SetError] = useState({});
    // const navigate  = useNavigate();

    // const [click, setClick] = useState(false);
    // const gotoLive = () =>    
    // {
    //     setClick(true);
    //     setTimeout(()  =>
    //     {
    //         navigate("/");
    //         if((!isEmpty(props)))
    //         {
    //             console.log(click,"click")
    //             props?.func("liveauction",true)
          
            
    //     },1000);
      
    // }
   
    
    // if((!isEmpty(props)))
    // {
    //     console.log(click,"click")
    //     props?.func("",false)
    // }
   
  
    // props? props?.func("#liveaution") : null;
    // console.log(props,"props");


    // const gotoHome = (val) =>
    // {
    //     alert(1)
        
    //     navigate("/");
       
    // }
    const scrollTo = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
      }
    var [link,setLink] = useState([]);
    var [Error,SetError] = useState({});
  const [footer,setFooter]=useState({})
    const { payload } = useSelector(state => state.LoginReducer.User)

      const push = useNavigate()
    useEffect(()=>{
        var a = Getlink();
        Getandsellnft();
        // console.log("hello",a)
    },[])
    const Getandsellnft = async () => {

        var resp = await getCmsContent("footer");
        console.log("footerrr",resp?.data)
        if (resp?.status)
            setFooter(resp?.data);
    }

    const Getlink=async()=>{
        
        let link_res= await Sociallinks();
        console.log("linkss",link_res?.msg);
        setLink(link_res?.msg??[])
    }

    const NewsLetter = async() =>{
        const id = toast.loading("Subscribing...");
        
        let err = {};
        if(email=='') err.email="Email Id Required";
        if(email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        // console.log('fhbdfhbdf',email)
        if(isEmpty(err)){
            let resp = await Newsletter({email : email});
            if(resp.success === 'success'){
                toast.update(id ,{render:"succeffully Subcribed for NewsLetter", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                setEmail('');
            }
            else{
                toast.update(id ,{ render : "Already User", type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                SetError({email:resp.msg});
            }
        }
        else{
            toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
        }
    }

    return(
        <>
          <footer className="footer">
                <div className="tf-container">
                    <div className="row">
                     
                        <div className="col-md-8 col-sm-7">
                        <p className="copy-right foot-p">Copyright © 2023. All Rights Reserved.</p>
                        </div>
                        <div className="col-md-4 col-sm-5">
                        <p className="copy-right foot-p"> <span><Link to="/terms"> Terms of Sevices </Link></span> | <span><Link to="/privacypolicy"> Privacy Policy</Link></span> </p>
                        </div>
                    </div>
                </div>
                <Link to="#" id="scroll-top" className="scrolltop_zindex" onClick={scrollTo}></Link>
           </footer>
        </>
    )
}