import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Card from "./Card";
import History from "../assets/images/author-history-1.jpg";
import { NavLink, Link, useLocation } from "react-router-dom";
import Header from "../separate/Header1.js";
import Footer from "../separate/Footer1.js";
import axios from 'axios'
import leftcurve from '../assets/images/joyimg/leftcurve.png';
import rightcurve from '../assets/images/joyimg/rightcurve.png';

import diamondimg from "../assets/images/joyimg/diamond.png";
import goldimg from "../assets/images/joyimg/goldbg.png";
import bronzeimg from "../assets/images/joyimg/bronze.png";
import silverimg from "../assets/images/joyimg/silver.png";
import rubyimg from "../assets/images/joyimg/ruby.png";
import { Stakemodal } from "./seperatemodals/stakemodal";

import config from "./config/config";

import ImgAudVideo from "../separate/ImgAudVideo";
import { toast } from "react-toastify";
import { address_showing, isEmpty } from "../actions/common";
import { useNavigate, useParams } from "react-router-dom";
import useContractProviderHook from "../actions/contractProviderHook";
import { useSelector } from "react-redux";
import { Token_Info_Func } from "../actions/axioss/nft.axios";
import { BuyNow } from "./seperatemodals/BuyNow";
import { Lower } from "./seperatemodals/Lower";
import { Cancel } from "./seperatemodals/Cancel";
import { Bid } from "./seperatemodals/Bid";
import { Accept } from "./seperatemodals/Accept";
import { CancelBids } from "./seperatemodals/CancelBids";
import Button from "react-bootstrap/esm/Button";
import Countdown from "react-countdown";

import moment from "moment";
import { LikeRef } from "./seperatemodals/LikeRef";
import { Share } from "./seperatemodals/Share";
import { Report } from './seperatemodals/Report';
import { findOwners } from "../actions/axioss/user.axios";

import author from "../assets/images/author-detail-3.png"
import author1 from "../assets/images/monkey1.png";
import web3d from 'web3'

export default function Details() {
  const { state   } = useLocation();
  const [stakemodal, setStakemodal] = useState(false);
  console.log("ddasdassdasdf",state);
  const ContractCall = useContractProviderHook();
  const [TabName, SetTabName] = useState("All");
  const [Tokens, SetTokens] = useState({
    All: {
      loader: true,
      page: 1,
      list: [],
      owner: {},
      myBid: {},
      highbid: {},
      myowner: {},
    },
  });
  const [Tokens_Detail, SetTokens_Detail] = useState({});
  const [Explores, SetExplore] = useState([]);
  const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);
  const { isStake} = useSelector((state) => state.LoginReducer);
  const [InfoDetail, SetInfoDetail] = useState({});
  var [seemore, setSeemore] = useState({});

  const { network, Contract, Owner, Id } = useParams()

  const [Loader, setLoader] = useState(false);
  const [LoaderTab, setLoaderTab] = useState(false);
  const [OpenPopup, SetOpenPopup] = useState('')
  const [SendDet, SetSendDet] = useState({});
  const [BtnData, SetBtnData] = useState('start')
  const [text, setText] = useState()
  const { accountAddress, web3 } = useSelector(state => state.LoginReducer.AccountDetails);
  var [moreprops, setMoreprops] = useState('');
  const push = useNavigate()
  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Reward,setReward]=useState()

  const [likedisable, SetLikeDisable] = useState(true);
var web=new web3d(config.RPC_URL)
  // useEffect(()=>{

  //   getrewards()
  // },[Tokens_Detail])

  // const getrewards=async()=>{
  //   let Reward = await ContractCall.Getreward(Id,Tokens[TabName]?.owner.WalletAddress)
  // if(Reward.success){
  //   console.log("REWARDDDDDD",Reward)
  //   setReward(web3.utils.fromWei(Reward.reward))
  // }
  // }
  useEffect(() => {
    setLoader(true);
    findOwner();
  }, [accountAddress, state, Contract, Owner, isStake]);
  useEffect(() => {
    if (typeof Tokens[TabName] == 'undefined') {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    } else setLoaderTab(false)
  }, [TabName, Contract, Owner, Id, accountAddress])
  const findOwner = async () => {
    var Resp = await findOwners({
      NFTCreator: Owner,
      ContractAddress: Contract,
      NFTId: Id,
    });
    if (Resp.success == "success") {
      Explore();
    } else if (state?.data && Resp.success == "error") {
      if (state?.data?.metadata?.animation_url) {
        var Response = await axios.get(state.data.metadata.animation_url)
        state.data.type = (Response.headers['content-type']).includes('image') ? "image" : (Response.headers['content-type']).includes('video') ? "video" : "audio"
        console.log("res.headers['content-type'];", Response, Response.headers['content-type'])
      }
      SetInfoDetail(state.data);
      setLoader(false);
    } else {
      console.log('elsssseeee')
      Explore();
    }

  };
  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <>
          <div className="d-flex justify-content-between">
            <div className="">
              <p className="dayy">{formatTime(days)} </p>
              <p className="dayss">Days </p>
            </div>
            <div>
              <p className="dayy">:</p>
            </div>
            <div className="">
              <p className="dayy">{formatTime(hours)}</p>
              <p className="dayss">Hours </p>
            </div>
            <div>
              <p className="dayy">:</p>
            </div>
            <div className="">
              <p className="dayy">{formatTime(minutes)}</p>
              <p className="dayss">Minutes</p>
            </div>
            <div>
              <p className="dayy">:</p>
            </div>
            <div className="">
              <p className="dayy"> {formatTime(seconds)}</p>
              <p className="dayss">Seconds</p>
            </div>
          </div>
        </>
      );
    }
  };

  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };




  const POPUPACTION = useCallback(
    (data, item, text) => {
      console.log("Buy is Calling", data, item);
      if (accountAddress) {
        if (data == "Stake") {
           item.isStake=Tokens[TabName]?.owner?.isStake
          SetOpenPopup(data);
          
          SetSendDet(item);
          setStakemodal(true)
        }
        if (data == "Accept") {
          (async () => {
            let Statu = await ContractCall.GetApproveStatus(
              Tokens_Detail.ContractType == 721 ||
                Tokens_Detail.ContractType == "721"
                ? "Single"
                : "Multiple",
              Tokens_Detail.ContractAddress
            );
            if (Statu == false || Statu == "error") {
              toast.warn("Need To Approve");
              SetBtnData("open");
              SetOpenPopup(data);
            } else {
              SetBtnData("error");
              SetOpenPopup(data);
            }
          })();
        } else {
          SetOpenPopup(data);
          setText(text)
          SetSendDet(item);
        }
      } else {
        if (data === "Share") {
          SetOpenPopup(data);
          SetSendDet(item);
        } else
          toast.error(" log in to connect to the wallet ", { autoClose: 1000, closeButton: true, closeOnClick: true });
      }
    },
    [OpenPopup, accountAddress, Tokens_Detail.ContractAddress]
  );




  const [dayreward,setDayreward]=useState()



  // useEffect(()=>{
  //   rewardget()
  // },[web3,Tokens_Detail])


  const rewardget = async (add)=>{
    console.log('add',add);
    var date=Number((new Date().getTime() /1000).toFixed(0))
    let Reward = await ContractCall.Getreward(Id,add,config.poolid,date)
    if(Reward.success){
      setReward(web3?.utils.fromWei(Reward.reward))
    }
    
    let dayreward = await ContractCall.Getdayreward(config.poolid,Id)
    console.log("REWARDDDDDD",Reward,dayreward)

    if(dayreward?.success){
      setDayreward(web3?.utils.fromWei(dayreward.reward))
    }
  }
  const Explore = async (data, tab) => {
    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 4,
      Owner: Owner,
      page: page ?? 1,
      from: "info",
      Contract: Contract,
      Id: Id.toString(),
      MyAdd: accountAddress,
    };
    console.log("Account Address Checing", SendDATA, accountAddress,Tokens[TabName]?.owner);
    let Resp = await Token_Info_Func(SendDATA);
    console.log("OwnersList", Resp?.token.data[0]);
    if (
      Resp?.token?.success == "success" &&
      Resp?.token?.data[0]?.Current_Owner.length > 0
    ) {
      if (TabName == "All") {
        SetTokens_Detail(Resp.token.data[0]);
        SetExplore(Resp.Explore.data);
        console.log("fsdfasdbvbvbv" ,Resp.token.data[0] );
        setMoreprops(Resp.token.data[0].NFTProperties?.length)
      }
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [
              ...Tokens[TabName].list,
              ...(TabName == "owner"
                ? Resp.token.data[0].tokenowners_list
                : TabName == "bid"
                  ? Resp.Bid.data
                  : []),
            ],
            loader:
              Resp.token.Count ==
                Tokens[TabName]?.list?.length + Resp.token.data.length
                ? false
                : true,
            page: Tokens[TabName].page,
            owner:
              TabName == "All"
                ? Resp.token.data[0].Current_Owner.pop()
                : Tokens["All"].owner,
            myowner:
              TabName == "All"
                ? Resp.token.data[0].myowner.pop()
                : Tokens["All"].myowner,
            myBid: Resp?.myBid?.data.pop(),
            highbid: Resp?.highBid?.data[0],
          },
        },
      });
  
      rewardget(Resp.token.data[0].tokenowners_list[0].WalletAddress)
       console.log("kdgfdfad1111", Tokens[TabName]?.owner?.isStake,Tokens[TabName]?.owner,Tokens[TabName]?.myowner);
    } else {
      SetTokens({ [TabName]: { loader: true, page: 1, list: [] } });
    }
    setTimeout(() => {
      setLoaderTab(false);
      setLoader(false);
    }, 2000);
  };
  const tabChange = (newValue) => {
    setLoaderTab(true);
    SetTabName(newValue);
  };

  const closePop = () => {
    SetOpenPopup("");
  };
  const LikeAction = async () => {
    if (accountAddress) {
      if (likedisable) {
        SetLikeDisable(false)
        var check = await LikeForwardRef.current.hitLike(Tokens_Detail);
        console.log("Check",check)
        toast.success("you " + check + "d this token", { autoClose: 500, closeButton: true, closeOnClick: true });
        if (check == 'like') {

          SetTokens_Detail({ ...Tokens_Detail, ...{ likecount: Tokens_Detail.likecount + 1 } })
        }
        else if (check == 'unlike') {
          SetTokens_Detail({ ...Tokens_Detail, ...{ likecount: Tokens_Detail.likecount - 1 } })
        }
        SetLikeDisable(true)
      }

    } else toast.error("Connect Wallet", { autoClose: 1000, closeButton: true, closeOnClick: true });
  };

  function LikeList(data) {
    setLikedTokenList(data);
  }

  var Seemore = () => {
    setMoreprops(Tokens_Detail?.NFTProperties?.length)
  }

  return (
    <>
      <div className="inheader">
        {OpenPopup === "Stake" && (<Stakemodal owner={SendDet}
          file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
          type={
            Tokens_Detail.CompressedFile
              ? Tokens_Detail.CompressedFile?.includes(".webp")
                ? "image"
                : Tokens_Detail.CompressedFile.includes(".webm")
                  ? "video"
                  : "audio"
              : Tokens_Detail.CompressedFile
          }
          thumb={Tokens_Detail.CompressedThumbFile}
          item={{
            NFTName: Tokens_Detail.NFTName,
            NFTId : Tokens_Detail.NFTId,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            OriginalImage: Tokens_Detail.NFTOrginalImage,
            CompressedFile: Tokens_Detail.CompressedFile,
            CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
            OriginalFile: Tokens_Detail.OriginalFile,
            NFTOwner : Tokens[TabName]?.owner?.NFTOwner,
            NFTCreator: Tokens_Detail.NFTCreator,
            NFTRoyalty: Tokens_Detail.RoNFTRoyaltyyalty,
             isStake  : Tokens[TabName]?.owner?.isStake,
            Category: Tokens_Detail?.Category,
            NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
            CoinName: Tokens[TabName]?.owner?.CoinName,
            PutOnSaleType: "FixedPrice",
            PutOnSale: true,
            ClockTime: null,
            Reward : Reward,
            TokenDET:Tokens[TabName]?.myowner,
            Dayreard : dayreward,
            isStakeupdate : Tokens_Detail?.tokenowners_list[0]?.isStakeupdate,
            isClaimupdate : Tokens_Detail?.tokenowners_list[0]?.isClaimupdate
          }}
          closePop={closePop}
        // onDismiss={() => setStakemodal(false)}
        />)}
        {
          <LikeRef
            ref={LikeForwardRef}
            setLikedTokenList={setLikedTokenList}
            LikeList={LikeList}
          />
        }

        {OpenPopup === "Buy" && (
          <BuyNow
            owner={SendDet}
            OpenPopup={OpenPopup}
            closePop={closePop}
            item={{
              NFTimageipfs: Tokens_Detail.NFTOrginalImage,
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              hash: Tokens_Detail.Hash,
              hashvalue : Tokens_Detail.tokenowners_list[0].HashValue,
              Category: Tokens_Detail?.Category,
              PutOnSaleType: Tokens_Detail?.PutOnSaleType,
              imagehash: Tokens_Detail?.NFTOrginalImageIpfs,
              nonce: Tokens_Detail?.Nonce,
              randomname: Tokens_Detail?.Randomname
            }}
          />
        )}
        {console.log('fjksdhjfkhsdjkf', Tokens_Detail)}
        {OpenPopup == "Cancel" && (
          <Cancel
            owner={SendDet}
            types="Cancel"
            OpenPopup={OpenPopup}
            closePop={closePop}
            file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
            type={
              Tokens_Detail.CompressedFile
                ? Tokens_Detail.CompressedFile?.includes(".webp")
                  ? "image"
                  : Tokens_Detail.CompressedFile.includes(".webm")
                    ? "video"
                    : "audio"
                : Tokens_Detail.CompressedFile
            }
            thumb={Tokens_Detail.CompressedThumbFile}
            // noimg={require("../assets/images/No_image.jpg")}
            item={{
              TokenName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail?.Category,
              NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
              CoinName: Tokens[TabName]?.myowner?.CoinName,
            }}
          />
        )}
        {OpenPopup === "createorder" && (
          <Lower
            owner={SendDet}
            text={text}
            OpenPopup={OpenPopup}
            closePop={closePop}
            file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
            type={
              Tokens_Detail.CompressedFile
                ? Tokens_Detail.CompressedFile?.includes(".webp")
                  ? "image"
                  : Tokens_Detail.CompressedFile.includes(".webm")
                    ? "video"
                    : "audio"
                : Tokens_Detail.CompressedFile
            }
            thumb={Tokens_Detail.CompressedThumbFile}
            item={{
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              OriginalImage: Tokens_Detail.NFTOrginalImage,
              CompressedFile: Tokens_Detail.CompressedFile,
              CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
              OriginalFile: Tokens_Detail.OriginalFile,
              NFTCreator: Tokens_Detail.NFTCreator,
              NFTRoyalty: Tokens_Detail.RoNFTRoyaltyyalty,

              // Category: Tokens_Detail.tokenowners_list?.Category,
              NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
              CoinName: Tokens[TabName]?.myowner?.CoinName,
              PutOnSaleType: "FixedPrice",
              PutOnSale: true,
              ClockTime: null,
            }}
          />
        )}
        {OpenPopup === "Bid" && (
          <Bid
            owner={Tokens[TabName]?.owner}
            bidder={!isEmpty(SendDet) ? SendDet : Tokens[TabName]?.myBid}
            OpenPopup={OpenPopup}
            bid={Tokens[TabName]?.highbid}
            closePop={closePop}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail?.Category,
            }}
          />
        )}
        {console.log('sdgsgsdfgsg', SendDet)}
        {OpenPopup === "Accept" && (
          <Accept
            owner={Tokens[TabName]?.myowner}
            bidder={SendDet}
            OpenPopup={OpenPopup}
            bid={SendDet}
            closePop={closePop}
            approvestatus={BtnData}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail?.Category,
            }}
         
          />
        )}
        {OpenPopup == "CancelBid" && (
          <CancelBids
            bidder={SendDet}
            OpenPopup={OpenPopup}
            owner={Tokens[TabName]?.owner}
            closePop={closePop}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              // Category: Tokens_Detail.tokenowners_list.Category,
            }}
          />
        )}
        {OpenPopup === "Share" && (
          <Share
            closePop={closePop}
            title={`${Tokens_Detail.NFTName}  NFT`}
            url={`${config.FRONT_URL}/info/${Tokens_Detail.CollectionNetwork}/${Tokens_Detail.ContractAddress}/${SendDet?.NFTOwner}/${Tokens_Detail.NFTId}`}
            quote={`${Tokens_Detail.NFTName} NFT`}
          />
        )}


        {
          OpenPopup === "Report" &&
          <Report
            OpenPopup={OpenPopup}
            closePop={closePop}
            item={{
              NFTId: Tokens_Detail.NFTId,
              NFTName: Tokens_Detail.NFTName,
              ContractAddress: Tokens_Detail.ContractAddress,
              ContractType: Tokens_Detail.ContractType,
              NFTRoyalty: Tokens_Detail.NFTRoyalty,
              NFTCreator: Tokens_Detail.NFTCreator,
              CollectionNetwork: Tokens_Detail.CollectionNetwork,
              Category: Tokens_Detail?.Category
            }}
          />
        }

        <Header />
        {Loader ?
          // <div className='text-center'><div class="lds-hourglass"></div></div>
          <div class="preload preload-container">
            <div class="preload-logo"></div>
          </div>
          : <>
            <div className="infodetail">
              {/* <section className="tf-page-title">
              <div className="tf-container">
                <div className="row">
                  <div className="col-md-12">

                    <ul className="breadcrumbs" data-aos="fade-right">
                      <li><NavLink to="/">Home</NavLink></li>
                      <li><NavLink to="/explore">Explore</NavLink></li>
                      <li>Product Details</li>
                    </ul>
                   
                  </div>
                </div>
              </div>
            </section> */}
            </div>
            <section className="tf-item-detail info">
              <img src={leftcurve} className="leftcurve" alt="golds" />
              <img src={rightcurve} className="rightcurve" alt="golds" />
              <div className="tf-container">
                <div className="row infodeatil align-items-center" >


                  <div className="col-md-6  lk">

                    <div class="card"
                    // style={{ background: `${Tokens_Detail.Category == "silver" ? "gray" : "gray"}` }}
                    >
                      {console.log("Token detailssssss", Tokens_Detail)}
                      {/* <img class="card-img-top" src={author1} alt="Card image cap"/> */}
                      <div className="image">
                        {console.log('sfhgdfhdf', InfoDetail)}
                        {!isEmpty(InfoDetail) ? (
                          InfoDetail.type == "audio" ?
                            <ImgAudVideo
                              file={InfoDetail.metadata.animation_url}
                              type={"audio"}
                              thumb={InfoDetail.Image}
                              from="info"
                              origFile={InfoDetail.metadata.animation_url}
                            />
                            :
                            <iframe style={{
                              background: 'url(' + InfoDetail?.Image + ')', backgroundRepeat: "no-repeat",
                              backgroundSize: "100% 100%", maxHeight: 288, minHeight: 288, minWidth: "100%", maxWidth: "100%", borderRadius: 15
                            }} height="288" width="288" title="Iframe Example" id="myiFrame"></iframe>
                        ) : (
                          <ImgAudVideo
                            file={Tokens_Detail?.NFTOrginalImage === undefined || null ? "" : `${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail?.NFTOrginalImage}`}
                            type={
                              Tokens_Detail?.NFTOrginalImage
                                ? Tokens_Detail?.NFTOrginalImage?.includes(".webp")
                                  ? "image"
                                  : Tokens_Detail?.NFTOrginalImage.includes(".webm")
                                    ? "video"
                                    : "audio"
                                : Tokens_Detail?.NFTOrginalImage
                            }
                            thumb={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT_THUMB/${Tokens_Detail.CompressedThumbFile}`}
                            from="info"
                            origFile={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail.OriginalFile}`}
                          />


                        )}
                        {console.log("Tokens_Detail.CompressedFile",Tokens_Detail.CompressedFile,Tokens_Detail?.NFTOrginalImage)}
                        {/* <img src={require("../assets/images/banner-new.jpg")} alt="Image" /> */}
                      </div>
                      <div class="card-body">
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tf-item-detail-inner">
                      <div className="content">
                        <div className="content-top koo">
                          {isEmpty(InfoDetail) && (
                            <div className="wishlish">
                            
                              <div className="option btn-option">
                                <i className="far fa-ellipsis-h"></i>
                                <div className="option_popup">

                                  {Tokens[TabName]?.owner?.NFTOwner !== payload?.WalletAddress && <a href="#" onClick={() => POPUPACTION('Report', Tokens[TabName]?.owner)}>Report</a>}
                                  
                                  <a href="#" onClick={() => POPUPACTION('Share', Tokens[TabName]?.owner)}>Share</a>
                                  {(Tokens[TabName]?.owner?.NFTOwner == payload?.WalletAddress && !Tokens[TabName]?.owner?.isStake && Tokens[TabName]?.owner?.PutOnSale == "true") && <a onClick={() => POPUPACTION(
                                    "createorder",
                                    Tokens[TabName]?.myowner,
                                    "ChangePrice"
                                  )}>Change Price</a>}
                                </div>
                              </div>
                              <div
                                className="number-wishlish"
                                onClick={() => LikeAction()}

                              >
                                {(LikedTokenList?.some(
                                  (value) => value.NFTId === Tokens_Detail.NFTId
                                ) ? (
                                  <i className="fa fa-heart active"></i>) : <i className="fa fa-heart"></i>)}{Tokens_Detail?.likecount}
                              </div>

                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <h2 className="title-detail titledetails">
                      {!isEmpty(InfoDetail)
                        ? InfoDetail?.NFTName?.length > 15 ? <>{InfoDetail?.NFTName.slice(0, 15)}...</> : InfoDetail?.NFTName
                        : Tokens_Detail?.NFTName?.length > 15 ? <>{Tokens_Detail?.NFTName.slice(0, 15)}...</> : Tokens_Detail?.NFTName}
                    </h2>
                    {/* <p className="created pt-2 mb-3"><span>Minted on : </span> <span className="amountss">Sep 30, 2022</span></p> */}
                    
                    <div className="mt-4">
                      <p className="created d-flex align-items-center">
                        {/* <span>Owned By : </span> */}
                        <span className="amountss mx-0">
                          {!isEmpty(InfoDetail) ? (
                            <div className="author d-flex align-items-center jk">
                              <img
                                src={
                                  Tokens_Detail?.Profile
                                    && !isEmpty(Tokens_Detail?.Profile) ? `${config.IMG_URL}/user/${Tokens_Detail?.WalletAddress}/profile/${payload?.Profile}`
                                    : author
                                }
                                alt="Image"
                              />
                              <Link to={"/profile" + "/" + Tokens[TabName]?.owner?.CustomUrl}>
                                <h6 className="title">
                                  {payload?.DisplayName
                                    ? payload?.DisplayName
                                    : address_showing(payload?.WalletAddress)}
                                </h6>
                              </Link>
                            </div>
                          ) : (
                            <div className="author d-flex align-items-center jk">
                              <img
                                src={
                                  Tokens[TabName]?.owner?.Profile
                                    && Tokens[TabName]?.owner?.Profile != "" ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                    : author
                                }
                                alt="Image"
                              />

                              <Link to={Tokens[TabName]?.owner?.CustomUrl ? "/profile" + "/" + Tokens[TabName]?.owner?.CustomUrl : "#"}>
                                <h6 className="title">
                                  {Tokens[TabName]?.owner?.DisplayName
                                    ? Tokens[TabName]?.owner?.DisplayName
                                    : address_showing(
                                      Tokens[TabName]?.owner?.NFTOwner
                                    )}
                                </h6>
                              </Link>
                            </div>
                          )}
                        </span>
                      </p>
                    </div>
{Tokens_Detail?.NFTDescription || InfoDetail?.NFTDescription &&  <div className="mt-4">
                    <h6>Description</h6>
                      <p className="except mt-3">
                        {isEmpty(InfoDetail) ? Tokens_Detail?.NFTDescription : InfoDetail?.NFTDescription}

                      </p>
                    </div> }

                  
                    {/* <div className="pricedis">
                           
                                  <p className="priceam ">Price  : </p>
                                  <span className="amountss">0.001  BNB</span>
                                
                             
                                
                              </div> */}







                    <div className="tf-tab pt-4">

                      <ul className="menu-tab tabsections">
                        <li
                          className={
                            TabName == "All"
                              ? "tab-title active"
                              : "tab-title"
                          }
                          onClick={() => tabChange("All")}
                        >
                          <a>Details</a>
                        </li>
                        <li
                          className={
                            TabName == "owner"
                              ? "tab-title active"
                              : "tab-title"
                          }
                          onClick={() => tabChange("owner")}
                        >
                          <a>Owner</a>
                        </li>

                      </ul>
                      <div className="content-tab">
                        <div
                          className={
                            TabName == "All"
                              ? "content-inner active d-block"
                              : "content-inner d-none"
                          }
                        >
                          <div className="tab-details">
                            <div className="top">
                              <div className="author">
                                <div className="heading">Current Owner</div>{console.log('sdkgjkdjgkdjg', Tokens[TabName]?.owner?.NFTOwner, !isEmpty
                                  (Tokens[TabName]?.owner?.Profile), InfoDetail)}
                                {!isEmpty(InfoDetail) ? (
                                  <div className="infor">
                                    <img
                                      src={
                                        Tokens[TabName]?.owner
                                          && !isEmpty
                                            (Tokens[TabName]?.owner?.Profile) ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                          : config.profile
                                      }
                                      alt="Image"
                                    />
                                    <Link to={"/profile" + "/" + Tokens[TabName]?.owner?.CustomUrl}>
                                      <h6 className="name">
                                        {payload?.DisplayName
                                          ? payload?.DisplayName
                                          : address_showing(
                                            payload?.WalletAddress
                                          )}
                                      </h6>
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="infor">
                                    <img
                                      src={
                                        Tokens[TabName]?.owner?.Profile
                                          && !isEmpty(Tokens[TabName]?.owner?.Profile) ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                          : author
                                      }
                                      alt="Image"
                                    />
                                    <Link to={Tokens[TabName]?.owner?.CustomUrl ? "/profile" + "/" + Tokens[TabName]?.owner?.CustomUrl : "#"}>
                                      <h6 className="name">
                                        {Tokens[TabName]?.owner?.DisplayName
                                          ? Tokens[TabName]?.owner?.DisplayName
                                          : address_showing(
                                            Tokens[TabName]?.owner?.NFTOwner
                                          )}
                                      </h6>
                                    </Link>
                                  </div>
                                )}
                              </div>
                              <div className="author">
                                <div className="heading">Creator</div>
                                {!isEmpty(InfoDetail) ? (
                                  <div className="infor">
                                    <img
                                      src={
                                        Tokens_Detail?.Creator_Profile
                                          && !isEmpty
                                            (Tokens_Detail?.Creator_Profile) ? `${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Profile}`
                                          : author
                                      }
                                      // Tokens_Detail
                                      alt="Image"
                                    />

                                    <Link to={"/profile" + "/" + Tokens_Detail?.Creator_CustomUrl}>
                                      <h6 className="name">
                                        {Tokens_Detail?.Creator_DisplayName
                                          ? Tokens_Detail?.Creator_DisplayName
                                          : address_showing(
                                            Tokens_Detail?.Creator_WalletAddress
                                          )}
                                      </h6></Link>
                                  </div>
                                )
                                  :
                                  (
                                    <div className="infor">
                                      <img
                                        src={
                                          Tokens_Detail?.Creator_Profile
                                            && !isEmpty
                                              (Tokens_Detail?.Creator_Profile) ? `${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Creator_Profile}`
                                            : author
                                        }
                                        // Tokens_Detail
                                        alt="Image"
                                      />

                                      <Link to={"/profile" + "/" + Tokens_Detail?.Creator_CustomUrl}>
                                        <h6 className="name">
                                          {Tokens_Detail?.Creator_DisplayName
                                            ? Tokens_Detail?.Creator_DisplayName
                                            : address_showing(
                                              Tokens_Detail?.NFTCreator
                                            )}
                                        </h6>
                                      </Link>
                                    </div>
                                  )}
                              </div>
                            </div>
                            {console.log('proppperrrtiiiesss,', Tokens_Detail?.NFTProperties, Tokens_Detail?.NFTProperties?.length)}
                            {Tokens_Detail?.NFTProperties?.length > 0 &&
                              !isEmpty(Tokens_Detail?.NFTProperties[0]) &&
                              <>
                                <div className="title-propepties">Properties</div>
                                <ul className="properties">
                                  {Tokens_Detail?.NFTProperties?.map((val, ind) =>
                                    <li>
                                      <svg
                                        width="12"
                                        height="14"
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 11.375L1.09375 7.53125L0 8.375L6 13.0312L12 8.375L10.9062 7.53125L6 11.375ZM6 9.65625L10.9062 5.84375L12 5L6 0.34375L0 5L1.09375 5.84375L6 9.65625ZM6 2.03125L9.8125 5L6 7.96875L2.1875 5L6 2.03125Z"
                                          fill="white"
                                        />
                                      </svg>

                                      {Object.keys(val)[0].length > 15 ? <>{Object.keys(val)[0].slice(0, 15)}...</> : Object.keys(val)[0]} : {Object.values(val)[0].length > 15 ? <>{Object.values(val)[0].slice(0, 15)}...</> : Object.values(val)[0]}
                                    </li>

                                  )}

                                  {(moreprops > 6 && moreprops <= 9) && <li className="active" onClick={Seemore()}>See more</li>}
                                </ul>
                              </>}


                           
                          </div>
                        </div>
                        <div
                          className={
                            TabName == "owner"
                              ? "content-inner active d-block"
                              : "content-inner d-none"
                          }
                        >
                          <ul className="tab-bid">
                            {!isEmpty(InfoDetail) ? (
                              <li>
                                <div className="box-bid">
                                  <div className="image-bid">
                                    <img
                                      src={
                                        Tokens[TabName]?.owner?.Profile
                                          && !isEmpty(Tokens[TabName]?.owner?.Profile) ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                          : author
                                      }
                                      alt="Image"
                                    />
                                  </div>
                                  <div className="infor">
                                    <div className="history">
                                      <span className="name">
                                        {payload?.DisplayName
                                          ? payload?.DisplayName
                                          : address_showing(
                                            payload?.WalletAddress
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              Tokens["owner"]?.list?.map((data, key) => {
                                return (
                                  <li>
                                    <div className="box-bid">
                                      <div className="image-bid">
                                        <img
                                          src={
                                            data?.Profile
                                              && !isEmpty(data?.Profile) ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                              : author
                                          }
                                          alt="Image"
                                        />
                                      </div>
                                      <div className="infor">
                                        <div className="history">
                                          {
                                            // (data.PutOnSaleType ==
                                            //   "FixedPrice" ||
                                            //   data.PutOnSaleType ==
                                            //     "TimedAuction") &&
                                            (
                                              <span className="price">
                                                {Tokens_Detail?.NFTCreator === data?.NFTOwner ?
                                                  <>{data?.NFTPrice} {data?.CoinName} </> :
                                                  <>{data?.buyprice?.NFTPrice} {data?.buyprice?.CoinName}</>
                                                }{" "}
                                                by
                                              </span>
                                            )}{" "}
                                          {" "}
                                          <span className="name">
                                            {data?.DisplayName
                                              ? data?.DisplayName
                                              : address_showing(
                                                data?.NFTOwner
                                              )}
                                          </span>
                                        </div>
                                        <div className="time">
                                          {moment(data?.updatedAt).fromNow()}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })
                            )}
                          </ul>
                        </div>
                        {(Tokens[TabName]?.owner?.NFTPrice != "0" ||  Tokens[TabName]?.owner?.NFTPrice != 0) &&  <>
                              <div>
                                <p className="created"><p className="priceam mt-3">Price </p></p>
                                <span className="amountss">{Tokens[TabName]?.owner?.NFTPrice}<span className="coinclr ml-2">{Tokens[TabName]?.owner?.CoinName}</span></span>
                              </div>
                            </>}
                      </div>
                    </div>



                    <div>
                     

                      {Tokens["All"]?.owner?.PutOnSaleType ===
                        "TimedAuction" &&
                        <div className="featured-countdown mb-2 justify-content-between align-items-center auctionbid">
                          {new Date(Tokens["All"]?.owner?.ClockTime) > Date.now() ? (
                            <span>Auction Yet to Start {Math.ceil((new Date(Tokens["All"]?.owner.ClockTime).getTime() - new Date(Date.now()).getTime()) / (1000 * 3600 * 24))} Days..!</span>
                          )
                            :
                            ((new Date(Tokens["All"]?.owner?.EndClockTime) > Date.now()) ?
                              <>

                                <span className="ml-1 js-countdown">
                                  {new Date(
                                    Tokens["All"]?.owner?.EndClockTime
                                  ) >= Date.now() && (
                                      <Countdown
                                        date={Tokens["All"]?.owner?.EndClockTime}
                                        // autoStart={true}
                                        renderer={renderer}
                                      />
                                    )}
                                </span>
                              </>
                              :
                              <span>Auction Ended</span>
                            )}
                        </div>
                      }
                    </div>

                    <div className="button try">
                      {!isEmpty(InfoDetail) && (
                        <Button
                          className="tf-button"
                          onClick={() => {
                            push(`/list/${payload?.WalletAddress}/${Id}`, {
                              state: InfoDetail,
                            })
                          }
                          }
                        >
                          List on Marketplace
                        </Button>
                      )}
                      {console.log("Tokens[TabName]?.owner?.isStake",typeof(Tokens[TabName]?.owner?.isStake),!Tokens[TabName]?.owner?.isStake,isEmpty(InfoDetail),Tokens[TabName]?.owner,(!Tokens[TabName]?.owner?.isStake  && isEmpty(InfoDetail)),accountAddress)}
                      {
                      (!Tokens[TabName]?.owner?.isStake) &&
                      // (Tokens[TabName]?.myowner?.NFTOwner !== accountAddress 
                      //   && Tokens[TabName]?.owner?.PutOnSale == "false") 
                      //   && <Button className="tf-button btn btn-primary" 
                      // >This Token is not in sale</Button>
                      // :
                      // (Tokens[TabName]?.owner?.PutOnSale == "false") &&
                        (Tokens_Detail?.ContractType?.toString() ===
                          "721" ? (
                          Tokens[TabName]?.myowner?.WalletAddress ==
                            accountAddress ? (
                            Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "FixedPrice" ? (
                              <Button
                                className="tf-button"
                                onClick={() =>
                                  POPUPACTION(
                                    "Cancel",
                                    Tokens[TabName]?.myowner
                                  )
                                }
                              >
                                Cancel Now
                              </Button>
                            ) : Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "NotForSale" ||
                              Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "UnlimitedAuction" ||
                              (Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "TimedAuction" &&
                                new Date(
                                  Tokens[TabName]?.myowner.EndClockTime
                                ).getTime() < Date.now()) ? (
                              <Button
                                className="tf-button"
                                onClick={() =>
                                  POPUPACTION(
                                    "createorder",
                                    Tokens[TabName]?.myowner,
                                    "Put On Sale"
                                  )
                                }
                              >
                                Put on Sale
                              </Button>
                            ) : (
                              Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "TimedAuction" &&
                                new Date(Tokens[TabName]?.myowner?.ClockTime) > Date.now() ? (
                                <a href="#" className="tf-button">
                                  Auction Not Started Yet
                                </a>
                              ) :
                                (new Date(Tokens[TabName]?.myowner?.EndClockTime).getTime() > Date.now()
                                  && <a href="#" className="tf-button text-center">
                                    Auction is Live Now
                                  </a>)
                            )
                          ) : (
                            Tokens[TabName]?.owner &&
                            Tokens[TabName]?.owner?.WalletAddress !=
                            accountAddress &&
                            (Tokens[TabName]?.owner?.PutOnSaleType ==
                              "FixedPrice" ? (
                              <Button
                                className="tf-button"
                                onClick={() =>
                                  POPUPACTION("Buy", Tokens[TabName]?.owner)
                                }
                              >
                                Buy Now
                              </Button>
                            ) : (
                              Tokens[TabName]?.myBid?.WalletAddress ==
                              accountAddress && (
                                <Button
                                  className="tf-button"
                                  onClick={() =>
                                    POPUPACTION("CancelBid", Tokens[TabName]?.myBid)
                                  }
                                >{console.log('biiiddds', Tokens[TabName], Tokens[TabName]?.myBid)}
                                  Cancel Bid
                                </Button>
                              )
                            ))
                          )
                        ) : Tokens[TabName]?.myowner?.WalletAddress ==
                          Tokens[TabName]?.owner?.WalletAddress ? (
                          <>
                            {Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "FixedPrice" && (
                                <Button
                                  className="tf-button"
                                  onClick={() =>
                                    POPUPACTION(
                                      "Cancel",
                                      Tokens[TabName]?.myowner
                                    )
                                  }
                                >
                                  Cancel Now
                                </Button>
                              )}
                            {Tokens[TabName]?.myBid?.WalletAddress ==
                              accountAddress ? (
                              <Button
                                className="tf-button"
                                onClick={() =>
                                  POPUPACTION("Bid", Tokens[TabName]?.myBid)
                                }
                              >
                                Edit Bid
                              </Button>
                            ) : (
                              Tokens[TabName]?.myowner?.WalletAddress !=
                              Tokens[TabName]?.owner?.WalletAddress && (
                                <Button
                                  className="tf-button"
                                  onClick={() => POPUPACTION("Bid", {})}
                                >
                                  Bid Now
                                </Button>
                              )
                            )}
                          </>
                        ) : Tokens[TabName]?.owner?.PutOnSaleType ===
                          "FixedPrice" ? (
                          <Button
                            className="tf-button"
                            onClick={() =>
                              POPUPACTION("Buy", Tokens[TabName].owner)
                            }
                          >
                            Buy Now
                          </Button>
                        ) : (
                          Tokens[TabName]?.myBid?.WalletAddress ==
                          accountAddress && (
                            <Button
                              className="tf-button"
                              onClick={() => POPUPACTION("CancelBid", Tokens[TabName]?.myBid)}
                            >
                              Cancel Bid
                            </Button>
                          )
                        ))
                      // :

                      // <Button className="tf-button btn btn-primary" 
                      //   >This Token is not in sale</Button>
                      
                      }


{console.log("DSDFSDFSDF",isEmpty(Tokens[TabName]?.owner?.HashValue),Tokens[TabName]?.owner?.HashValue,"fff",Tokens[TabName])}

                      {Tokens[TabName]?.owner?.HashValue == "not minted" ? "" : 
                        Tokens[TabName]?.owner?.NFTOwner == payload?.WalletAddress ?
                        <Button className="tf-button btn btn-primary" onClick={() =>
                          POPUPACTION("Stake", Tokens[TabName]?.myowner)
                        }>{Tokens[TabName]?.owner?.isStake ? "UnStake" : "Stake"}</Button>
                        :
                        (Tokens[TabName]?.owner?.isStake && Tokens[TabName]?.owner?.NFTOwner !== payload?.WalletAddress) && 
                        <Button className="tf-button btn btn-primary" 
                        >This Token is Staked</Button>
                      }

{/* {Tokens[TabName]?.owner?.PutOnSale == "false" && <Button className="tf-button btn btn-primary" 
                        >This Token is not in sale</Button>
}
                      */}
                      

                      
                    </div>

                    

                  </div>



                </div>

              </div>
            </section>


            {isEmpty(InfoDetail) && (
              <section className="tf-explore-more">
                <div className="tf-container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="tf-heading">
                        {/* <h4 className="heading">Explore More</h4> */}
                        <h2 className="headingzz pt-4 pb-5">Explore <span style={{ color: 'black' }}> More</span></h2>
                      </div>
                    </div>
                    {Explores.map((item, index) => (

                      <div
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                        data-aos="zoom-in-up"
                      >
                        {console.log("jdkjfsd", item)}
                        <Card
                          product={item}
                          index={index}
                          LikeList={LikeList}
                          LikedTokenList={LikedTokenList}
                          setLikedTokenList={setLikedTokenList}
                          LikeForwardRef={LikeForwardRef}
                        />
                      </div>
                    ))}
                    <div className="col-md-12">
                      <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                        <NavLink to="/explore" className="tf-button style-8 loadmore">Explore More
                          {/* <i className="far fa-long-arrow-right"></i> */}
                        </NavLink>
                      </div>
                    </div>
                  </div>

                </div>
              </section>
            )}
          </>
        }
        <Footer />
      </div>
    </>
  )
}