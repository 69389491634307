import { Button } from "@mui/material"
import { useState } from "react"
import ReactDatetimeClass from "react-datetime"

export default function CLockModal({modal,setClockValue,closeModal,validDate}){

const [Clock , setClock ] = useState(null)
const CloSeModal = () => {
    closeModal()
}
const handleendclock = (value) => {
    setClock(value)
    setClockValue(modal,value)
}
    console.log('calendarr',modal,setClockValue)
    return <div className="modal show fade primary_modal calendar" style={{display:"block"}} id="calendar_modal_expire" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content">
            <div className="modal-header text-center">
                <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={CloSeModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="pb-3">
                    <ReactDatetimeClass
                    input={false}
                    value={Clock}
                    isValidDate={validDate}
                    timeFormat="YYYY-MM-DD HH:mm:ss"
                    onChange={(value) => handleendclock(value)}    />   

                </div>
            </div>
            <div className="text-center-modal pb-3 mb-3">
                <Button className="btn create_btn" id="doneEndDate" onClick={CloSeModal}>Done</Button>
            </div>
        </div>
    </div>
</div>
}