import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Header from "../separate/Header1";
import Footer from "../separate/Footer1";
import leftcurve from '../assets/images/joyimg/leftcurve.png';
import rightcurve from '../assets/images/joyimg/rightcurve.png';
import rubyimg from "../assets/images/joyimg/ruby.png";
import diamondimg from "../assets/images/joyimg/Frame1.png";
import goldimg from "../assets/images/joyimg/goldbg.png";
import bronzeimg from "../assets/images/joyimg/bronze.png";
import silverimg from "../assets/images/joyimg/silver.png";
export default function Cardartwork(props) {
// console.log("priview",props)
var imgarr=[rubyimg,diamondimg,goldimg,bronzeimg,silverimg]
var title= [ "NFTname","Not For Sale","Staked"]
// console.log("iii",imgarr[Math.random(0,5)],Math.floor(Math.random() * 4) + 1);
    return (

        <>
              <div className="inheaders">


      <div className="sc-product style1" 
      style={{background: `url(${imgarr[Math.floor(Math.random() * 4) + 1]})`}}
     
      >




        
      
        <div className="features">
          <div
            className="product-media  audImgVid"
          
          >
            
              <img src={props.product}/>
          

          
          </div>
         
         
        </div>
        {/* {console.log("productttt", product)} */}
        <div className="bottom pads">
          <div className="text-center fullname">
          NFTname
          </div>
          <div>
            <p className="ETHno">{props?.price ? props?.price : "0.01"} {props?.coin ?props?.coin : "BNB" } </p>
          </div>
          <div className="product-buttons buynowbuttoon">
            
                
                <span className="icon-btn-product"></span>
                Owned
            </div>

        </div>


    </div>     
    {/* <Footer />        */}
    </div>   
        </>
    );
}