import React from "react";
import authorImg from "../assets/images/banner.png";
import Binance from '../assets/images/svg/binance.png'
import productImg from "../assets/images/product52.jpg";
import ethImg from "../assets/images/ethuu.png";
import Countdown from "react-countdown";
import { NavLink, useNavigate, Link } from "react-router-dom";
import ImgAudVideo from "../separate/ImgAudVideo";
import config from "./config/config";
import { address_showing } from "../actions/common";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LikeRef } from "./seperatemodals/LikeRef";

import timer from "../assets/images/timer.png";

import diamondimg from "../assets/images/joyimg/Frame1.png";
import goldimg from "../assets/images/joyimg/goldbg.png";
import bronzeimg from "../assets/images/joyimg/bronze.png";
import silverimg from "../assets/images/joyimg/silver.png";
import rubyimg from "../assets/images/joyimg/ruby.png";

import author from '../assets/images/poo.jpg';
import endless from '../assets/images/endless.png';

export default function Card({
  product,
  index,
  LikeList,
  LikedTokenList,
  setLikedTokenList,
  LikeForwardRef,
  from,
}) {
  console.log("likrreeeecardd", product);
  const { accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  const LikeAction = async () => {
    // console.log("ksgfsdga", LikeForwardRef, accountAddress, product);
    if (accountAddress) {
      var check = await LikeForwardRef.current.hitLike(product);
      toast.success("you " + check + "d token", { autoClose: 500, closeButton: true, closeOnClick: true });
    } else toast.error("Connect Wallet", { autoClose: 1000, closeButton: true, closeOnClick: true });
  };

  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(days)} d</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };

  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };
  const push = useNavigate();
  return (
    <div
    // data-aos="zoom-in-up"
    >
      {index === 0 && (
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          LikeList={LikeList}
        />
      )}
      {/* {product.map((data,index) => { return( */}

      <div className="sc-product style1" 
      style={{background: `url(${product?.tokenowners_list?.Category=="silver" ?
      silverimg :
      product?.tokenowners_list?.Category=="gold" ? 
      goldimg : 
      product?.tokenowners_list?.Category=="bronze" ? 
      bronzeimg:
      product?.tokenowners_list?.Category=="diamond" ?
      diamondimg :
      rubyimg
    })`,}}
     
      >




        
        {/* <div className="top"> */}
          {/* <Link
              to={ product.from !== "othercollection" ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : accountAddress === product?.NFTCreator ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
            }
            state={{data: (from == "usercollection" ? product : null)}}
              className="tag"
            >
              {product?.NFTName?.length>13 ? product?.NFTName?.substring(0,10) + "..." : product?.NFTName}
            </Link> */}
          {/* {console.log('adgvhkdgsk',product?.NFTName?.length)} */}
          {/* {from !== "usercollection" &&
            (LikedTokenList?.some(
              (value) => value.NFTId === product.NFTId
            ) ? (
              <div className="wish-list active">
                <Link
                  to="#"
                  className="heart-icon"
                  onClick={() => LikeAction()}
                ></Link>
              </div>
            ) : (
              <div className="wish-list">
                <Link
                  to="#"
                  className="heart-icon"
                  onClick={() => LikeAction()}
                ></Link>
              </div>
            ))}
        </div> */}
        <div className="features">
          <div
            className="product-media  audImgVid"
            onClick={() => {
              
                push(
                  `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`, { state:  product }
                );
            }}
          >
            {/* <NavLink to="/details"> */}

            {/* {console.log(
                "tokenntypeee",
                product?.CompressedFile,
                product,
                product?.CompressedFile?.includes(".mp3")
              )} */}
            {from === "usercollection" ? (
              <iframe style={{
                background: 'url(' + product?.Image + ')', backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%", maxHeight: 288, minHeight: 288, minWidth: 288, maxWidth: 288, borderRadius: 15
              }} height="200" width="300" title="Iframe Example" id="myiFrame"></iframe>
              // <object data={product?.Image} width="288" height="288"></object>
              //  <iframe style={{ background: 'url(' + product?.Image + ')',backgroundRepeat: "no-repeat",
              //  backgroundSize: "100% 100%",maxHeight: 288,minHeight: 288,minWidth: 288,maxWidth: 288,borderRadius:15}} height="200" width="300" title="Iframe Example" id="myiFrame"></iframe> 
              // <img src={product?.Image} className="img-fluid" />
            ) : (
              // <img src= {`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`}/>
              <ImgAudVideo
                file={`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`}
                origFile={`${config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT/${product?.NFTOrginalImage}`}
                thumb={`${config.IMG_URL}/nft/${product.NFTCreator}/Compressed/NFT_THUMB/${product?.CompressedThumbFile}`}
                type={
                  product?.CompressedFile
                    ? product?.CompressedFile?.includes(".webp")
                      ? "image"
                      : product?.CompressedFile.includes(".webm")
                        ? "video"
                        : "audio"
                    : product?.CompressedFile
                }
              />
            )}

           
          </div>
         
         

          
        </div>
        {console.log("productttt", product,product.PutOnSaleType)}
        <div className="bottom pads">
          <div className="text-center fullname">
            <Link to={`/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`}>
              {product?.NFTName?.length > 13 ? product?.NFTName?.substring(0, 10) + "..." : product?.NFTName}
            </Link>
          </div>
          <div>
            {(product?.NFTPrice == "0" || product?.NFTPrice == 0 ) ? "" : <p className="ETHno">{product?.NFTPrice && product?.NFTPrice} {product?.CoinName} </p>}
          </div>
          <div className="product-buttons buynowbuttoon">
              <Link
                 to={`/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`}
                 state={{ state: product }}

                data-toggle="modal"
                data-target="#popup_bid"
                className="tf-buttons"
              >


                {" "}
                <span className="icon-btn-product"></span>
                
                { 
                  (product?.isStake == false  && product?.NFTOwner == accountAddress) ?
                  "Owned" 
                  : 
                  (product?.isStake == false  && product?.NFTOwner != accountAddress) ?
                  product.PutOnSale=="false"?
                  "NotForSale"  :
                  "Buy Now"
                  :
                  "Staked" 
                  
                  }
              </Link>
            </div>

        

        </div>

      </div>

    </div>
  );
}
