import config from '../../views/config/config'
import {axiosFunc, AppenData} from '../common'

var token ;

export const GetNftCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        console.log('egfwafwer',parts)
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
      }, '');
}

export const imgageupload=async(data)=>{
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/imagefileupload`,
        data    :   data,
        
    }
    console.log('jsgfds',senddata)
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const buyipfsandupdate=async(data)=>{
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/buyipfs`,
        data    :   data,
        
    }
    console.log('jsgfds',senddata)
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
//NFT Name Validation Function
export const nftNameValidation   =   async   (data)  =>  {
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/ValidateTokenName`,
        data    :   data,
        'headers': {
            'Authorization': token
          }
    }
    console.log('jsgfds',senddata)
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
//nftCreate axios
export const nftCreate    =   async   (data)  =>  {
    console.log("UserRegister is Calling",data)
    var formdata = AppenData(data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/nftcreate`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
    }
    
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

//Nft Image Upload Function
export const NFTImageUpload   =   async   (data)  =>  {
    var formdata = AppenData(data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/nftimageupload`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

// Create New NFT
export const CreateNFT    =   async   (data)  =>  {
    if(data.from == 'othercollection'){
        var senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/nft/listcollectionnft`,
            data    :   data,
            'headers': {
                'Authorization': token
            }
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp.data
    }
    else{
        var senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/nft/createnft`,
            data    :   data,
            'headers': {
                'Authorization': token
            }
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp.data
    }
}

// Get Category Function
export const Category    =   async   (data)  =>  {
    
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/getcategory`,
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

//GET All NFT
export const Token_List_Func    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/Tokenlistfunc`,
        params  :   data
        }
    let Resp    =   await axiosFunc(senddata)
    console.log("varRespexplore",Resp.data)

    return Resp.data
}

//Get NFT info
export const Token_Info_Func    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/info`,
        params  :   data
        }
    let Resp    =   await axiosFunc(senddata)

    return Resp.data
}

//Buy And Accept 
export const BuyAccept =   async(data) =>  {
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/BuyAccept`,
        data    :   data,
        'headers': {
            'Authorization': token
          }
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}


export const Buymint =   async(data) =>  {
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/Buymint`,
        data    :   data,
        'headers': {
            'Authorization': token
          }
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

//put on Sale
export const CreateOrder    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/CreateOrder`,
        data    :   data,
        'headers': {
            'Authorization': token
          }
        
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

//Bid Function
export const BidApprove =   async(FormValue) =>  {
    var senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/nft/BidAction`,
            data    :   FormValue,
            'headers': {
                'Authorization': token
            }
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const TopCreatorApi  =   async   ()  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/TopCreatorApi`
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const HotAuctionHotSales =   async   ()  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/HotAuctionHotSale`
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const CreateCollectionFunc = async(data) =>  {
    var formdata = AppenData(data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/CreateCollectionFunc`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
          }
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const getnfttaglist    =   async   (data)  =>  {
    
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/user/getnfttag`,
        }
    let Resp    =   await axiosFunc(senddata)
    console.log("nfttt",Resp);
    return Resp.data
}
export const CollectionByCreator =   async   (data)  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/CollectionByCreator`,
        params   :   data ?? {}
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
export const Activity_List_Func = async(data) => {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/Activity_List_Func`,
        params   :   data ?? {}
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
export const CollectionBySymbol = async(data) => {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/CollectionBySymbol`,
        params   :   data ?? {}
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const Getpromotedtoken = async() =>{
    var senddata  =  {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/getpromotedtoken`,
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const EditCollectionbyCreator = async(data) =>{
    var formdata = AppenData(data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/editcollectionbycreator`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
          }
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const GetNFTOwner = async(data) =>{
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/getnftowner`,
        data    :   data
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const NftbalanceUpdate = async(data) =>{
    var senddata  =  {
        method  :   'POST',
        url     :   `${config.BACK_URL}/nft/findupdatebalance`,
        data    :   data
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const Enablestake = async(data) =>{
    var senddata  =  {
        method  :   'POST',
        url     :   `${config.BACK_URL}/nft/EnableStake`,
        data    :   data
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
