import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Enablestake } from '../../actions/axioss/nft.axios';
import useContractProviderHook from "./../../actions/contractProviderHook";
import detailimg from '../../assets/images/joyimg/fox.jpg';
import ImgAudVideo from "../../separate/ImgAudVideo";
import config from '../../views/config/config'
import { useSelector,useDispatch } from "react-redux";


export function Stakemodal ({ owner, types, closePop, file, type, thumb, item }) {
  console.log("dsfsdsd",item);
  const ContractCall = useContractProviderHook()
  const {Pooldata}= useSelector(state => state.LoginReducer);
  const dispatch = useDispatch()
  const [claim,setClaim]=useState()
  const [stake,setStake]=useState()

// console.log("getpooll",Pooldata,new Date(new Date().getTime() + 60 * 60 * 24 * Number(Pooldata.intervaltime)))
    const[stakemodal, setStakemodal] = useState(true);
    const [approve,setApprove]=useState()
    // const [Reward,setReward]=useState()

    useEffect(()=>{
        getapprovestatus()
        setAllstate()
    },[])




    const setAllstate = () => {
      if(item.isStake){
        let hourortime = Number(Pooldata.intervaltime.toFixed(2))*24 <= 1 ? "hour" : "day"
        var staketime= new Date(item.isClaimupdate).getHours()
        let stakedate = new Date(item.isClaimupdate).getDate()
let stakemont = new Date(item.isClaimupdate).getMonth()

        if(hourortime == "hour"){
          if(staketime == new Date().getHours()){
            setClaim(stakedate < new Date().getDate())
          }else{
           console.log("crtstakeclaim",(Number(new Date().getHours()) - Number(staketime) ),Math.round(Number(Pooldata.intervaltime)*24));
           setClaim((Number(new Date().getHours()) - Number(staketime) ) >= Math.round(Number(Pooldata.intervaltime)*24))
          }
         }
         if(hourortime == "day"){
          if(stakedate == new Date().getDate() ){
            setClaim(stakemont < new Date().getMonth())
          }else{
            setClaim(stakedate < new Date().getDate())
        }
      }
      else{
        setClaim(false)
      }


      //stakedisable
      let hourtime = Number(Pooldata.lockperiod)
        
      let stakdate = new Date(item.isStakeupdate).getDate()
      let stakemonth= new Date(item.isStakeupdate).getMonth()


if((Number(stakdate) - Number(new Date().getDate())) == 0 || (Number(stakdate) - Number(new Date().getDate())) == "0" ){
  setStake(stakemonth < new Date().getMonth())
}
else{
console.log("1");
setStake((Number(stakdate) - Number(new Date().getDate())) == hourtime || Number(stakdate) < Number(new Date().getDate()))
}
    }
    else{
      setClaim(false)
      setStake(false)
    }








      
    }
  
const getapprovestatus=async()=>{
  let Statu = await ContractCall.GetApproveStatus(
    item.ContractType == 721 || item.ContractType == "721"
      ? "Single"
      : "Multiple",
    item.ContractAddress,
    true
  );
  setApprove(Statu)

}

    const enablestake=async()=>{
      var poolid =config.poolid;
      // console.log("STAKEPARAMs",item.isStakeupdate,Number(new Date(item.isStakeupdate).getDate()),Number(new Date().getDate()),Number(Pooldata.lockperiod),(Number(new Date(item.isStakeupdate).getDate()) - Number(new Date().getDate())))
      const id = toast.loading("Staking on Process");
      var hash
      if(!item.isStake){
        if(!approve){
          const cont = await ContractCall.SetApproveStatus(
            item.ContractType == 721 || item.ContractType == "721"
              ? "Single"
              : "Multiple",
            item.ContractAddress,
            true
          )
        }
        hash=await ContractCall.EnableStake(item.NFTOwner,item.NFTId,item.ContractAddress,poolid);
      }
      else{

        let hourortime = Number(Pooldata.lockperiod)
        
        let stakedate = new Date(item.isStakeupdate).getDate()
        let stakemonth= new Date(item.isStakeupdate).getMonth()

console.log("dateee",hourortime,"0",(Number(stakedate) - Number(new Date().getDate())) == 0 || (Number(stakedate) - Number(new Date().getDate())) == "0")

if((Number(stakedate) - Number(new Date().getDate())) == 0 || (Number(stakedate) - Number(new Date().getDate())) == "0" ){
  console.log("0",stakemonth < new Date().getMonth());
if ( stakemonth < new Date().getMonth()){
  hash= await ContractCall.NFTWithdraw(item.NFTOwner,item.NFTId)
}
else{
  setTimeout(() => {
    return toast.update(id,{render : "You can't unStake within Lockperiod",isLoading : false ,autoClose :1000 })
      
    }, 1000);}
}
else{
  console.log("1");
  if((Number(stakedate) - Number(new Date().getDate())) == hourortime || Number(stakedate) < Number(new Date().getDate())){
  hash= await ContractCall.NFTWithdraw(item.NFTOwner,item.NFTId,item.ContractAddress)
  }
  else{
    setTimeout(() => {
      return toast.update(id,{render : "You can't unStake within Lockperiod",isLoading : false ,autoClose :1000 })
        
      }, 1000);  }
}
      }
      
      if(hash && hash.status){
        let sen_dta={
          NFTId : item.NFTId,
          Owneradd : item.NFTOwner,
          isStake  : Boolean(item.isStake),
          date:Date.now()
        }
        console.log("senddata",sen_dta)
        
       var resp = await Enablestake(sen_dta)
       if(resp.success){
        toast.update(id, {
          render: "Staked successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000, closeButton: true, closeOnClick: true
      });
        dispatch({
          type : "Stake_Section",
          Stake_Section : {
            isStake : item.isStake
          }
        })
        closePop()
       }
       else{
        toast.update(id, {
          render: "Try Again..",
          type: "error",
          isLoading: false,
          autoClose: 1000, closeButton: true, closeOnClick: true
      });
       }
      }
      else{
        toast.update(id, {
          render: "Try Again..",
          type: "error",
          isLoading: false,
          autoClose: 1000, closeButton: true, closeOnClick: true
      });
      }
    }

    console.log("STAKESTAE",claim,stake);
    const rewardclaim=async()=>{
      var  hash;
      var id = toast.loading("Claim on process")
      console.log("stakeupdate", new Date().getTime(),new Date(item.isStakeupdate).getTime(),(new Date().getTime() -new Date(item.isStakeupdate).getTime()),item.isStakeupdate,Number(Pooldata.intervaltime)*24,Pooldata.intervaltime)
     var staketime= new Date(item.isClaimupdate).getHours()
let stakemont = new Date(item.isClaimupdate).getMonth()
let stakedate = new Date(item.isClaimupdate).getDate()
let intervel= Pooldata.intervaltime.toFixed(2)
let hourortime = Number(Pooldata.intervaltime.toFixed(2))*24 <= 1 ? "hour" : "day"
     console.log("stakedate",staketime,stakedate,hourortime,stakemont);


if(hourortime == "hour"){
 if(staketime == new Date().getHours()){
  if(stakedate < new Date().getDate()){
    hash= await ContractCall.ClaimReward(item.NFTOwner,item.NFTId)      
  }
  else{
    setTimeout(() => {
      return  toast.update(id,{render : "You can't claim within claim period",isLoading : false,autoClose : 1000})
          
        }, 1000);  }
 }else{
  console.log("crtstakeclaim",(Number(new Date().getHours()) - Number(staketime) ),Math.round(Number(Pooldata.intervaltime)*24));
  if((Number(new Date().getHours()) - Number(staketime) ) >= Math.round(Number(Pooldata.intervaltime)*24)){
     hash= await ContractCall.ClaimReward(item.NFTOwner,item.NFTId)
            
   }
   else{
    setTimeout(() => {
      return  toast.update(id,{render : "You can't claim within claim period",isLoading : false,autoClose : 1000})
          
        }, 1000);   }
 }
}

if(hourortime == "day"){
  if(stakedate == new Date().getDate() ){
    if(stakemont < new Date().getMonth()){
        hash= await ContractCall.ClaimReward(item.NFTOwner,item.NFTId)
    }
    else{
      setTimeout(() => {
    return  toast.update(id,{render : "You can't claim within claim period",isLoading : false,autoClose : 1000})
        
      }, 1000);
    }
  }else{
    if(stakedate < new Date().getDate()){
        hash= await ContractCall.ClaimReward(item.NFTOwner,item.NFTId)
     }
     else{
      setTimeout(() => {
        return  toast.update(id,{render : "You can't claim within claim period",isLoading : false,autoClose : 1000})
            
          }, 1000);     }
  }
}

if(hash && hash.status){
  let sen_dta={
    NFTId : item.NFTId,
    Owneradd : item.NFTOwner,
    claim : true,
    date:Date.now()
  }
  console.log("senddata",sen_dta)
  
  var resp = await Enablestake(sen_dta)
  if(resp.success){
    setTimeout(() => {
      toast.update(id, {
        render: "Claimed successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000, closeButton: true, closeOnClick: true
    });
    }, 1000);
   
    dispatch({
      type : "Stake_Section",
      Stake_Section : {
        isStake : item.isStake
      }
    })
    closePop()
   }
   else{
    setTimeout(() => {
      toast.update(id, {
        render: "Try Again..",
        type: "error",
        isLoading: false,
        autoClose: 1000, closeButton: true, closeOnClick: true
    });
    }, 1000);
    
   }
}
else{
  setTimeout(() => {
    toast.update(id, {
      render: "Try Again..",
      type: "error",
      isLoading: false,
      autoClose: 1000, closeButton: true, closeOnClick: true
  });
  }, 1000);
}


//        if((new Date(item.isStakeupdate).getTime() - new Date().getTime() ) ==   Number(Pooldata.intervaltime)*24  ){
//         var  hash= await ContractCall.ClaimReward(item.NFTOwner,item.NFTId)
//         if(hash && hash.status){
//            toast.success("Your reward claimed successfully")
//         }
//         else{
//          toast.error("Something error please try again")
//         }
//        }else{
// toast.warning("You can't claim within claim period")
//        }
    



    }
    return(
        <>
         <Modal show={stakemodal} 
         className='stakess add-nft-inner'
         size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
         <Modal.Header>
        <button type="button" class="btn-close" aria-label="Close" onClick={closePop}></button>     
       </Modal.Header>
        <Modal.Body>
          <div className='stakecard'>
            <div>
              <img src={`${config.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT/${item?.CompressedFile}`} alt='image'/>
            </div>
          </div>
          <h6 className='text-left mt-3'>{item.NFTName}</h6>
          <div className='d-flex text-left  mt-3'>
          <div>
              <p className='reward'>Stake Lock Period</p>
              <p>{Pooldata.lockperiod} <span className='coinclr'>{Pooldata.lockperiod == 1  ? "Day" : "Days"}</span></p>
            </div>
            <div className='ml-3'>
              <p className='reward'>Claim Period</p>
              <p>{Pooldata.intervaltime < 1 ? `${Math.round(Pooldata.intervaltime * 24)} Hour` : `${Pooldata.intervaltime} Days`} </p>
            </div>
            <div className='ml-3'>
              <p className='reward'>Reward Percentage</p>
              <p>{Number(Pooldata.rewardPer)} % <span className='coinclr'></span></p>
            </div>
            {/* {item.isStake &&  */}
            <div className='ml-5'>
              <p className='reward'>Rewards </p>
              <p>{item.Reward} {"JFUT"}</p>
            </div>
            {/* // } */}
          </div>
          <div class="bottom-button pr-0 mt-4">
            {item?.isStake && <button type="button" class="tf-button active discard btn btn-primary" disabled={!claim} onClick={()=>rewardclaim()}>Claim</button>
}
            <button type="button"  onClick={()=>enablestake()} disabled={!item?.isStake ? item?.isStake : !stake } class="tf-button active btn btn-primary">{item.isStake ? "UnStake" : "Stake"}</button>
            </div>
        </Modal.Body>
      
      </Modal>
        </>
    )
}

// export default Stakemodal;