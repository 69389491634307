import { useState } from "react";

export default function   ImgAudVideo({
  file,
  type,
  classname,
  thumb,
  origFile,
  from,
  noimg,
}) {
  // console.log("fileeeeeee",file,type)
  var [Check,setCheck] = useState(false)
  const Audioaction =()=>{
    var aud = document.getElementById("nftaudio");
    if(Check==false){
      aud.play();
      setCheck(!Check)
    }
    else{
      aud.pause();
      setCheck(!Check)
    }
  }
  return file ? (
    type === "image" ? (
      <img
        src={file}
        alt="img"
        className={classname}
        onContextMenu="return false;"
        onError={event => {
          event.target.src = origFile
        }}
      />
    ) : type === "video" ? (
      <video
        loop={true}
        controlsList="nodownload"
        muted
        autoPlay={true}
        controls
        poster={thumb}
        onContextMenu="return false;"
        type="video/*"
        src={file}
        onError={event => {
          event.target.src = origFile
        }}
      >
      </video>
    ) : type === "audio" ? (
      <>
        {" "}
        <img src={thumb} alt="favicon" onClick={Audioaction} className={classname} />
        <audio
          controlsList="nodownload"
          id="nftaudio"
          controls
          // autoPlay
          // loop
          // muted
          src={file}
        >
          {/* <source  type="audio/*" /> */}
        </audio>
      </>
    ) : (
      <img src={noimg} alt="audio" className={classname} />
    )
  ) : (
    <img src={noimg} alt="noimg" className={classname} />
  );
}
