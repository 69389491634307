import React ,{useState , useEffect , useRef} from "react";
import Card from "./Card.js";
import { NavLink , useParams } from "react-router-dom";
import Header from "../separate/Header1.js";
import Footer from "../separate/Header1.js";
import { useSelector } from "react-redux";
import {userRegister , Token_MyList_Func ,FollowUnFollowFunc } from '../actions/axioss/user.axios'
import action_config from './config/config';
import { toast } from 'react-toastify'
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { address_showing } from "../../src/actions/common";
import NoData from "./seperatemodals/nodata";

export default function MyItem(){
    const [category,setCategory] = React.useState('owned');
    const [show,setShow] = React.useState(false);
    const [cursor,setcursor] = React.useState('');
    var { customurl } = useParams();
    const [OpenPopup, SetOpenPopup] = useState('')
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);
    const [loading,setLoading]=useState(true)
    const [value, SetTabName] = React.useState('owned');
    // const { push    }   =   useHistory()
    const { payload,isAdmin } = useSelector(state => state.LoginReducer.User)
    // const AxiosFile = useAxiosFile()
    const [userProfile, setUserProfile] = useState({})
    const [Tokens, SetTokens] = useState({ 'owned': { 'loader': true, page: 1, list: [] } })
    const [Follow, SetFollow] = useState('unfollow');
    var [filter,setFilter] = useState("LatestDrops");
    var [filtershow,setFiltershow] = useState("Recently Created")
    const { web3p, accountAddress } = useSelector(
        (state) => state.LoginReducer.AccountDetails
      );
        // console.log('paramss',customurl)

        useEffect(() => {
            if (typeof Tokens[value] == 'undefined' || Tokens[value].filter !== filter) {
                Tokens[value] = { page: 1, list: [], loader: false };
                SetTokens(Tokens);
                Explore(1, value);
            }
           
        }, [value,filter])
        
        useEffect(() => {
            // window.location.reload()
            // if (typeof Tokens[value] == 'undefined' || Tokens[value].filter !== filter) {
            //     Tokens[value] = { page: 1, list: [], loader: false };
                SetTokens({ 'owned': { 'loader': true, page: 1, list: [] } });
                Explore(1, value);
            // }
        }, [ userProfile?.WalletAddress])

        useEffect(()=>{
            getProfileDetails()
        },[customurl])
    
        const getProfileDetails = async () => {
            var SendDATA = {
                CustomUrl: customurl,
                Type: 'getProfile'
            }
            var profileInfo = await userRegister(SendDATA)
            if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
                setUserProfile(profileInfo.data)
            }
        }
    
        const Explore = async (data, tab) => {
            console.log("dkjfkdfdf")
            setLoading(false)
            var page = data ? data : (Tokens[value]?.page)
            var SendDATA = {
                TabName: tab ? tab : value,
                limit: (tab === "usercollection" || value === "usercollection") ? 100 : 12,
                CustomUrl: userProfile?.customurl,
                NFTOwner: (userProfile?.WalletAddress),
                page: page ?? 1,
                filter: filter,
                from: 'myItem',
                cursor:cursor
            }
            
            let Resp = await Token_MyList_Func(SendDATA)
            console.log('sfgdfdfdfhgfs',value,Resp,page,SendDATA)
            if (Resp?.success == 'success' && Resp.data.length > 0) {
                setcursor(Resp?.cursor)
                console.log("TOKENSCXXC",Resp?.data)
                SetTokens({
                    ...Tokens, ...{
                        [value]: {
                            list: SendDATA.page ==1 ? Resp.data : [...Tokens[value].list, ...Resp.data],
                            loader: ( Resp.data.length ==0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
                            page: Tokens[value].page,
                            filter: filter,
                        }
                    }
                })
                setLoading(true)
            } else{
                console.log('TOKENSCXXCelsee',Resp?.data)
            SetTokens({
                ...Tokens,
                ...{
                  [value]: {
                    list: SendDATA?.page == 1 ? [] : Tokens[value].list,
                    loader: false,
                    page: Tokens[value].page,
                    filter: filter
                  },
                },
              });
              setLoading(true)
            }
        }

        console.log("DDDFFDFSDFd",Tokens);
        const LoadMore = () => {
            setLoading(false)
            Tokens[value].page = Tokens[value].page + 1;
            SetTokens(Tokens);
            Explore(Tokens[value].page);
    
        }
        const FollowUnFollow = async () => {
            const id = toast.loading(Follow == "follow" ? "Your Following " + userProfile?.DisplayName : "Your UnFollowing " + userProfile?.DisplayName)
            let SendData = {
                MyItemAddr: userProfile?.WalletAddress,
                ClickAddr: accountAddress,
                From: 'myitem',
                MyItemCustomUrl: userProfile?.CustomUrl,
                ClickCustomUrl: payload?.CustomUrl,
            }
            // console.log('senddataaa',SendData,userProfile,payload)
            let Resp = await FollowUnFollowFunc(SendData)
            if (Resp?.success) {
                SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
                toast.update(id, { render: Resp.msg == 'follow' ? 'Your Following ' + userProfile?.DisplayName + ' Successfully' : 'Your UnFollowing ' + userProfile?.DisplayName + ' Successfully', type: 'success', isLoading: false, autoClose: 1000 })
            }
            else {
                toast.update(id, { render: 'Try Again', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
    
            }
        }

    function LikeList(data){
        setLikedTokenList(data)
    }

    // useEffect(() => {
    //   if(filter !== Tokens[value].filter){
    //     Explore();
    //   }  
    //   },[filter]);
      
    return(
        <>
        {/* <Header /> */}
        <div className="collection">
                          
            <section className="tf-collection-page tf-filter">
                {/* <div className="tf-container"> */}
                    <div className="row ">
                        <div className="col-md-12">
                        <div className="top-menu row"  data-aos="fade-up">
                                <ul className="filter-menu">
                                    <li className={value == "owned" ? "active" : ""} onClick={() => SetTabName("owned")}><a>Owned</a></li>
                                    <li className={value == "onsale" ? "active" : ""} onClick={() => SetTabName("onsale")}><a>On sale</a></li>
                                    {/* <li className={value == "liked" ? "active" : ""} onClick={() => SetTabName("liked")}><a>Liked</a></li> */}
                                    <li className={value == "gold" ? "active" : ""} onClick={() => SetTabName("gold")}><a>Gold</a></li>

                                    <li className={value == "silver" ? "active" : ""} onClick={() => SetTabName("silver")}><a>Silver</a></li>
                                    <li className={value == "bronze" ? "active" : ""} onClick={() => SetTabName("bronze")}><a>Bronze</a></li>

                                    <li className={value == "diamond" ? "active" : ""} onClick={() => SetTabName("diamond")}><a>Diamond</a></li>

                                    <li className={value == "ruby" ? "active" : ""} onClick={() => SetTabName("ruby")}><a>Ruby</a></li>


                                 {/* {accountAddress == userProfile.WalletAddress &&   <li className={value == "usercollection" ? "active" : ""} onClick={() => SetTabName("usercollection")}><a>User Collections</a></li>} */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row"  
                    // data-aos="fade-up"
                    >
                        { !loading  ? 
                        
                        <>
                        <div className="text-centre">
                            <h3>Loading...</h3>
                            </div>
                        </>
                        :
                        
                        
                        Tokens[value] &&
                        Tokens[value]?.list?.length>0 ? 
                        Tokens[value].list.map((val,index) => 
                        
                        <div
        className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
        // data-aos="zoom-in-up"
      >
        {console.log("carddata",val)}
                        <Card 
                        from    = { value == 'usercollection' ?  'usercollection' :'marketplace'}
                        product={ value == 'usercollection' ? 
                        {
                            NFTName : val.name,
                            Category : val.tokenowners_list.Category,
                            CollectionNetwork   :  action_config.COIN_NAME ,
                            ContractAddress :   val.colladdress,
                            NFTOwner    :   userProfile.WalletAddress,
                            NFTId   :   val.tokenCounts,
                            Image   :   val.image,
                            OwnerName : userProfile.DisplayName ,
                            OwnerProfile : userProfile.Profile ,
                            type : val.type,
                            amount : val.amount,
                            ipfshash:val.ipfshash,
                            meta:val.meta,
                            metadata:val.metadata,
                            Creator_CustomUrl:payload?.CustomUrl,
                            
                        }
                        :val}
                        type="marketplace"
                        index={index}
                        LikeList={LikeList}
                        LikedTokenList={LikedTokenList}
                        setLikedTokenList={setLikedTokenList}
                        LikeForwardRef={LikeForwardRef}/>
                        </div>
                        ):<NoData/>}
                       
                    </div>
                   {Tokens[value]?.loader&& <div className="col-md-12">
                        <div className="btn-loadmore mt6">
                            <Link to="#" className="tf-button loadmore" onClick={LoadMore}>Load More</Link>
                        </div>
                    </div>
                    }
                {/* </div> */}
            </section>
        </div>
        {/* <Footer />     */}
        </>
    )
}