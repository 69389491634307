import React,{useEffect,useState} from "react";
import { NavLink,Link } from "react-router-dom";
import Author from "../assets/images/product52.jpg";
import Footer from "../separate/Footer1";
import Header from "../separate/Header1";
import config from './config/config'

import {  CollectionByCreator  } from "../actions/axioss/nft.axios"
import { data } from "jquery";

export default function CollectionList(){
    const [collection,setcollection]=useState([])
    const [SaleData, SetSaleData] = useState({});
    const collecti = ([
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
        {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"}]
    )
    useEffect(()=>{
        CollectionByCreate("All");
    },[])
    const CollectionByCreate = async(data) =>  {
        var SendDATA = {
            tab: data,
            limit: 4,
            ProfileUrl:  "",
            page: 1,
            from: "home",
          };
        let Resp = await CollectionByCreator(SendDATA)
        setcollection(Resp?.data)
    }
    return(
        <>
        <Header />
          <div className="collectionlist">
          <section className="tf-page-title" data-aos="fade-right">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="breadcrumbs">
                                <li><NavLink to ="/">Home</NavLink></li>
                                <li>Collection List</li>
                            </ul>
                            {/* <h4 className="page-title-heading">Explore</h4> */}
                        </div>
                    </div>
                </div>                    
            </section>  
            <section className="tf-container">
            {/* <ul className="filter-menu">
                                    <li className="active">3D MODEL</li>
                                    <li>ANIME/MANGA</li>
                                    <li>CYBER PUNK</li>
                                    <li>PIXEL ART</li>
                                    <li>MUSIC</li>
                                    <li>ABSTRACT</li>
                                    <li>2D ARTS</li>
                                </ul> */}
                                <div className="row mt-5">
                                {collection.length>0 && collection?.map((data,index) => {
                                        return ( 
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div className="swiper-slide">                               
                                        <div className="slider-item">
                                            <div className="sc-product style1 collection collection2" >
                                                <div className="top">
                                                    <div className="content">
                                                    <Link to ={"/collection/"+data.CollectionSymbol} >
                                                        <div className="author-cl">
                                                        <img src={data.CollectionProfileImage ? `${config.IMG_URL}/collection/${data.CollectionSymbol}/${data.CollectionProfileImage}` : require("../assets/images/add-collection.jpg")} alt="images" />
                                                        </div>
                                                        </Link>
                                                        <div className="inner">
                                                            <NavLink to ="/" className="name">{data.title}</NavLink>
                                                            <div className="create">created by <NavLink to={"/profile/"+data?.CustomUrl}>{data.DisplayName}</NavLink></div>
                                                        </div>
                                                    </div>
                                                    <div className="wish-list">
                                                        <a href="#" className="heart-icon"></a>
                                                    </div>
                                                </div>
                                                <a href="#">
                                                    <div className="thumb-collection">
                                                        <div className="left-thumb">
                                                        <Link to ={data?.Tokens[0]?.Link}><img src={`${config.IMG_URL}/nft/${data?.CollectionCreator}/Compressed/NFT/${data?.Tokens[0]?.CompressedFile}`} alt="images" /></Link>
                                                        </div>
                                                        <div className="right-thumb">
                                                            <div className="top-cl">
                                                            <Link to ={data?.Tokens[1]?.Link}><img src={`${config.IMG_URL}/nft/${data?.CollectionCreator}/Compressed/NFT/${data?.Tokens[1]?.CompressedFile}`} alt="images" /></Link>
                                                            </div>
                                                            <div className="bottom-cl">
                                                            <Link to ={data?.Tokens[2]?.Link}><img src={`${config.IMG_URL}/nft/${data?.CollectionCreator}/Compressed/NFT/${data?.Tokens[2]?.CompressedFile}`} alt="images" /></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    )})}
                                </div>
                                <div class="col-md-12">
                                <div class="btn-loadmore mt17 mb-5">
                                    <a href="#" class="tf-button loadmore">Load More</a>
                                </div>
                            </div>
            </section>
          </div>
        <Footer />  
        </>
    )
}