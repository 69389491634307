import { useMemo, useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import Web3 from 'web3'
// import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import useContractProviderHook from "./../../actions/contractProviderHook";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "../../actions/common";
// import useAxiosFile from "../../actions/useaxios";
import config from '../config/config'
import { useEffect } from "react";
import { BuyAccept,buyipfsandupdate,Buymint } from "../../actions/axioss/nft.axios";
import { error } from "jquery";
import randomInteger from 'random-int';
export function BuyNow({ OpenPopup, closePop, owner, item }) {
   const navigate=useNavigate();
   // console.log('buyyyyyyyy',item,owner);
   const { currency } = useSelector(state => state.LoginReducer)
   const { web3, web3p, accountAddress, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
   // console.log('coinnnnballl',coinBalance,web3p.utils.fromWei(coinBalance.toString()))
   const { buyerFees,sellerFees } = useSelector(state => state.LoginReducer.ServiceFees);
   //console.log('getServiceFees',buyerFees,sellerFees)
   const ContractCall = useContractProviderHook()
   const  push = useNavigate()
   // const AxiosFile = useAxiosFile()
   const { payload } = useSelector(state => state.LoginReducer.User)
   console.log("payloaitemd",item?.hashvalue,item);

   const [Btn, SetBtn] = useState('start')
   const [App_Btn, SetApp_Btn] = useState(owner.CoinName != "BNB" ? 'init' : "start")
   const [Error, SetError] = useState('')
   const [NFTQuantity, SetNFTQuantity] = useState(1)
   const [TokenBalance, SetTokenBalance] = useState('0')
   const [SignatureHash,setSignatureHash]=useState()
   const[show10,setShow10]=useState(false);
   const handleClose10 = () => closePop();
   const [proceedtopayment, setProceedtopayment] = useState(false);
   const decimal = currency?.filter(item => item.label === owner.CoinName)?.pop()?.decimal ?? 18
   // console.log('aaaaaaabbbb',currency,currency?.filter(item => item.label === owner.CoinName))
   const token_address = currency?.filter(item => item.label === owner.CoinName)?.pop()?.address ?? config.DEADADDRESS
   const YouWillGet = useMemo(() => { return ContractCall.buy_bid_price_calculation((owner.NFTPrice * NFTQuantity).toString(), decimal.toString()) }, [owner.TokenPrice, NFTQuantity])
   const Validation = async () => {
      var error = {};
      if (isEmpty(NFTQuantity)) return "Token Quantity Required"
      else if(owner.NFTBalance<NFTQuantity) return error.NFTQuantity= "NFT Quantity should be less than "+owner.NFTBalance
      if (owner.CoinName != "BNB" && ((owner.TokenPrice * NFTQuantity) > TokenBalance)) return "Insufficient Balance"
      else return await ContractCall.Contract_Base_Validation()
   }

   const FormSubmit = async () => {
      const id = toast.loading('Purchasing Token on processing')
      SetError('')
      SetBtn('process')
      var error = await Validation()
      console.log("after",error)
      if (error) {
         toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
         SetBtn('error')
         SetError(error)
      }
      else {
console.log("vaada",currency?.filter(item => item.label === owner.CoinName)?.pop()?.address)
         let cont = await ContractCall.approve_721_1155(token_address, config.TradeContract, web3p.utils.toWei(YouWillGet.toString()))
         console.log("cont",cont)
         if (cont) {
            toast.update(id, { render: 'Approve For Token Successfully', type: 'success', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
            SetBtn('done')
            SetApp_Btn('start')
         }
         else {
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
            SetBtn('try')
         }

      }
   }

   useEffect(() => {
      (async () => {
         const TokenBalance = await ContractCall.Token_Balance_Calculation(token_address, accountAddress)
         SetTokenBalance(TokenBalance ? TokenBalance : 0)
      })()
   }, [TokenBalance])

   const _signcall =async()=>{
      try{
      
                      if (web3) {
                        var generator = require('generate-password');
                        const passwords = require('secure-random-password');
                        var TokenPrice=owner.NFTPrice
                        var randomNum = 
                        randomInteger(10000000, 100000000);
                        console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
                        var password;
                        try{
                           console.log('kshskjhfsjkfs',passwords)
                           password = passwords.randomPassword({ length: 10, characters: [passwords.lower, passwords.upper, passwords.digits]  })
                           ;}
                        catch(err){
                           console.log('lkfadjlfjd',err)
                        }
                        console.log("Password", password);
                        if (web3) {
                          // console.log("checking window.ethereum", web3.givenProvider,Wallet_Details.providerss)
                          var web3RpcPro = new Web3(web3.providers);
                          console.log("asddfsfsdvbvbvcb", web3RpcPro, payload.WalletAddress);
                          const to =  payload.WalletAddress
                          const _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3.utils.toWei(String(TokenPrice));
                          console.log("ajhghjas", _amount);
                          const _nonce = Date.now();
                          console.log("qwewqewqreqwrqrq", password);
                  
                        //   setRandomName(password)
                          var tot = _nonce + Number(randomNum);
                        //   setNonceHash(tot);
                          console.log("signparametes", to, _amount, password, tot);
                          const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
                          console.log("signresult", result);
                          const signhash = await web3.eth.personal.sign(result, to);
                          console.log("Signature", result, signhash)
                          if (signhash) {
                            setSignatureHash(signhash);
                            return ({hash : signhash,
                              result : result,
                              randomname:password,
                              nonce : tot

                            })
                          }
                        }
                     }
                  }
                  catch(err){
                     console.log('signer',err)
                  }
   }


   const ipfshash=async(hash)=>{
      var send_data={
        NFTId : item.NFTId,
      }
var Resp = await buyipfsandupdate(send_data)
if(Resp.success) return Resp
return ({success : false})
   }

   const mint=async()=>{
      const id = toast.loading('Purchasing Token on processing')

      try{
         
        
         var ipfsadd =await ipfshash()
        
        var hash= await ContractCall.lazyminting_721_1155(
              owner.CoinName,
             web3.utils.toWei(YouWillGet.toString()),
            [config.IPFS + String(ipfsadd.MetaData), "Status", "lazy", item.randomname],
            [item.NFTCreator, accountAddress,config.ERC721],
            [
                (NFTQuantity).toString(),
                item.ContractType,
                web3?.utils.toWei(item.NFTRoyalty),
                web3.utils.toWei(
                    (owner.PutOnSaleType == "FixedPrice"
                        ? (owner.NFTPrice * NFTQuantity)
                        : "0"
                    ).toString()
                ),
                NFTQuantity,
                item.nonce,
                web3.utils.toWei(
                    (owner.PutOnSaleType == "FixedPrice"
                        ? owner.NFTPrice
                        : "0"
                    ).toString()
                ),
            ],
             owner.CoinName == "BNB" || owner.CoinName == "ETH" ? config.BUYCOINNAME : owner.CoinName,
            item.hash
        );
        console.log("MINTHASH",hash)

      let newOwner = {
         HashValue: hash.HashValue,
         NewTokenOwner: accountAddress,
         NewTokenId : hash.Tokenid,
         NFTQuantity: NFTQuantity,
         NFTId: owner.NFTId,
         NFTOwner: owner.NFTOwner,
         PutOnSale: owner.PutOnSale,
         PutOnSaleType: owner.PutOnSaleType,
         activity: "Buy",
         TP: owner.NFTPrice,
         CN: owner.CoinName,
         click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`

      }
      let Resp = await Buymint({ newOwner: newOwner, item: item })
      if (Resp.success == 'success') {
         toast.update(id, { render: 'Token Purchased Successfully', type: 'success', isLoading: false, autoClose: 1000 ,closeButton:true,closeOnClick:true})
         SetApp_Btn('done')
         navigate('/profile/'+payload.CustomUrl,{state:{Tab:'owned'}});
      }
      else {
         toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
         SetApp_Btn('try')
      }

      }
         
         catch(err){
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
         SetApp_Btn('try')
            console.error(err)
         }
      }


   const _Buy = async () => {
      SetApp_Btn('process')
      console.log('ghgdhdg')
      const id = toast.loading('Purchasing Token on processing')
      var error = await Validation();
      // console.log('errrrrrrrrr',error,NFTQuantity);
      SetError(error)
      if(isEmpty(error)){
      let cont = await ContractCall.buy_721_1155(
         web3p.utils.toWei(YouWillGet.toString()),
          owner.CoinName,
           owner.NFTOwner,
            [owner.NFTId,
                web3p.utils.toWei(String(owner.NFTPrice * NFTQuantity)),
                 
                ,
                  item.ContractType
               ]
               ,
                item.ContractAddress)
      if (cont) {
         let newOwner = {
            HashValue: cont.HashValue,
            NewTokenOwner: accountAddress,
            NFTQuantity: NFTQuantity,
            NFTId: owner.NFTId,
            NFTOwner: owner.NFTOwner,
            PutOnSale: owner.PutOnSale,
            PutOnSaleType: owner.PutOnSaleType,
            activity: "Buy",
            TP: owner.NFTPrice,
            CN: owner.CoinName,
            click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`

         }
         let Resp = await BuyAccept({ newOwner: newOwner, item: item })
         if (Resp.success == 'success') {
            toast.update(id, { render: 'Approving Token Successfully', type: 'success', isLoading: false, autoClose: 1000 ,closeButton:true,closeOnClick:true})
            SetApp_Btn('done')
            navigate('/profile/'+payload.CustomUrl,{state:{Tab:'owned'}});
         }
         else {
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
            SetApp_Btn('try')
         }
      }
      else {
         toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
         SetApp_Btn('try')
      }
      }
      else{
         toast.update(id, { render: 'Validation failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
      }
   }

   const onChange = (e) =>{
      // console.log('vallll',e.target.value)
      SetNFTQuantity(e.target.value)
   }

   // useEffect(()=>{
   //    async function BalanceCheck(){
   //      var Nftbalance = await ContractCall.Current_NFT_Balance(owner,item)
   //      console.log('ownneerrr ittemmm',owner,item,Nftbalance);
   //      if(Nftbalance !== owner.NFTBalance){
   //        toast.warning("You won't buy at this moment please refresh you data");
   //        setTimeout(() => {
   //          push("/")
   //        }, 1000);
   //      }
   //    }
   //    BalanceCheck();
   //  },[item,owner])

   return <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className={proceedtopayment ? "fixedmodel" : ""}
   >
      {/* {console.log('fgfdgfdhgfhb',NFTQuantity)} */}
      <Modal.Header>
         <button type="button" class="btn-close" aria-label="Close" onClick={closePop}></button>
         <Modal.Title id="contained-modal-title-vcenter">
            Checkout
         </Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <div className='buynowsec'>
            <div className='buynowseller d-flex justify-content-between'>
               <p className="receives">Seller :</p><p className="receives">{owner.DisplayName ? owner.DisplayName : owner.NFTOwner}</p>
            </div>
            <div className='buynowseller d-flex justify-content-between'>
               <p className="receives">Buyer:</p><p className="receives">{payload?.DisplayName ? payload?.DisplayName : payload.WalletAddress}</p>
            </div>
         </div>
         <div className='buynowbalance mt-5'>
            <div className='buynowseller  d-flex justify-content-between'>
               <p className="receives">Your Balance :</p><p className="receives">{ owner.CoinName != "BNB" ? TokenBalance : web3p.utils.fromWei(coinBalance.toString()) } {owner.CoinName == "BNB" ? "BNB" : owner.CoinName}</p></div>
            {/* <div className='buynowseller d-flex justify-content-between'>
               <p>Your Token Balance:</p><p>99999.8 T CAKE</p></div> */}
            <div className='buynowseller  d-flex justify-content-between'>
               <p className="receives">Price :</p><p className="receives">{owner.NFTPrice} {owner.CoinName == "BNB" ? "BNB" : owner.CoinName}</p></div>
            <div className='buynowseller  d-flex justify-content-between'>
               <p className="receives">Service Fee :</p><p className="receives">{web3p.utils.fromWei(String(buyerFees))}% {owner.CoinName == "BNB" ? "BNB" : owner.CoinName}</p></div> {/*{web3p.utils.fromWei(String(buyerFees))}*/ }
            <div className='buynowseller d-flex justify-content-between'>
               <p className="receives">Royalty Fee :</p><p className="receives">{(item.NFTRoyalty)}% {owner.CoinName == "BNB" ? "BNB" : owner.CoinName}</p></div>
            <div className='buynowseller d-flex justify-content-between'>
               <p className="receives">You Will Pay :</p><p className="receives">{YouWillGet} {owner.CoinName == "BNB" ? "BNB" : owner.CoinName}</p></div>
               <p className="text-left pb-2 mt-4 receives">Enter Quantity </p>
        <input
          type="text"
          id="NFTQuantity"
          value={NFTQuantity}
          onChange={(e) => SetNFTQuantity(Number(e.target.value))}
          name="NumOnly"
          className=""
          disabled={owner.NFTBalance == '1' || owner.NFTBalance == 1 ? true : false}
          placeholder="Enter your bid quantity"
        />
        {Error && ( <span className="text-danger img-file">{Error}</span>)}
         </div>
         <div className='connecwalletbtns d-flex justify-content-between mt-5'>
         {owner.CoinName == "BNB"  || owner.CoinName == "ETH" ?null:
         <Button className="connectwallet primary" tabIndex="-1" 
         disabled    =   {Btn == 'error' || Btn === "process"  ||  Btn ==="done" ? true : false} 
         onClick     =   {Btn == 'start' || Btn === "try" ? FormSubmit : null}>{Btn == 'start' && 'Approve' 
         ||Btn == 'try' && 'Try-Again'
         ||Btn == 'error' && 'Error' 
         ||Btn == 'done' && 'Done' 
         ||Btn == 'process' && 'In-Progress' 
          }</Button>
         }
         <Button className={ Btn ==="done" ? "cancelpayment secondary" : "connectwallet hides primary" } 
         tabIndex="-1" 
         disabled    =   {Btn!='done' && App_Btn=='init'|| App_Btn == 'error' || App_Btn === "process"  ||  App_Btn ==="done" ? true : false} 
         onClick     =   {(App_Btn == 'start' || App_Btn === "try") ?  item?.hashvalue == "not minted" ? mint :  _Buy  : null}>{App_Btn == 'start' && 'Proceed to payment' 
         ||App_Btn == 'try' && 'Try-Again'
         ||App_Btn == 'error' && 'Error' 
         ||App_Btn == 'done' && 'Done' 
         ||App_Btn == 'process' && 'In-Progress' 
         ||App_Btn == 'init' && 'Proceed to payment' 
          }</Button>
            {/* <button className={proceedtopayment ? "connectwallet primary" : "connectwallet hides primary"} tabIndex="-1" aria-disabled="true" onClick={() => setProceedtopayment(true)}>Proceed</button>
            <button className='cancelpayment secondary' onClick={handleClose10}>Cancel</button> */}
         </div>
      </Modal.Body>
   </Modal>
}