// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'
import users from "../../assets/images/author-detail-3.png"
var EnvName = 'production';
var key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL           =   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ 
key.MOBILE          =   /^\d{10}$/
key.NumOnly         =   /^\d+$/
key.PASSWORD        =   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets   =   /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil       =   /^[a-zA-Z0-9]+$/
key.OnlyAlphSpecial =   /^[A-Za-z_@.#&+-]*$/
key.IPFS            =   'https://joyfunfi.infura-ipfs.io/ipfs/'
key.DecimalAlloweddigits = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
key.ABI=EnvName
key.profile = users
key.BUYCOINNAME     = "Coin"
key.DEX = 'https://joyfun-mainnet.pages.dev/'

if(EnvName === "demo") {
    key.FRONT_URL       =   'http://nftdemo.bimaticz.com/joyfun'
    key.BACK_URL        =   'http://192.53.121.26:8903/v1/front'
    key.ADMIN_URL        =   'http://192.53.121.26:8903/v1/admin'
    key.IMG_URL         =   'http://192.53.121.26:8903'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract   =   '0x5b6AaF50abD17EA1faE897c487B71CDf75d90769'.toLowerCase()
    key.ERC721          =   '0x8CF4806220836DaA2159857a7e4c983D131484F1'.toLowerCase()
    key.ERC1155         =    '0xcdA4344aB3fF13d82eC06b5aF2B0BED761833168'.toLowerCase()
    key.erc20Address    =   '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    key.StakeAddress    =   "0x1B13AF008b0d968F27cb383e4f21e79a25554dF4".toLowerCase()
    key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    key.CHAIN_ID        =   43113
    key.COIN_NAME        =   "BNB"
    key.poolid   = 5
    key.ADMINADDRESS    = "0xA19C37D354213bF8C8AF9a48f86fA6D1689A584C".toLocaleLowerCase()
}

else if(EnvName === "production") { 
    key.FRONT_URL       =   'https://nft.joyfunfi.com'
    key.BACK_URL        =   'https://api.joyfunfi.com/v1/front'
    key.ADMIN_URL        =   'https://api.joyfunfi.com/v1/admin'
    key.IMG_URL         =   'https://api.joyfunfi.com'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract   =   '0xFec71dBB8AB3A0e13E0060A45911FA6Ade7cC44f'.toLowerCase()
    key.ERC721          =   '0xBF48a58C5b0e9f36BB1125e67db1364110EeA31d'.toLowerCase()
    key.erc20Address    =   '0xa142b84718368eFd9F656A5782CD47FC33B0b985'.toLowerCase()
    key.StakeAddress    =   "0x906373D8e6C872ABc9C8c1f43fE721b77a9E850A".toLowerCase()
    key.RPC_URL         =   "https://bsc-dataseed1.binance.org/"
    key.CHAIN_ID        =   56
    key.poolid   = 1
    key.BUYCOINNAME     = "Coin"
    key.COIN_NAME        =   "BNB"
}

else{
    key.FRONT_URL       =   'http://localhost:3800/Ditmax'
    key.BACK_URL        =   'http://localhost:3800/v1/front'
    key.ADMIN_URL        =   'http://localhost:3800/v1/admin'
    key.IMG_URL         =   'http://localhost:3800'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract   =   '0x5b6AaF50abD17EA1faE897c487B71CDf75d90769'.toLowerCase()
    key.ERC721          =   '0x8CF4806220836DaA2159857a7e4c983D131484F1'.toLowerCase()
    key.ERC1155         =    '0xcdA4344aB3fF13d82eC06b5aF2B0BED761833168'.toLowerCase()
    key.erc20Address    =   '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    key.StakeAddress    =   "0x1B13AF008b0d968F27cb383e4f21e79a25554dF4".toLowerCase()
    key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    key.CHAIN_ID        =   43113
    key.COIN_NAME        =   "BNB"
    key.poolid   = 5
    key.ADMINADDRESS    = "0xA19C37D354213bF8C8AF9a48f86fA6D1689A584C".toLocaleLowerCase()
}

export default key;
