import { Route, Routes, Redirect } from "react-router-dom";
import Home from "./views/Home1";
import Explore from "./views/Explore";
import Stake from "./views/Stakes";
import FAQ from "./views/FAQ1";

import Blog from "./views/Blog";
// import Blogdetail from "./views/Blogdetail";
import Create from "./views/Create2";
import Collection from "./views/Collection";
import ConnectWallet from "./views/ConnectWallet";
import ContactUs from "./views/Contactus";
import Details from "./views/Details1";
import Board from "./views/Board1";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Terms from "./views/Terms";
import MyItem from "./views/my_item1";
import CollectionList from "./views/CollectionList";
import  Search  from "./views/Search";
import  Carduploadpreview  from "./views/carduploadpreview";
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Activity from "./views/Activity";
import List from "./views/List";
import AboutUs from "./views/Aboutus";
import HelpandCentre from "./views/helpandcentre";



function App() {

  console.log = () => { };
  console.debug = () => { };
  console.info = () => { };
  console.warn = () => { };
  return (
    <>   
   
    <BrowserRouter
     basename="/"
     >
      <Routes>
      <Route path='/explore' element={<Explore/>} />
      <Route path='/carduploadpreview' element={<Carduploadpreview/>} />
      <Route path='/explore' element={<Stake/>} />
      <Route path='/explore/:Category' element={<Explore/>} />
        <Route path='/Activity' element={<Activity/>} />
        <Route path='/faq' element={<FAQ/>} />
        <Route path='/blog' element={<Blog/>} />
        {/* <Route path='/blogdetail' element={<Blogdetail/>} /> */}
        <Route path='/create' element={<Create/>} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/collection/:CollectionSymbol' element={<Collection/>} />
        <Route path='/connect' element={<ConnectWallet/>} />
        <Route path='/search' element={<Search />} />
        <Route path='/collectionlist' element={<CollectionList />} />
        {/* <Route path='/details' element={<Details/>} /> */}
        <Route path="/myitem" element={<MyItem/>} />
        <Route path='/info/:network/:Contract/:Owner/:Id' element={<Details/>} />
        <Route path='/list/:address/:id' element={<List/>} />
        <Route path='/details' element={<Details/>} />
        <Route path="/my-item/:customurl" element={<MyItem/>} />
        <Route path='/profile/:customurl' element={<Board/>} />
        <Route path='/profile' element={<Board/>} />

        <Route path='/search/:key' element={<Search/>} />

        <Route path='/' element={<Home/>} />
        <Route path='/privacypolicy' element={<PrivacyPolicy/>} />
        <Route path='/terms' element={<Terms/>} />
        <Route path='/helpandcentre' element={<HelpandCentre/>}/>
        <Route path ='/contactus' element={<ContactUs/>} />
        <Route path ='/aboutus' element={<AboutUs/>} />
      

     
    </Routes>
    
    </BrowserRouter>

    <ToastContainer
				position="top-right"
				autoClose={1000}
				hideProgressBar={false}
				rtl={true}
				closeOnClick={true}
        closeButton={true}
				style={
					{zIndex:"999999",
					"width":"400px",
					"wordBreak":"break-word",
					"text-align":"center",
					"fontWeight":"500",
					marginTop:"70px"
				}} 
			/>   

    </>
  );
}

export default App;
