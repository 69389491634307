
import React, { useEffect, useState } from "react";
import "../assets/js/common.js";
import { Link, NavLink, useNavigate } from "react-router-dom";

import $ from "jquery";
import Copy from "../assets/images/svg/copy.svg";
import Profile from "../assets/images/profileicon.png";
import WalletIcon from "../assets/images/svg/wallet.svg";
import LogoutIcon from "../assets/images/svg/logout.svg";
import Accordion from 'react-bootstrap/Accordion';
import { toast } from 'react-toastify';
import { connectWallet, getServiceFees } from '../views/hooks/useWallet';
import { isEmpty } from '../actions/common';
import CopyToClipboard from "react-copy-to-clipboard";


import { RxPerson } from 'react-icons/rx';
import useContractProviderHook from "../actions/contractProviderHook";
import { userRegister, SearchAction, GetUserCookieToken } from '../actions/axioss/user.axios'
import { GetNftCookieToken } from '../actions/axioss/nft.axios'
import { Category, Currency, USDPRICE, TOKENPRICE } from '../actions/axioss/cms.axios'
import { useDispatch, useSelector } from 'react-redux';
import Config from "../views/config/config";
import { address_showing, copydata } from "../actions/common";

import users from "../assets/images/author-detail-1.png"
import noimg from "../assets/images/noimage.png"

export default function Header() {

    // const googleTranslateElementInit = () => {
    //     new window.google.translate.TranslateElement(
    //       {
    //         pageLanguage: "en",
    //         autoDisplay: false
    //       },
    //       "google_translate_element"
    //     );
    //   };

    // useEffect(() =>{
    //     var addScript = document.createElement("script");
    // addScript.setAttribute(
    //   "src",
    //   "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    // );
    // document.body.appendChild(addScript);
    // window.googleTranslateElementInit = googleTranslateElementInit;
        
    // },[])


    const [menu, setMenu] = React.useState(false);
    const [arrow, setArrow] = React.useState(false);
    const [categorys, setCategory] = React.useState([]);

    const [Searchdata, SetSearch] = React.useState(null);

    const { Categorys } = useSelector((state) => state.LoginReducer);
    const { AdminAddress } = useSelector((state) => state.LoginReducer);
    const [val, Setval] = useState("");
    const push = useNavigate()


    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if ($(this).scrollTop()) {
            $("header").addClass("fixedTop");
        } else {
            $("header").removeClass("fixedTop");
        }
        if (scroll >= 100) {
            $(".fixedTop").addClass('scroll');
            $("#scroll-top").addClass('show');
        }
        else {
            $(".fixedTop").removeClass('scroll');
            $("#scroll-top").removeClass('show');
        }
    });
    const ContractCall = useContractProviderHook();
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // client changed default theme dark
    // if (localStorage.theme) {
    //     localStorage.setItem("theme", localStorage.theme);
    //     var themeChange = localStorage.getItem("theme");
    //     if (localStorage.theme == "is_dark") {
    //         document.body.classList.remove("is_dark"); 

    //     }
    //     else{
    //         document.body.classList.remove("is_dark"); 

    //     }
    //     document.body.classList.add(localStorage.theme);
    //   } else {
    //     localStorage.setItem("theme", "is_dark"); 

    //     var themeChange = localStorage.getItem("theme");
    //     document.body.classList.remove("is_dark"); 

    //     document.body.classList.add("is_light");
    //   }
    
    const switchTheme = () => {
        if (localStorage.theme == "is_light") {
            localStorage.setItem("theme", "is_dark");
            document.body.classList.remove("is_dark");
            document.body.classList.add("is_light");
        }
        else {
            localStorage.setItem("theme", "is_light");
            document.body.classList.remove("is_dark");
            document.body.classList.add("is_light");
        }
    }
    const { Logedin } = useSelector(
        (state) => state.LoginReducer
    );

    const dispatch = useDispatch();
    const wallet = useSelector(state => state.LoginReducer.AccountDetails);
    const { payload, token } = useSelector(state => state.LoginReducer.User)
    console.log("User details", payload, AdminAddress)
    useEffect(() => {
        if (localStorage.getItem("walletConnectType") && wallet.accountAddress == '') {
            initialConnectWallet(localStorage.getItem("walletConnectType"))
        }
        // getadminaddress()
        
        getInitialSeviceFee();
        Categorys1();
        CurrencyList();

    }, []);
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum?.on("accountsChanged", handleAccountChange);
            return () => {
                window.ethereum?.removeListener("accountsChanged", handleAccountChange);
            };
        }
    }, [])

    window.addEventListener('load', function () {
        document.body.addEventListener('click', function () {
            Setval(''); SetSearch(null)
        });
    });

    useEffect(() => {
        if (wallet?.web3) {
            getadminaddress()
            getpolldata()
        }
    }, [wallet?.web3])

    const getadminaddress = async () => {
        //   debugger
        var data = await ContractCall.Getadminaddress()
        console.log("GETADDMINADDRESS", wallet, data, payload);
        if (data) {
            dispatch({
                type: "Admin_Section",
                Admin_Section: {
                    AdminAddress: String(data).toLowerCase(),
                },
            });
            dispatch({
                type: "Logedin",
                Logedin: { Logedin: !Logedin }
            })
        }
    }

    console.log(":::::::::::::::::::::::::::::::", AdminAddress, payload?.WalletAddress)
    const Categorys1 = async () => {

        let Resp = await Category();

        if (Resp?.data) {
            var sendda = [];
            var data = (Resp.data || []).map((item) => {

                // console.log('mapppppp',item);
                if (item.hideShow !== "hidden") {
                    sendda.push({
                        label: item.name,
                        value: item.name,
                        description: item.description,
                    });
                }

            });
            dispatch({
                type: "Register_Section",
                Register_Section: {
                    Categorys: sendda,
                },
            });
        }
    };
    const CurrencyList = async () => {
        let Resp = await Currency();

        if (Resp?.msg) {
            var sen = [];
            var datas = await Promise.all(
                Resp?.msg[0]?.CurrencyDetails ||
                []?.map(async (data) => {
                    if (data.label == "BNB" || data.label == "BNB")
                        var USD = await USDPRICE(data.label);
                    else var USD = await TOKENPRICE(data.address);
                    sen.push({
                        value: data.value,
                        label: data.label,
                        address: data.address,
                        usd: USD ? USD : 0,
                        decimal: data.decimal,
                    });
                })
            );
            dispatch({
                type: "Register_Section",
                Register_Section: {
                    currency: datas.length > 0 ? datas : sen,
                },
            });
        }
    };

    const getpolldata = async () => {
        var data = await ContractCall.GetPoll()
        console.log("getpoolldata", data, data?.lockPeriod, data?.lockPeriod / 1e18);
        if (data) {
            dispatch({
                type: "Pooldata",
                Pooldata: {
                    Pooldata: {
                        lockperiod: data?.lockPeriod / 1e18,
                        intervaltime: data?.interval / 1e18,
                        rewardPer : data.rewardPer / 1e18
                    },
                },
            });
        }

    }

    const handleAccountChange = (...args) => {
        const id = toast.success("Wallet Connecting...", { autoClose: 1000, isLoading: false })
        toast.update(id, { autoClose: 1000, isLoading: false, })
        initialConnectWallet(localStorage.walletConnectType);
    }
    const initialConnectWallet = async (type) => {
        // var dad=await getadminaddress()
        console.log("intigetaadin",);
        const id = ("Wallet Connecting...", { closeButton: true, closeOnClick: true })
        var accountDetails = await connectWallet(type)
        if (!isEmpty(accountDetails)) {
            var resp = await setWalletAddress('InitialConnect', accountDetails.accountAddress, type)
            if (resp?.success == 'success') {
                toast.update(id, { render: resp.msg, type: resp.success, autoClose: 1000, isLoading: false, })
                dispatch({
                    type: "Account_Section",
                    Account_Section: { AccountDetails: accountDetails }
                })
            }
            else {
                toast.update(id, { render: "Connect Wallet", type: 'error', autoClose: 1000, isLoading: false, })
            }

        }
    }
    const setWalletAddress = async (type, walletAddress, walletType) => {
        if (walletAddress) {
            var NewMethod = {
                Type: type,
                WalletAddress: walletAddress,
                WalletType: walletType,
            };
            //   console.log("Resp.data1",NewMethod);
            let Resp = await userRegister(NewMethod);
            //   console.log("Resp.data",Resp.data);
            if (Resp?.success == 'success') {
                dispatch({
                    type: 'Register_Section',
                    Register_Section: {
                        User: {
                            payload: Resp.data,
                            token: Resp.token ? Resp.token : token
                        }
                    }
                })
                document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
                GetNftCookieToken();
                GetUserCookieToken();
                return Resp
            }
            else return Resp
        }
        else return { success: 'error', msg: 'No Address Detected.. Check Your Wallet' }

    }
    const walletDisconnect = async () => {
        localStorage.removeItem("accountInfo")
        localStorage.removeItem("walletConnectType")
        dispatch({
            type: 'Account_Section',
            Account_Section: {
                AccountDetails: {
                    accountAddress: "",
                    tokenBalance: 0,
                    coinBalance: 0
                }
            }
        })
        push("/")
        window.location.reload();
        document.cookie = 'token' + "=" + "" + ";" + ";path=/";
        GetNftCookieToken();
        GetUserCookieToken();
    }
    const getInitialSeviceFee = async () => {
        var fees = await getServiceFees();
        // console.log("getServiceFees",fees);
        if (fees) {
            dispatch({
                type: 'ServiceFees',
                ServiceFees_Section: {
                    ServiceFees: fees,
                },
            });
        }
    };
    const click = async (table) => {
        push("/explore/" + table)
    }


    const OnChange = async (value) => {
        // console.log("vallllllllllll",value);
        if (value) {
            Setval(value);
            var Resp = await SearchAction({

                keyword: value,
                limit: 3,
                page: 1,
                from: "search",
            });
            // console.log("response", Resp);
            if (Resp.success === "success") {
                SetSearch(Resp);
            }
            else {
                SetSearch(null)
            }
        }
        else {
            SetSearch(null)
            Setval('')
        }
    };

    const Search = () => {
        if (window.location.pathname.includes('search')) {
            SetSearch(null)
            Setval('')
        }

    }
    
    // const googleTranslateElementInit = () => {
    //     console.log("Translate");
    //     // new window.google.translate.TranslateElement(
    //     //   {
    //     //     pageLanguage: "en",
    //     //     autoDisplay: false
    //     //   },
    //     //   "google_translate_element"
    //     // );

    //     new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')

    //     // new window.google.translate.TranslateElement(
    //     //     {
    //     //       pageLanguage: 'en',
    //     //       includedLanguages: 'en,ms,ta,zh-CN', // include this for selected languages
    //     //       layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    //     //     },
    //     //     'google_translate_element'
    //     //   );
    //   };
    //   const setTranslate = () =>{
    //     // var addScript = document.createElement("script");
    //     // addScript.setAttribute(
    //     //   "src",
    //     //   "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    //     // );
    //     // document.body.appendChild(addScript);
    //     // window.googleTranslateElementInit = googleTranslateElementInit;
    //     var addScript = document.createElement('script');
    // addScript.setAttribute(
    //   'src',
    //   '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    // );
    // document.body.appendChild(addScript);
    // window.googleTranslateElementInit = googleTranslateElementInit;
    //   }
    //   useEffect(() => {
    //     setTranslate();
        
    //   }, []);
    return (
        <>
            {console.log("SAMECREATEE", Config.ADMINADDRESS, payload)}
            <header className="header align_header">
            {/* <div id="google_translate_element"></div> */}
                {menu == true ?
                    (<nav id="main-nav-mobi" className="main-nav d-block">
                        <ul id="menu-primary-menu" className="menu">
                            {/* <li className="menu-item">
                                            <NavLink to="/">Home</NavLink>
                                        </li> */}
                            <li className="menu-item search-item">
                                <form onSubmit={push((`/search/${val}`))}>
                                    <div className="search-form d-flex justify-content-between align-items-center">
                                        <input type="text" placeholder="Search" required="" onChange={(e) => OnChange(e.target.value)} autoComplete="off" />
                                        <Link className="btn-search">
                                            {!val && <i className="icon-fl-search-filled"></i>}
                                            {val && <i className="fa fa-times" onClick={() => { Setval(''); SetSearch(null) }}></i>}
                                            {/* <i className="fa fa-times"></i> */}
                                        </Link>
                                        <div className="search-model" >
                                            {val && (
                                                <div className="contentTop" id="smc-modal">
                                                    {Searchdata?.token?.data?.length > 0 ||
                                                        Searchdata?.user?.msg?.length > 0 || Searchdata?.collection?.msg?.length > 0 ? (<>
                                                            {Searchdata?.collection?.data?.length > 0 && (
                                                                <div className="content">
                                                                    {/* <h6>Collections</h6> */}
                                                                    {Searchdata?.collection?.data?.map(
                                                                        (value) => (<>
                                                                            {/* <div className="d-flex align-items-center justify-content-start pt-4 searchCursor" onClick={() => {
                                                                        Setval("");
                                                                      
                                                                    }}
                                                                    >
                                                                       <div className="">
                                                                       <img
                                                                                        src={
                                                                                            value.CollectionProfileImage
                                                                                                ? `${Config.IMG_URL}/collection/${value.CollectionSymbol}/${value.CollectionProfileImage}`
                                                                                                : require("../assets/images/noimage.png")
                                                                                        }
                                                                                        
                                                                                     alt="Search" className="img-fluid"/>
                                                                       </div>
                                                                       <div className="ml-3">
                                                                        <p>{value.CollectionName}</p>
                                                                       
                                                                        
                                                                        </div>
                                                                    </div> */}
                                                                        </>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                            {Searchdata.user?.msg?.length > 0 && (

                                                                <div className="content">
                                                                    <h6>Users</h6>
                                                                    {Searchdata.user?.msg.map(
                                                                        (value) => (
                                                                            <>
                                                                                <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                    onClick={() => {
                                                                                        Setval("");
                                                                                        push(
                                                                                            `/profile/${value.CustomUrl}`
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <div className="">
                                                                                        <img
                                                                                            src={
                                                                                                value.Profile
                                                                                                    ? `${Config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`
                                                                                                    : noimg
                                                                                            }
                                                                                            className="img"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="ml-3">
                                                                                        <p >
                                                                                            {value.DisplayName}
                                                                                        </p>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}

                                                            {Searchdata.token?.data?.length > 0 && (
                                                                <div className="content">
                                                                    <h6>Tokens</h6>
                                                                    {Searchdata.token?.data.map(
                                                                        (value) => (<>
                                                                            <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                onClick={() => {
                                                                                    Setval("");
                                                                                    SetSearch();
                                                                                    push(
                                                                                        `/info/${value.CollectionNetwork}/${value.ContractAddress}/${value.NFTOwner}/${value.NFTId}`
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <div className="">
                                                                                    <img src={value.CompressedFile.includes('webp') ? `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}` : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`} alt="Search" className="img-fluid" />
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    <p>  {value.NFTName}</p>
                                                                                    <span></span>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                        )
                                                                    )}
                                                                </div>

                                                            )}
                                                            <div className="tf-button search-button my-5 d-flex justify-content-center">
                                                                <Link to={(`/search/${val}`)}>Search</Link>
                                                                {/* <Link to="/">Search</Link> */}
                                                            </div>
                                                        </>) : (val === '' ? <></>
                                                            : 
                                                            <div className="text-center">
                                                            <span className="error_msg">
                                                                No data Found
                                                            </span></div>
                                                    )}
                                                </div>
                                            )}
                                            {/* <ul className="sub-menu d-block">
                                                <li className="menu-item "><NavLink to="/explore">3D MODEL</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">ANIME/MANGA</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">CYBER PUNK</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">PIXEL ART</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">MUSIC</NavLink></li>
                                                <li className="menu-item"><NavLink to="/explore">2D ARTS</NavLink></li>
                                                <div class="btn-slider "><NavLink class="tf-button style-2" href="/">Search<i class="far fa-long-arrow-right"></i></Link></div>
                                            </ul> */}
                                        </div>
                                    </div>
                                </form>
                            </li>
                            {/* <li className="menu-item menu-item-has-children">
                                        <Accordion defaultActiveKey="0" className="menu-sub">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header> <NavLink to="/explore">Explore</NavLink></Accordion.Header>
                                            <Accordion.Body>
                                            
                                             <ul className="sub-menu d-block">
                                 <li className={"menu-item"} onClick={()=>{click("All")}}><Link to="#">All</Link></li>
                                    {Categorys.map(item=>  <li className={"menu-item"} onClick={()=>{click(item.value)}}><Link to="#">{item.label}</Link></li>)}
                                    </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        </Accordion>
                                        </li> */}
                            {/* <li className="menu-item">
                                            <NavLink to="/create">Create</NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink to="/faq">FAQ</NavLink>
                                        </li>    */}
                            <li className="menu-item">
                                <NavLink to="/">Home</NavLink>
                            </li>
                            {/* <li className="menu-item">
                                            <NavLink to="/myitem">My item</NavLink>
                                        </li> */}
                            {/* {wallet && wallet.accountAddress &&                                                           
                                        <li className="menu-item">

                                            <NavLink to={`/profile/${payload?.CustomUrl}`}>My item</NavLink>                                        

                                        </li>
                                        }  */}
                            {console.log("geteeeeeee", AdminAddress == payload.WalletAddress)}
                            {AdminAddress && payload && AdminAddress == payload.WalletAddress ? <li className="menu-item">
                                <NavLink to="/create">Create</NavLink>
                            </li> : ""}
                            <li className="menu-item">
                                <NavLink to="/faq">FAQ</NavLink>
                            </li>
                            <li  className="menu-item">DEX</li>
                            {wallet && wallet.accountAddress ?
                                <>
                                    <li className="menu-item">
                                        <NavLink to={`/profile/${payload?.CustomUrl}`}>My item</NavLink>
                                        {/* <NavLink to="/">Profile</NavLink>                                              */}
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="#" onClick={() => { walletDisconnect() }}>Disconnect</NavLink>
                                        {/* <NavLink to="#" >Disconnect</NavLink>                                      */}
                                    </li>
                                </>
                                :
                                <li className="menu-item">
                                    <NavLink to="/connect">Connect Wallet</NavLink>
                                </li>
                            }

                        </ul>
                    </nav>)
                    : (<></>)
                }
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div id="site-header-inner">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <NavLink to="/" rel="home" className="main-logo">
                                            <p id="logo_header"></p>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="header-center">
                                    <nav id="main-nav" className="main-nav">
                                        <ul id="menu-primary-menu" className="menu">
                                            {/* <li className="menu-item">
                                            <NavLink to="/">Home</NavLink>
                                        </li> */}



                                            {/* <li className="menu-item menu-item-has-children ">
                                            <NavLink to="/explore">Explore</NavLink>
                                            <ul className="sub-menu">
                                 <li className={"menu-item"} onClick={()=>{click("All")}}><Link to="#">All</Link></li>

                                    {Categorys.map(item=>  <li className={"menu-item"} onClick={()=>{click(item.value)}}><Link to="#">{item.label}</Link></li>)}

                                    </ul>
                                          
                                        </li> */}

                                            <li className="menu-item">
                                                <NavLink to="/">Home</NavLink>
                                            </li>
                                            <li className="menu-item">
                                                <NavLink to="/explore">Explore</NavLink>
                                            </li>
                                            {/* <li className="menu-item">
                                            <NavLink to="/myitem">My item</NavLink>
                                        </li>  */}
                                            {console.log("geteeeeeee", AdminAddress == payload?.WalletAddress, "gg", AdminAddress, "bb", payload?.WalletAddress)}

                                            {(AdminAddress == payload?.WalletAddress && (!isEmpty(payload?.WalletAddress) && !isEmpty(AdminAddress))) &&
                                                <li className="menu-item">
                                                    <NavLink to="/create">Create</NavLink>
                                                </li>
                                            }

                                            {wallet && wallet.accountAddress &&
                                                <li className="menu-item">

                                                    <NavLink to={`/profile/${payload?.CustomUrl}`}>My item</NavLink>

                                                </li>
                                            }
                                            <li className="menu-item">
                                                <NavLink to="/faq">FAQ</NavLink>
                                            </li>
                                            <li className="menu-item" ><NavLink to={Config.DEX} target="_blank">DEX</NavLink></li>

                                            <li className="menu-item search-item">
                                                <form onSubmit={() => push((`/search/${val}`))}>
                                                    <div className="search-form d-flex justify-content-between align-items-center">
                                                        <input type="text" value={val} placeholder="Search" required="" autoComplete="off" onChange={(e) => OnChange(e.target.value)} />
                                                        <Link className="btn-search">
                                                            {!val && <i className="icon-fl-search-filled"></i>}
                                                            {val && <i className="fa fa-times" onClick={() => { Setval(''); SetSearch(null) }}></i>}
                                                        </Link>
                                                        {/* <ul className="sub-menu d-block">
                                                        <li className="menu-item "><NavLink to="/explore">3D MODEL</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">ANIME/MANGA</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">CYBER PUNK</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">PIXEL ART</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">MUSIC</NavLink></li>
                                                        <li className="menu-item"><NavLink to="/explore">2D ARTS</NavLink></li>
                                                        <div class="btn-slider "><NavLink class="tf-button style-2" href="/">Search <i class="far fa-long-arrow-right"></i></Link></div>
                                                    </ul> */}
                                                    </div>

                                                    <div className="search-model">
                                                        {val && (
                                                            <div className="contentTop">
                                                                {Searchdata?.token?.data?.length > 0 ||
                                                                    Searchdata?.user?.msg?.length > 0 || Searchdata?.collection?.msg?.length > 0 ? (<>
                                                                        {Searchdata?.collection?.data?.length > 0 && (
                                                                            <div className="content">
                                                                                {/* <h6>Collections</h6> */}
                                                                                {Searchdata?.collection?.data?.map(
                                                                                    (value) => (<>
                                                                                        {/* <div className="d-flex align-items-center justify-content-start pt-4 searchCursor" onClick={() => {
                                                                        Setval("");
                                                                        push(
                                                                            `/collection/${value.CollectionSymbol}`
                                                                        );
                                                                    }}
                                                                    >
                                                                       <div className="">
                                                                       <img
                                                                                        src={
                                                                                            value.CollectionProfileImage
                                                                                                ? `${Config.IMG_URL}/collection/${value.CollectionSymbol}/${value.CollectionProfileImage}`
                                                                                                : require("../assets/images/noimage.png")
                                                                                        }
                                                                                        
                                                                                     alt="Search" className="img-fluid"/>
                                                                       </div>
                                                                       <div className="ml-3">
                                                                        <p>{value.CollectionName}</p>
                                                                    
                                                                        
                                                                        </div>
                                                                    </div> */}
                                                                                    </>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        {Searchdata.user?.msg?.length > 0 && (

                                                                            <div className="content">
                                                                                <h6>Users</h6>
                                                                                {Searchdata.user?.msg.map(
                                                                                    (value) => (
                                                                                        <>
                                                                                            <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                                onClick={() => {
                                                                                                    Setval("");
                                                                                                    push(
                                                                                                        `/profile/${value.CustomUrl}`
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <div className="">
                                                                                                    <img
                                                                                                        src={
                                                                                                            value.Profile
                                                                                                                ? `${Config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`
                                                                                                                : noimg
                                                                                                        }
                                                                                                        className="img"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="ml-3">
                                                                                                    <p >
                                                                                                        {value.DisplayName}
                                                                                                    </p>
                                                                                                    <span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {Searchdata.token?.data?.length > 0 && (
                                                                            <div className="content">
                                                                                <h6>Tokens</h6>
                                                                                {Searchdata.token?.data.map(
                                                                                    (value) => (<>
                                                                                        <div className="d-flex align-items-center justify-content-start pt-4 searchCursor"
                                                                                            onClick={() => {
                                                                                                Setval("");
                                                                                                SetSearch();
                                                                                                push(
                                                                                                    `/info/${value.CollectionNetwork}/${value.ContractAddress}/${value.NFTOwner}/${value.NFTId}`
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <div className="">

                                                                                                <img src={value.CompressedFile.includes('webp') ? `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}` : `${Config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`} alt="Search" className="img-fluid" />
                                                                                            </div>
                                                                                            <div className="ml-3">
                                                                                                <p>  {value.NFTName}</p>
                                                                                                <span></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </>
                                                                                    )
                                                                                )}
                                                                            </div>

                                                                        )}
                                                                        <div className="tf-buton search-button my-5 d-flex justify-content-center">
                                                                            {/* <Link to="/">Search</Link>                                                         */}
                                                                            <Link to={`/search/${val}`} onClick={Search}>Search</Link>
                                                                        </div>
                                                                    </>) : (val === '' ? <></>
                                                                        : <span className="error_msg">
                                                                            No data Found
                                                                        </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>

                                                </form>
                                            </li>

                                        </ul>
                                    </nav>
                                </div>

                                <div className="header-right">

                                    {
                                        wallet && wallet.accountAddress &&
                                        <div className="popup-user">
                                            <div className="avatar">
                                                <img className="img-fluid imgprofile" width="20" height="18" src={payload?.Profile ? Config.IMG_URL + "/user/" + payload?.WalletAddress + '/profile/' + payload?.Profile : Config.profile} alt="avatar" />
                                            </div>
                                            <div className="avatar_popup">
                                                {payload && payload.msg && payload.msg.DisplayName &&
                                                    <h6>{payload.msg.DisplayName}</h6>
                                                }

                                                <div className="price">
                                                    <p className="title">Balance</p>
                                                    {
                                                        wallet && wallet.coinBalance ?
                                                            <p className="style">{wallet && wallet.coinBalance / 1e18} {Config.COIN_NAME}</p>
                                                            :
                                                            <p className="style">0 {Config.COIN_NAME}</p>
                                                    }

                                                </div>
                                                {wallet && wallet.accountAddress && <>
                                                    {localStorage.getItem("walletConnectType") === 'Coinbase' ? <p className="title">Trust Wallet</p> : <p className="title">{localStorage.getItem("walletConnectType")}</p>}
                                                    <div className="code">
                                                        <p>{address_showing(wallet.accountAddress)}</p>
                                                        <CopyToClipboard
                                                            text={wallet?.accountAddress}
                                                            onCopy={() =>
                                                                toast.success("Address Copied", { autoClose: 1000 })
                                                            } >

                                                            <img src={Copy} alt="Images" />

                                                            {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
                                                        </CopyToClipboard>
                                                        {/* <NavLink to="#" className="no-link" onClick={() => { copydata(wallet.accountAddress); toast.success("Address Copied", { autoClose: 1000 }) }}>
                                                            <img src={Copy} alt="Images" />
                                                        </NavLink> */}
                                                    </div>
                                                </>}

                                            </div>
                                        </div>
                                    }

                                    {wallet && wallet.accountAddress ?
                                        <NavLink to="#" className="tf-button tf-connect" onClick={() => { walletDisconnect() }}><span>Disconnect</span></NavLink>
                                        :
                                        <NavLink to="/connect" className="tf-button tf-connect co-wa"><span>Connect Wallet</span></NavLink>
                                    }
                                    {/* <NavLink to="/connect" className="tf-button tf-connect"><span>Connect Wallet</span></NavLink> */}

                                    {/* <Link to="#" className="mode-switch" onClick={switchTheme}>
                                       <p className="icon-change"></p>
                                    </Link> */}
                                </div>
                                <div className={menu == true ? "mobile-button active" : "mobile-button"} id="mobile-button" onClick={() => setMenu(!menu)}><span></span></div>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}