import React, { useEffect, useState, useRef } from "react";
import Author from "../assets/images/product52.jpg";
import Card from "./Card.js";
import Tick from "../assets/images/svg/tick.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import aboutprofile from '../assets/images/Artist1.png'
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../separate/Header1";
import Footer from "../separate/Footer1";
import Countdown from 'react-countdown';
import Modals from "./Modals";
import { useSelector } from "react-redux";
import { CollectionByCreator, HotAuctionHotSales, Token_List_Func, TopCreatorApi } from "../actions/axioss/nft.axios"
import { getCmsContent, getarticle, getabouuser } from "../actions/axioss/cms.axios"
import { getFaqList,getFaqcontentList} from '../actions/axioss/user.axios';

import { address_showing, } from "../actions/common";
import config from './config/config'
import ImgAudVideo from "../separate/ImgAudVideo";
import NoData from "./seperatemodals/nodata";
import { SearchAction, Sociallinks, Newsletter } from "../actions/axioss/user.axios"
import { Getpromotedtoken } from "../actions/axioss/nft.axios";
import { data } from "jquery";
import { midjourney } from "../actions/axioss/user.axios";
import faqimg from "../assets/images/galaxy-7040416__480.webp";
import rocket from '../assets/images/RocketLaunch.png';
import aiiimg from '../assets/images/aiimg.png';
import placee from '../assets/images/Imageplaceholder.png';
import wing1 from '../assets/images/pngwing 1.png';
import wing2 from '../assets/images/pngwing 2.png';
import Stakess from './Stakes'
import author from '../assets/images/Artist1.png';
import { toast } from "react-toastify";
import star from '../assets/images/joyimg/star.svg';
import CountUp from 'react-countup';
import { colors } from "@mui/material";
import { isEmpty } from "../actions/common";

import icon1 from '../assets/images/joyimg/icon-1.png';
import icon2 from '../assets/images/joyimg/icon-2.png';
import icon3 from '../assets/images/joyimg/icon-3.png';
import icon4 from '../assets/images/joyimg/icon-4.png';
import homelogo from '../assets/images/joyimg/logo.png';

import gold from '../assets/images/joyimg/gold.png';
import rose from '../assets/images/joyimg/rose.png';
import green from '../assets/images/joyimg/green.png';
import violent from '../assets/images/joyimg/violent.png';
import browny from '../assets/images/joyimg/brownmonkeyy.webp'
import goldround from '../assets/images/joyimg/goldround.png';
import greenround from '../assets/images/joyimg/greenround.png';

import leftcurve from '../assets/images/joyimg/leftcurve.png';
import rightcurve from '../assets/images/joyimg/rightcurve.png';

import nftimg from '../assets/images/joyimg/features.png';
import teamimg from '../assets/images/joyimg/Artist1.png';
import Accordion from 'react-bootstrap/Accordion';

import fac from '../assets/images/joyimg/facebook 1.png';
import insta from '../assets/images/joyimg/instagram 1.png';
import twit from '../assets/images/joyimg/twitter 1.png';
import gift from '../assets/images/joyimg/gift.png';
import Dummy from './Dummycard'

import thunder from '../assets/images/joyimg/thunder.png';

import grad from '../assets/images/joyimg/gradient.svg';
import Cardart from './carduploadpreview';

// import { Web3Auth } from "@web3auth/modal";

export default function Home() {
    const [show, setShow] = React.useState(false);
    const [hotactionstate, SetHotAuctionstate] = useState(true)
    const [hotsalestate, SetHotsalestate] = useState(true)
    const [bannerstate, setBannerstatus] = useState(true)
    const[status,setStatus]=useState(true)

    const [faqList,setFaqList] = useState([]);
    const { payload, isAdmin } = useSelector(state => state.LoginReducer.User)
    const navigate = useNavigate()
    const [once, setOnce] = useState(false)


    useEffect(() => {
        // if (!once) {
            Getlink();
            aboutuser()
            HotAuction(1, 'All')
            HotSales('All')
            getFaqDetails()
            // getarticles()
        // }
        // setOnce(true)
    }, [])
    const getFaqDetails = async()=>{
        var resp = await getFaqList();
        console.log("resp userlist",resp)
        if(resp?.status){
            setStatus(false)
            setFaqList(resp.data);
        }
           
    }

    const collection = ([
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" }]
    )
    const slideone = {
        dots: false,
        infinite: true,
        smartSpeed: 4000,
        autoplayHoverPause: true,
        autoplay: true,
        // loop:true,
        nav: false,
        margin: 30,
        speed: 1500,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        },

    }

    const options1 = {
        loop: true,
        margin: 20,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        nav: true,
        dots: false,
        smartSpeed: 1200,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };

    const optionlist = {
        loop: true,
        margin: 20,
        navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
        nav: true,
        dots: false,
        smartSpeed: 1200,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 2
            }
        }
    }

    var [link, setLink] = useState([]);
    var [email, setEmail] = useState('');
    var [Error, SetError] = useState({});






    const aboutuser = async () => {
        var aboutusers = await getabouuser()
        if (aboutusers?.status) {
            Setaboutlist1(aboutusers?.data)
        }
    }

    // const Getandsellnft = async () => {

    //     var resp = await getCmsContent("footer");
    //     console.log("footerrr",resp.data)
    //     if (resp?.status)
    //         setFooter(resp.data);
    // }

    const Getlink = async () => {

        let link_res = await Sociallinks();
        console.log("linkss", link_res?.msg);
        setLink(link_res?.msg ?? [])
    }

    const NewsLetter = async () => {
        const id = toast.loading("Subscribing...");

        let err = {};
        if (email == '') err.email = "Email Id Required";
        if (email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        // console.log('fhbdfhbdf',email)
        if (isEmpty(err)) {
            let resp = await Newsletter({ email: email });
            if (resp.success === 'success') {
                toast.update(id, { render: "successfully Subcribed for NewsLetter", type: "success", isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                setEmail('');
            }
            else {
                toast.update(id, { render: " User Already Subscribed", type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                SetError({ email: resp.msg });
            }
        }
        else {
            toast.update(id, { render: err.email, type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
        }
    }



    const [blog, setBlog] = useState([])
    const [aboutlist1, Setaboutlist1] = useState([
        {
            "id": 1,
        },
        {
            "id": 2,
        },
        {
            "id": 3,
        },
        {
            "id": 4,
        },
        {
            "id": 5,
        },
        {
            "id": 6,
        },
        {
            "id": 7,
        },
        {
            "id": 8,
        },
    ])

    const slidetwo = {
        dots: false,
        infinite: true,
        smartSpeed: 3500,
        autoplayHoverPause: true,
        autoplay: true,
        loop: true,
        nav: false,
        margin: 30,
        speed: 1500,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        },
    }
    var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {

            return <span>Waiting for Owner To Accept</span>
        } else {
            return <span> <span className="hourds">{formatTime(days)} d</span>{" "}
                <span className="semicolan">:</span>{" "} <span className="hourds">{formatTime(hours)} h</span> <span className="semicolan">:</span> <span className="hourds">{formatTime(minutes)} m</span> <span className="semicolan">:</span> <span className="hourds">{formatTime(seconds)} s</span> </span>;
        }
    };
    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    /** lp */
    const { Categorys } = useSelector((state) => state.LoginReducer);

    const [creatorstatus, setCreator] = useState(false)
    const [TopCreator, SetTopCreator] = useState([])

    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);


    const [homecontent, setHomecontent] = useState([]);

    const [homecontentmidd, setHomecontentmidd] = useState([])

    const [dummycards, setdummycards] = useState([
        { "id": 1, "name": "Joyfun", "amount": "0.001 BNB", "image": require("../assets/images/joyfunflowerart.png") }, { "id": 2, "name": "Joyfun", "amount": "0.038 BNB", "image": require("../assets/images/joyfunflowerart.png") }, { "id": 3, "name": "Joyfun", "amount": "0.096 BNB", "image": require("../assets/images/joyfunflowerart.png") }
    ])




    const [HotAuctionData, SetHotAuction] = useState({
        All: [],
        "tab": "All"
    });
    // setselloading(false)
    const [selloding, setselloading] = useState(false)
    const [SaleData, SetSaleData] = useState({
        All: [],
    });
    const [category, setCategory] = useState("All")
    const [CreateCollectionState, SetCreateCollectionState] = useState({
        All: [],
        "tab": "All"
    });
    const [val, Setval] = useState("");
    const [Searchdata, SetSearch] = React.useState(null);
    const [filter, setFilter] = useState({ auction: "Recent", collection: "new", sale: "LatestDrops" });
    var [filterview, setFilterview] = useState({ auction: "Recently created", collection: "Recently created", sale: "Recently created" })
    var [promotedToken, setPromotedtoken] = useState({});

    // const [homecontent,setHomecontent]=useState([]);
    // const[homecontentmidd,setHomecontentmidd]=useState([])
    // console.log("------------------",category);


    const CollectionByCreate = async (data) => {
        var SendDATA = {
            tab: data,
            limit: 4,
            ProfileUrl: "",
            page: 1,
            from: "home",
            filter: filter.collection,
        };
        let Resp = await CollectionByCreator(SendDATA)
        // console.log('fhgngfngf',Resp)
        SetCreateCollectionState({
            ...CreateCollectionState,
            ...{
                [data]: Resp?.data,
                "tab": data,
                "filter": filter.collection,
            },
        })
        setCategory(data)
    }

    const getarticles = async () => {
        var resp = await getarticle()
        if (resp?.status) {
            setBlog(resp.data)
        }

    }
    const TopCreatorFunc = async () => {
        var resp = await TopCreatorApi()
        console.log('topcreator', resp)
        if (resp?.data.length > 8) {
            SetTopCreator(resp?.data.slice(0, 8))
            setCreator(true)
        }
        else {
            SetTopCreator(resp?.data)
            setCreator(true)
        }

    }
    // console.log("HotAuctionData",HotAuctionData)
    const HotAuction = async (data, tabs) => {
        var SendDATA = {
            TabName: tabs,
            limit: 8,
            ProfileUrl: "",
            page: 1,
            from: "Auction",
            filter: filter.auction,
        };
        let Resp = await Token_List_Func(SendDATA);
        if (Resp?.success == "success") {
            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.auction
                },
            })
            SetHotAuctionstate(false)
        }
        else {
            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.auction

                },
            })
            SetHotAuctionstate(false)
        }


    }
    const HotSales = async (tabs) => {
        setselloading(false)

        var SendDATA = {
            TabName: tabs,
            limit: 8,
            ProfileUrl: "",
            page: 1,
            from: "Sale",
            filter: filter.sale
        };
        let Resp = await Token_List_Func(SendDATA);
        console.log('hot sales', Resp, 'tabs', tabs);
        if (Resp?.success == "success") {
            setselloading(true)
            SetSaleData({
                ...SaleData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.sale
                },
            })
            SetHotsalestate(false)
        }
        else {
            setselloading(true)
            SetSaleData({
                ...SaleData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.sale
                },
            })
            SetHotsalestate(false)
        }
    }


    function LikeList(data) {
        setLikedTokenList(data)
    }

    const Collectionlist = async (tabs) => {
        var SendDATA = {
            TabName: tabs,
            limit: 4,
            ProfileUrl: "",
            page: 1,
            from: "collection",
        };
        let Resp = await Token_List_Func(SendDATA);
        if (Resp?.success == "success") setCategory({
            ...SaleData,
            ...{
                [tabs]: Resp?.data,
                "tab": tabs
            },
        })
    }

    const Getandselldata = async () => {

        var resp = await getCmsContent("homepage_top");
        //  console.log("cmslist",resp.data)
        if (resp?.status)
            setHomecontent(resp.data);
    }
    const Getandsellnft = async () => {

        var resp = await getCmsContent("homepage_middle");
        // console.log("cmslist",resp.data)
        if (resp?.status)
            setHomecontentmidd(resp.data);
    }

    const OnChange = async (value) => {
        // console.log("vallllllllllll",value);
        if (value) {
            Setval(value);
            var Resp = await SearchAction({
                keyword: value,
                limit: 3,
                page: 1,
                from: "home",
            });
            // console.log("response", Resp);
            if (Resp?.success === "success") {
                SetSearch(Resp);
            }
            else {
                SetSearch(null)
            }
        }
        else {
            SetSearch(null)
            Setval('')
        }
    };

    const GetPromtion = async () => {
        // var resp = await Getpromotedtoken()
        var protoken = await Getpromotedtoken();
        console.log('DGJFGJFJD', protoken)
        if (protoken?.success == 'success') {
            console.log(protoken.data[0], "protoken")
            setPromotedtoken(protoken.data[0])
            setBannerstatus(false)
        };
    }

    useEffect(() => {
        // if(filter?.auction)
        if (HotAuctionData.filter !== undefined && filter.auction !== HotAuctionData.filter) {
            HotAuction('hi', HotAuctionData.tab)
        }
        if (SaleData.filter !== undefined && filter.sale !== SaleData.filter) {
            HotSales(SaleData.tab)
        }
        if (CreateCollectionState.filter !== undefined && filter.collection !== CreateCollectionState.filter) {
            CollectionByCreate(CreateCollectionState.tab)
        }
    }, [filter])

    const AuctionFilter = (val, show) => {
        setFilter({ ...filter, ...{ auction: val } });
        setFilterview({ ...filterview, ...{ auction: show } });
    }

    const SaleFilter = (val, show) => {
        setFilter({ ...filter, ...{ sale: val } });
        setFilterview({ ...filterview, ...{ sale: show } });
    }

    const CollectionFilter = (val, show) => {
        setFilter({ ...filter, ...{ collection: val } });
        setFilterview({ ...filterview, ...{ collection: show } });
    }

    return (
        <>
            <div className="homepage" id="homescreen">
                <Header />

                <div className="modals"><Modals /></div>
                <section className="tf-slider tf-start">
                    {/* <img src={grad} className="firstgrad" alt="firstgrad" /> */}
                    <div className="tf-container homesection1">
                        <div className="row1">
                            <div className="coll-md-12">
                                <div className="swiper-container slider-home ">
                                    <div className="tf-slider-item1 style-41 top-banner">
                                        <div className="row hometry align-items-center mt-0 w-100">
                                            <div className="col-md-6 fpad mt-3 firstcol" data-aos="fade-up">
                                                {/* <div className="first-p" dangerouslySetInnerHTML={{ __html: homecontent?.answer }}></div> */}
                                                <div>
                                                    <p className="home_banne_tex">
                                                        <img src={star} className="starimg" alt="images" />

                                                        Buy NFTs <br></br> <span className="collec">Collection</span>
                                                        {/* <div className="first-p" dangerouslySetInnerHTML={{ __html: homecontent?.description }}></div> */}
                                                        <img src={star} className="starimg1" alt="images" />
                                                    </p>
                                                    <p className="home_banne_tex_dec">
                                                        NFT Marketplace is the web3 NFT Marketplace where traders and collectors earn rewards.
                                                        {/* <div className="first-p" dangerouslySetInnerHTML={{ __html: homecontent?.answer }}></div> */}
                                                    </p>
                                                </div>
                                                <div className="btn-slider exploretop mt-5 mb-5">
                                                    {/* <input type="button" onClick={()=>midjourney("kooky portraits of 90s mall teens in retro clothing, shot on VHS")} value="Midjourney"/> */}
                                                    <NavLink to="/explore" className="tf-button style-2">
                                                        Buy Now
                                                    </NavLink>
                                                    <NavLink to={`${config.IMG_URL}/guidevideo/JoyfunNFT.mp4`}  target="_blank" className="tf-button style-2 ml-3">
                                                       Guide video
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-0 gradients">
                                                <div>
                                                    {
                                                        !selloding ?
                                                            <>
                                                                <div className="text-centre loadingcontent">
                                                                    <h3>Loading...</h3>

                                                                </div>
                                                            </>
                                                            :
                                                            <OwlCarousel className='owl-theme' {...optionlist} >
                                                                {
                                                                    SaleData[SaleData.tab]?.length > 0 ?

                                                                        SaleData[SaleData.tab]?.slice(0, 8).map((item, index) =>
                                                                            <div class="item">

                                                                                <Card
                                                                                    product={item}
                                                                                    type="marketplace"
                                                                                    index={index}
                                                                                    LikeList={LikeList}
                                                                                    LikedTokenList={LikedTokenList}
                                                                                    setLikedTokenList={setLikedTokenList}
                                                                                    LikeForwardRef={LikeForwardRef}
                                                                                />
                                                                                {/* )} */}

                                                                            </div>
                                                                        )
                                                                        :
<>
<Dummy/></>
                                                                        // dummycards.map((e, index) =>
                                                                        //     <div class="item">
                                                                        //         <div class="sc-product style1 staticback">
                                                                        //             <div class="features"><div class="product-media audImgVid"><img src={e.image} alt="img" /></div></div>
                                                                        //             <div class="bottom pads">
                                                                        //                 <div class="text-center fullname"><a >{e.name}</a></div><div><p class="ETHno">{e.amount} </p></div><div class="product-buttons buynowbuttoon"><a data-toggle="modal" data-target="#popup_bid" class="tf-buttons" > <span class="icon-btn-product"></span>Buy Now</a></div>
                                                                        //             </div>
                                                                        //         </div>
                                                                        //     </div>
                                                                        // )
                                                                }
                                                            </OwlCarousel>}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>


                <section className="">
                    <div className="contribute">
                        <div className="tf-container">
                            <div className="row">
                                <div className="col-md-2 col-sm-4 pt-3 text-center">
                                    <h3><CountUp end={550} /></h3>
                                    <p>Total Items</p>
                                </div>
                                <div className="col-md-1 col-sm-2 pt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="66" viewBox="0 0 53 66" fill="none">
                                        <path d="M25.036 54.106C23.7609 49.6652 21.808 45.4802 19.3166 41.8434C16.1069 37.1579 11.9402 33.2996 7.09675 31.0317C10.786 29.3898 13.9995 26.8555 16.8454 23.3912C20.4626 18.988 23.4681 13.1061 26.167 5.70697C29.7149 14.1811 32.9558 20.5788 36.6047 25.0197C38.7977 27.6887 41.18 29.7063 43.9176 31.0463C44.0753 31.1235 44.2339 31.1983 44.3933 31.2707C40.1532 33.4146 36.0787 36.907 32.6878 41.0085C29.5286 44.8296 26.8249 49.3391 25.036 54.106Z" stroke="white" stroke-width="4" />
                                    </svg>
                                </div>
                                <div className="col-md-2 col-sm-4 pt-3 text-center">
                                    <h3><CountUp end={896} /></h3>
                                    <p>Total owners</p>
                                </div>
                                <div className="col-md-1 col-sm-2 pt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="66" viewBox="0 0 53 66" fill="none">
                                        <path d="M25.036 54.106C23.7609 49.6652 21.808 45.4802 19.3166 41.8434C16.1069 37.1579 11.9402 33.2996 7.09675 31.0317C10.786 29.3898 13.9995 26.8555 16.8454 23.3912C20.4626 18.988 23.4681 13.1061 26.167 5.70697C29.7149 14.1811 32.9558 20.5788 36.6047 25.0197C38.7977 27.6887 41.18 29.7063 43.9176 31.0463C44.0753 31.1235 44.2339 31.1983 44.3933 31.2707C40.1532 33.4146 36.0787 36.907 32.6878 41.0085C29.5286 44.8296 26.8249 49.3391 25.036 54.106Z" stroke="white" stroke-width="4" />
                                    </svg>
                                </div>
                                <div className="col-md-2 col-sm-4 pt-3 text-center">
                                    <h3>0.55</h3>
                                    <p>Floor Price (BNB)</p>
                                </div>
                                <div className="col-md-1 col-sm-2 pt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="66" viewBox="0 0 53 66" fill="none">
                                        <path d="M25.036 54.106C23.7609 49.6652 21.808 45.4802 19.3166 41.8434C16.1069 37.1579 11.9402 33.2996 7.09675 31.0317C10.786 29.3898 13.9995 26.8555 16.8454 23.3912C20.4626 18.988 23.4681 13.1061 26.167 5.70697C29.7149 14.1811 32.9558 20.5788 36.6047 25.0197C38.7977 27.6887 41.18 29.7063 43.9176 31.0463C44.0753 31.1235 44.2339 31.1983 44.3933 31.2707C40.1532 33.4146 36.0787 36.907 32.6878 41.0085C29.5286 44.8296 26.8249 49.3391 25.036 54.106Z" stroke="white" stroke-width="4" />
                                    </svg>
                                </div>
                                <div className="col-md-3 col-sm-6 pt-3 text-center">
                                    <h3><CountUp end={25.5} />k</h3>
                                    <p>Volume Traded (BNB)</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


                <section className="tf-section easysteps">
                    <div className="easyy">
                        <div className="tf-container">
                            <h2 className="headingzz">How to Buy <span style={{ color: 'black' }}>Easy steps</span></h2>
                            <div className="row pt-5 pb-5" data-aos="fade-up" data-aos-duration="2000">
                                <div className="col-md-6 pt-5">
                                    <div className="row">
                                        <div className="col-md-2 col-4">
                                            <img src={icon1} alt="iconss" />
                                        </div>
                                        <div className="col-md-10 col-8">
                                            <h6>Connect Wallet</h6>
                                            <p>Select your wallet address and connect it.</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 pt-5">
                                    <div className="row">
                                        <div className="col-md-2 col-4">
                                            <img src={icon2} alt="iconss" />
                                        </div>
                                        <div className="col-md-10 col-8">
                                        <h6>Payment</h6>
                                            <p>Select the Payment of the card</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 pt-5">
                                    <div className="row">
                                        <div className="col-md-2 col-4">
                                            <img src={icon3} alt="iconss" />
                                        </div>
                                        <div className="col-md-10 col-8">
                                        <h6>Select the NFT card</h6>
                                            <p>Select the NFT card and view the price</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 pt-5">
                                    <div className="row">
                                        <div className="col-md-2 col-4">
                                            <img src={icon4} alt="iconss" />
                                        </div>
                                        <div className="col-md-10 col-8">
                                            <h6>Purchase</h6>
                                            <p>Once the payment is made.NFT card will added into your wallet</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 className="headingzz pt-5 pb-5">Latest <span style={{ color: 'black' }}>Artworks</span></h2>
                    </div>

                </section>

                <section className="tf-section  artwork">
                    {/* <h2 className="headingzz">Latest <span style={{ color: 'black' }}>Artworks</span></h2> */}
                    <div className="artworks">
                        <div className="tf-container">
                            <div className="row">
                                {/* {aboutlist1.map((item, index) => */}
                                {!selloding ?
                                    <>
                                        <div className="text-centre">
                                            <h3>Loading...</h3>

                                        </div>
                                    </>
                                    : SaleData[SaleData.tab]?.slice(0, 8).map((item, index) =>

                                    (<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 cardss">
                                        <Card
                                            product={item}
                                            type="marketplace"
                                            index={index}
                                            LikeList={LikeList}
                                            LikedTokenList={LikedTokenList}
                                            setLikedTokenList={setLikedTokenList}
                                            LikeForwardRef={LikeForwardRef}
                                        />

                                    </div>))}


                            </div>
                        </div>
                    </div>
                </section>



                <div className="nftback1">
                    <section class="design-section">

                        <h2 className="headingzz pb-5">Roadmap <span style={{ color: 'black' }}>of Joyfun </span></h2>
                        <img src={gold} className="golds" alt="golds" />
                        <img src={rose} className="rose1" alt="golds" />
                        <img src={rose} className="rose" alt="golds" />
                        <img src={green} className="greens" alt="golds" />
                        <img src={violent} className="violent1" alt="golds" />
                        <img src={leftcurve} className="leftcurve" alt="golds" />
                        <img src={rightcurve} className="rightcurve" alt="golds" />

                        <img src={goldround} className="goldround" alt="golds" />
                        <img src={greenround} className="greenround" alt="golds" />
                        <div class="timeline pt-3">
                            <div class="timeline-empty">
                            </div>
                            <div class="timeline-middle">
                                <div class="timeline-circle"></div>
                            </div>
                            <div class="timeline-component timeline-content">
                                <h6>Phase 1</h6>
                                <p>
    <span >Elderly Care Project NFT issued</span><br/>
    <span >Real NFT Fi White paper &amp; Website completed</span><br/>
    <span >Real NFT Fi Exchange Platform</span><br/>
    <span >JF Token issuance &amp; Exchange platform completed</span>
</p>
                            </div>
                            <div class="timeline-component timeline-content">
                                <h6>Phase 2</h6>
                                <p>
    <span >Staking NFT issuance</span><br/>
    <span > (Start of NFT banking solution applying Real NFT Fi solution)</span><br/>
    <span >Completion of NFT trading platform (NFT Marketplace)</span>
</p>
                            </div>
                            <div class="timeline-middle">
                                <div class="timeline-circle"></div>
                            </div>
                            <div class="timeline-empty">
                            </div>

                            <div class="timeline-empty">
                            </div>

                            <div class="timeline-middle">
                                <div class="timeline-circle"></div>
                            </div>
                            <div class=" timeline-component timeline-content">
                                <h6>Phase 3</h6>
                                <p>
    <span >NFT banking system integrated</span><br/>
    <span > (Deposit/loan/payment solution)</span><br/>
    <span >Completion of solutions related to NFT digital ownership and asset securitization</span><br/>
  
</p>
                            </div>
                            <div class="timeline-component timeline-content">
                                <h6>Phase 4</h6>
                                <p>
    <span >NFT utility environment (shopping/card integration - NFT ECO System)</span><br/>
    <span >NFT Banking Main Net development begins</span><br/>
  
</p>
                            </div>
                            <div class="timeline-middle">
                                <div class="timeline-circle"></div>
                            </div>
                            <div class="timeline-empty">
                            </div>
                            <div class="timeline-empty">
                            </div>

                            <div class="timeline-middle">
                                <div class="timeline-circle"></div>
                            </div>
                            <div class=" timeline-component timeline-content">
                                <h6>Phase 5</h6>
                                <p>
    <span >NFT utility ECO System completed</span><br/>
    <span >NFT Banking Main Net completed</span><br/>
    
</p>
                            </div>
                        </div>

                    </section >
                </div>

                <section className="nftmarket">
                    <div className="nftback">
                        <div className="tf-container">
                            <div className="row align-items-center">
                                <div className="col-md-6 pt-4" data-aos="zoom-in-right" data-aos-duration="1800">
                                    <img src={nftimg} className="nftimg" alt="golds" />
                                </div>
                                <div className="col-md-6 pt-4" data-aos="zoom-in-left" data-aos-duration="1800">
                                    <h2>JoyFun</h2>
                                    <h3>NFT with NFT Market</h3>
                                    <p className="mt-4">NFTs are special types of cryptos. Not only do they have monetary value, but they also have unique value. NFTs add beauty to the Crypto Market.
                                        <br></br><br></br>
                                        In a regular wallet, NFTs appear as tokens. Therefore, to be able to see the aesthetic side of an NFT, a different type of platform will be required. These platforms are known as NFT Marketplaces.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="faqsection">
                    <div className="faqlayer">
                        <div className="tf-container">
                            <div className="faqborder mb-5">
                                <h2 className="headingzz faqs pb-5">Frequently <span style={{ color: 'black' }}>Asked Question </span></h2>
                                <div className="faqsecborder" data-aos="fade-up" data-aos-duration="1500">
                                    <Accordion>

                                    { status ==true ?  <>
    <div className="text-centre">
        <h3>Loading...</h3>
                <div className="load-more">
                </div>
        </div>
       </> : faqList.length>0 && faqList.map((data,key) => {
                                    return(
                            <Accordion.Item eventKey={JSON.stringify(key)} >
                                <Accordion.Header >{ data.question }</Accordion.Header>
                                <Accordion.Body>
                                <div dangerouslySetInnerHTML={{__html:data.answer}}></div>  
                                </Accordion.Body>
                            </Accordion.Item>
                            )})}

                                    </Accordion>

                                </div>
                            </div>


                            <div className="teambanner pt-5 pb-5 mb-5">
                                <div className="row align-items-center">
                                    <div className="col-md-6 pt-4 leftgradient" data-aos="zoom-in-right" data-aos-duration="1500">
                                        <img src={gift} className="socialmedia" alt="golds" />
                                    </div>
                                    <div className="col-md-6 pt-4 pl-5 pr-5" data-aos="zoom-in-left" data-aos-duration="1500">
                                        <h2>JoyFun Platform</h2>
                                        <h3>One home</h3>
                                        <p className="mt-4">Our JoyFun platform offers a comprehensive platform where you can track your favorite cryptos, engage in DeFi, trade and mint NFTs and stay updated with crypto news.
                                            <br></br><br></br>
                                            Join us and navigate the crypto world with confidence!
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className="lastsection pt-5 pb-4">

                                <img src={violent} className="violent" alt="golds" />
                                <img src={gold} className="lastgolds" alt="golds" />
                                <img src={rose} className="lastrose" alt="golds" />
                                <img src={green} className="lastgreens" alt="golds" />

                                <img src={goldround} className="lastgoldround" alt="golds" />
                                <img src={greenround} className="lastgreenround" alt="golds" />

                                <div className="row">
                                    <div className="col-md-7 text-center mx-auto">
                                        <img src={homelogo} className="socialmedia mb-4" alt="golds" />
                                        <h6>Discover Digital Arts & Collect JoyFun NFTs</h6>
                                        <p className="pb-5">Full-service multi-currency banking, asset management, trading and advisory firm supporting blockchain companies. We are one of the only regulated financial firms that support blockchain and digital assets.</p>
                                        <form id="subscribe-form" onSubmit={() => NewsLetter()}><input type="email" placeholder="Info@yourmail.com" id="subscribe-email" value={email} onChange={(event) => { setEmail(event.target.value) }} /><button class="tf-button" type="button" id="subscribe-button" onClick={() => NewsLetter()} >Subscribe <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path d="M16 1C15.8787 0.514929 15.8787 0.514943 15.8786 0.51496L15.8784 0.515011L15.8778 0.515174L15.8755 0.515742L15.8671 0.517849L15.8354 0.525941C15.8076 0.53306 15.7666 0.543627 15.7132 0.557643C15.6063 0.585674 15.4495 0.627505 15.2478 0.683135C14.8445 0.794389 14.2618 0.960882 13.5411 1.18264C12.1001 1.62602 10.1052 2.29097 7.88799 3.17787C5.35215 4.1922 3.39332 5.20714 2.17534 5.89393C1.18157 6.45421 1.32738 7.83719 2.32547 8.26493C2.32548 8.26493 2.32548 8.26493 2.32548 8.26493L6.8122 10.1878L8.73507 14.6745C8.73507 14.6745 8.73507 14.6745 8.73507 14.6745C9.16277 15.6725 10.5457 15.8185 11.1061 14.8247C11.7929 13.6067 12.8078 11.6479 13.8221 9.11201C14.709 6.89478 15.374 4.89987 15.8174 3.45888C16.0391 2.73818 16.2056 2.1555 16.3169 1.75221C16.3725 1.55055 16.4143 1.3937 16.4424 1.28683C16.4564 1.23339 16.4669 1.19245 16.4741 1.16464L16.4822 1.13286L16.4843 1.12451L16.4848 1.12225L16.485 1.1216L16.485 1.12139C16.4851 1.12132 16.4851 1.12127 16 1ZM16 1L16.4851 1.12127C16.5277 0.95088 16.4777 0.770637 16.3536 0.646447C16.2294 0.522256 16.0491 0.472332 15.8787 0.514929L16 1Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></button></form>
                                        <div className="mt-4 lastseca">
                                            <ul className="social-item mt-4">
                                                {link.map((mlink) => (
                                                    <>
                                                        {
                                                            (mlink.website).toLowerCase() === "twitter" &&
                                                            <a href={mlink.link} target="blank">
                                                                <img src={twit} alt="dd" />
                                                            </a>
                                                        }
                                                        {(mlink.website).toLowerCase() === "instagram" &&
                                                            <a href={mlink.link} target="blank">

                                                                <img src={insta} alt="dd" />
                                                            </a>
                                                        }
                                                        {
                                                            (mlink.website).toLowerCase() === "facebook" &&
                                                            <a href={mlink.link} target="blank">

                                                                <img src={fac} width={20} alt="dd" />
                                                            </a>
                                                        }
                                                    </>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <Footer />
            </div >
        </>
    )
}