import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";
import Button from "react-bootstrap/esm/Button";
import ImgAudVideo from "../../separate/ImgAudVideo";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { useHistory, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty, NumANdDotOnly } from "../../actions/common";
import config from "../config/config";
import { CreateOrder } from "../../actions/axioss/nft.axios";
import Web3 from 'web3'
import randomInteger from 'random-int';
export function Lower({ text,owner, types, closePop, file, type, thumb, item }) {
  //console("OpenPopup", OpenPopup)
  const handleClose8 = () => setShow8(false);
  const [show4, setShow4] = useState(true);
  const [show8, setShow8] = useState(false);
  const handleClose4 = () => setShow4(false);
  const [BtnData, SetBtnData] = useState("start");
  const [TokenBtn, SetTokenBtn] = useState("start");
  const [Mintbtn, SetMintbtn] = useState("start");
  const [dropdown, setdropdown] = useState(false);
  const [dropdown1, setdropdown1] = useState(false);
  const [modal, setModal] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const { currency } = useSelector((state) => state.LoginReducer);
  const ContractCall = useContractProviderHook();
  const push = useNavigate();

  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { web3 } = useSelector((state) => state.LoginReducer.AccountDetails);
  const { sellerFees } = useSelector((state) => state.LoginReducer.ServiceFees);

  const [FormValue, SetFormValue] = useState(item);
  const [ValidateError, SetValidateError] = useState({});
  console.log('skgshkdfgds',item,text)
  useEffect(() => {
    if (!FormValue.CoinName) {
      SetFormValue({
        ...FormValue,
        ...{ ["CoinName"]: currency[0]?.label ?? "BNB" },
      });
    }

    SetFormValue({ ...FormValue, ...{ ["EmailId"]: payload.EmailId } });
  }, [currency]);

  const TokenApproveCall = async () => {
    SetTokenBtn("process");
    const id = toast.loading("Approve Processing");
    const cont = await ContractCall.SetApproveStatus(
      FormValue.ContractType == 721 || FormValue.ContractType == "721"
        ? "Single"
        : "Multiple",
      FormValue.ContractAddress
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
      closeButton:true,
      closeOnClick:true
    });
    if (cont.status) {
      SetTokenBtn("done");
      SetBtnData("process");
    } else SetTokenBtn("try");
  };

  const onSelectChange = (e, data) => {
    SetBtnData("start");

    const id = "CoinName";
    //console("ada", e,id)
    const { label, value } = e;
    SetFormValue({ ...FormValue, ...{ [id]: value } });
  };

  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(FormValue.NFTPrice);
  }, [FormValue.NFTPrice]);

  const onChange = (e) => {
    const { files, value, id } = e.target;
    console.log("price@123", value);
    SetBtnData("start");
    // var price = NumANdDotOnly(value)
    // console.log("price@1243",price);
    SetFormValue({ ...FormValue, ...{ [id]: NumANdDotOnly(value) } });
  };
  console.log("nftprice",FormValue.NFTPrice);

  const FormSubmit = async () => {
    SetBtnData("start");
    const id = toast.loading("Validating Form");
    var Error = Validation(FormValue);
    console.log('hdfhgdhsh',FormValue,owner,Error)
    SetBtnData("process");
    if (isEmpty(Error)) {
      let Respc = await ContractCall.Contract_Base_Validation();
      if (!Respc) {
        let Statu = await ContractCall.GetApproveStatus(
          FormValue.ContractType == 721 || FormValue.ContractType == "721"
            ? "Single"
            : "Multiple",
          FormValue.ContractAddress
        );
        if (Statu == true) {
          setShow4(false);
          setShow8(true);
          SetBtnData("process");
          toast.update(id, {
            render: "Ready To Place Order",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton:true,
            closeOnClick:true
          });
        } else {
          setShow4(false);
          setShow8(true);
          SetBtnData("open");
          toast.update(id, {
            render: "Get APProve",
            type: "success",
            isLoading: false,
            autoClose: 1000,closeButton:true,closeOnClick:true
          });
        }
      } else {
        SetBtnData("error");
        SetValidateError(Respc);
      }
    } else {
      // console.log("errortoasrt");
      setTimeout(() => {
        toast.update(id, {
          render: "Check Fields",
          type: "error",
          isLoading: false,
          autoClose: 1000,closeButton:true,closeOnClick:true
        });
        SetBtnData("error");
        SetValidateError(Error);
      }, 1000);
      
    }
  };
  const closemodal = () => SetBtnData("start");

  const Validation = (data) => {
    let ValidateError = {};
    const { NFTPrice, CoinName, PutOnSaleType, ClockTime, EndClockTime, NFTQuantity, ContractType } = data;
    console.log('errrorrrr',ContractType,ContractType === '1155' || ContractType === 1155,NFTQuantity,owner?.NFTBalance,Number(NFTQuantity) > Number(item?.NFTBalance))
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      isEmpty(NFTPrice)
    )
      ValidateError.NFTPrice = "Token Price Required";
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      !CoinName
    )
      ValidateError.CoinName = "CoinName Required";
    if (PutOnSaleType == "TimedAuction" && !ClockTime)
      ValidateError.ClockTime = "ClockTime Required";
    if (PutOnSaleType == "TimedAuction" && !EndClockTime)
      ValidateError.EndClockTime = "EndClockTime Required";
    if(ContractType === '1155' || ContractType === 1155){
      if(Number(NFTQuantity) > Number(owner?.NFTBalance)){
        ValidateError.NFTQuantity = "Quantity Must Be Less Than "+owner?.NFTBalance
      }
    }
    return ValidateError;
  };

  const onChangeTab = (e, newValue) => {
    SetBtnData("start");
    SetFormValue({
      ...FormValue,
      ...{ ["PutOnSaleType"]: newValue, ["FixedPrice"]: "", ["CoinName"]: "" },
    });
  };

  const onSlectDate = (data, up) => {
    SetBtnData("start");
    setdropdown(false);
    setdropdown1(false);
    if (up == "RightAfterClick") {
      var date = new Date().setMinutes(20);
    } else if (up == "one" || up == "two") {
      var date = new Date().setDate(
        up == "one"
          ? 1 + new Date().getDate()
          : up == "two"
          ? 2 + new Date().getDate()
          : 0
      );
    } else setModal(data);

    var formvalue = {
      ...FormValue,
      ...{ [data]: moment(date).format("YYYY-MM-DD HH:mm:ss") },
    };
    SetFormValue(formvalue);
  };

  const setClockValue = (data, date) => {
    SetFormValue({
      ...FormValue,
      ...{ [data]: moment(date).format("YYYY-MM-DD HH:mm:ss") },
    });
  };
console.log("ENNADATAVARUTHU",FormValue,owner,owner.HashValue)
  async function ListCall() {
    const id = toast.loading("Listing Processing");
    try{
    
    if(owner.HashValue !== "not minted"){
      console.log("RR");
      SetMintbtn("process");
      if (FormValue.PutOnSaleType == "FixedPrice") {
        var error = await ContractCall.Contract_Base_Validation();
        if (error)
          toast.update(id, {
            render: error,
            type: "error",
            isLoading: false,
            autoClose: 1000,closeButton:true,closeOnClick:true
          });
        else {
          const cont = await ContractCall.place_order_721_1155(
            owner.NFTId,
            web3.utils.toWei(FormValue.NFTPrice?.toString()),
            FormValue.ContractAddress,
            owner.NFTOwner,
            Number(FormValue.ContractType),
            "order"
          );
          console.log("cont",cont)
          if (cont) {
            let _data = FormValue;
            _data.NFTOwner = payload.WalletAddress;
            _data.HashValue = cont.HashValue;
            _data.NFTId = owner.NFTId;
            _data.activity = "PutOnSale";
            _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
            BackCall(id, _data);
          } else {
            console.log("json fil")
            toast.update(id, {
              render: "Transaction Failed",
              type: "error",
              isLoading: false,
              autoClose: 1000,closeButton:true,closeOnClick:true
            });
            console.log("ewjewkljelwjrkwejkrweklr")
            SetMintbtn("try");
          }
        }
      } else {
        let _data = FormValue;
        _data.NFTOwner = payload.WalletAddress;
        _data.HashValue = "";
        _data.NFTId = owner.NFTId;
        _data.activity = "PutOnSale";
        _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
  
        BackCall(id, _data);
      }
    }
    else{
      if(text== "ChangePrice" || text == "Put On Sale"){
        var sign_det=await _signcall()
        let _data = FormValue;
console.log("changedata",_data,sign_det)
        _data.NFTOwner = payload.WalletAddress;
        _data.HashValue = "not minted";
        _data.Hash=sign_det.hash
        _data.Randomname=sign_det.randomname
        _data.Nonce=sign_det.nonce
        _data.NFTId = owner.NFTId;
        _data.activity = "PutOnSale";
        _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
  
        BackCall(id, _data);
      }
      else{
        let _data = FormValue;
        _data.NFTOwner = payload.WalletAddress;
        _data.HashValue = "";
        _data.NFTId = owner.NFTId;
        _data.activity = "PutOnSale";
        _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
  
        BackCall(id, _data);
      }
        

    }
  }
  catch(err){
    toast.update(id, {
      render: "Transaction Failed",
      type: "error",
      isLoading: false,
      autoClose: 1000,closeButton:true,closeOnClick:true
    });
    console.log("ERRIn",err)
  }
   
  }

  const _signcall =async()=>{
    try{
    
                    if (web3) {
                      var generator = require('generate-password');
                      const passwords = require('secure-random-password');
                      var TokenPrice=FormValue.NFTPrice;
                      var randomNum = 
                      randomInteger(10000000, 100000000);
                      console.log("sdcfsdfsdfsdvsdfdsf", randomNum,TokenPrice);
                      
                         console.log('kshskjhfsjkfs',passwords)
                         const password = passwords.randomPassword({ length: 10, characters: [passwords.lower, passwords.upper, passwords.digits]  })
                         
                      console.log("Password", password);
                      if (web3) {
                        // console.log("checking window.ethereum", web3.givenProvider,Wallet_Details.providerss)
                        var web3RpcPro = new Web3(web3.providers);
                        console.log("asddfsfsdvbvbvcb", web3RpcPro, payload.WalletAddress);
                        const to =  payload.WalletAddress
                        const _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3.utils.toWei(String(TokenPrice));
                        console.log("ajhghjas", _amount);
                        const _nonce = Date.now();
                        console.log("qwewqewqreqwrqrq", password);
                
                      //   setRandomName(password)
                        const tot = _nonce + Number(randomNum);
                      //   setNonceHash(tot);
                        console.log("signparametes", to, _amount, password, tot);
                        const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
                        console.log("signresult", result);
                        const signhash = await web3.eth.personal.sign(result, to);
                        console.log("Signature",signhash,password,tot,to)
                        if (signhash) {
                          // setSignatureHash(signhash);
                          return ({hash : signhash,
                            randomname:password,
                            nonce : tot
                          })
                        }
                      }
                   }
                }
                catch(err){
                   console.log('signer',err)
                }
 }
  const BackCall = async (id, _data) => {
    
    let Resp = await CreateOrder(_data);
    if (Resp.success == "success") {
      toast.update(id, {
        render: "Listed Successfully",
        type: Resp.success,
        isLoading: false,
        autoClose: 1000,closeButton:true,closeOnClick:true
      });
      SetMintbtn("done");
      push("/profile/" + payload.CustomUrl,{state:{Tab:'owned'}});
    } else {
      console.log("dsahgdhasgjgdjasd")
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,closeButton:true,closeOnClick:true
      });
      SetMintbtn("try");
    }
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show4}
        className="putonsale"
      >
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={closePop}
          ></button>
          <Modal.Title id="contained-modal-title-vcenter">
          {text == "ChangePrice" ? <>Change Price</> : <>  Put on Sale</>}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="approvetransaction">
            <div className="item_imgs text-center">
              <ImgAudVideo
                file={`${config.IMG_URL}/nft/${item.NFTCreator}/Compressed/NFT/${item.CompressedFile}`}
                type={
                  item.CompressedFile
                    ? item.CompressedFile?.includes(".webp")
                      ? "image"
                      : item.CompressedFile.includes(".webm")
                      ? "video"
                      : "audio"
                    : item.CompressedFile
                }
                thumb={`${config.IMG_URL}/nft/${item.NFTCreator}/Compressed/NFT_THUMB/${item.CompressedThumbFile}`}
                from="info"
                origFile={`${config.IMG_URL}/nft/${item.NFTCreator}/Original/NFT/${item.OriginalFile}`}
                className="img-fluid"
              />
              {/* <img src={Image} alt="imgae" className='img-fluid'/> */}
            </div>
            <div className="putonsale">
            <p className="putonsalede mt-4">
              It is a long established <span>fact</span> that a reader will be
              <span>0x02d54f...</span> distracted by the readable content
            </p>
            </div>
            <div className="putonsale">
              <div>
                <h2 className="putonsasle">{text == "ChangePrice" ? <>Change Price</> : <>  Put on Sale</>}
</h2>
                {/* <p className='receives'>You’ll receive bids on this item</p> */}
              </div>
              {/* <div className="toggleswitch">
                                  <button className="switch toggle_custom" onClick={() =>setShow4(!show4)}><input type="checkbox" id="putonsale" name="putonsale"  /><span  className={show4?"slider":"overleft"} ></span></button>
                                  </div> */}
            </div>
            <div className={show4 ? "putonsalefixed" : "tabbable-line"}>
              {/* <ul className="nav nav-tabs ">
                      <li className="active">
                          <a href="#fixedprice" data-toggle="tab">
                          <div className="fixedprice">
                                      <i className="fal fa-dollar-sign"></i>
                                      <p>Fixed Price</p>
                                  </div></a>
                      </li>
                      <li>
                          <a href="#timeauction" data-toggle="tab">
                              <div className="fixedprice">
                                      <i className="fas fa-clock"></i>
                                      <p>Timed Auction</p>
                                  </div></a>
                      </li>
                  </ul> */}
              <div class="tab-content mt-5">
                <div class="tab-pane active" id="fixedprice">
                  <div className="fixedpricedropdown mb-4">
                    <div className="formgroupsec text-left">
                      <label className="text-left mb-3 receives">Enter your Price</label>
                      <div className="fixedpricedropdownflex">
                        <input
                          type="text"
                          autoComplete="off"
                          className=""
                          placeholder="e.g: 0.001"
                          onChange={onChange}
                          id="NFTPrice"
                          value={FormValue.NFTPrice}
                          maxLength={7}
                        />
                        {(FormValue.PutOnSaleType == "FixedPrice" ||
                          FormValue.PutOnSaleType == "TimedAuction") &&
                          ValidateError.NFTPrice && (
                            <div className="error_msg">
                              {ValidateError.NFTPrice}
                            </div>
                          )}
                        {/* <div></div> */}
                        <div className="mt-5 coins">
                        <label className="text-left mb-1 receives">Choose Coin Type</label>
                          <Select
                          // menuIsOpen={true} 
                            defaultValue={selectedOption}
                            placeholder={<div className="select-placeholder-text">Select category</div>} 
                            isSearchable={false}
                            onChange={(e) => onSelectChange(e, "price")}
                            value={
                              FormValue.PutOnSaleType
                                ? FormValue.PutOnSaleType == "FixedPrice"
                                  ? {
                                      label: FormValue.CoinName,
                                      value: FormValue.CoinName,
                                    }
                                  : currency.filter(
                                      (item) => item.value !== "BNB"
                                    )[0]
                                : currency[0]
                            }
                            options={
                              FormValue.PutOnSaleType == "FixedPrice"
                                ? currency
                                : currency.filter((item) => item.value != "BNB")
                            }
                            id="CoinName"
                            classNamePrefix="react-select"
                          />
                        </div>
                        {(FormValue.PutOnSaleType == "FixedPrice" ||
                          FormValue.PutOnSaleType == "TimedAuction") &&
                          ValidateError.CoinName && (
                            <div className="error_msg">
                              {ValidateError.CoinName}
                            </div>
                          )}
                      </div>
                      {/* <p className="pt-4">Service fee1% You will receive 0XDC $( 0)</p> */}
                    </div>
                    {
                      (FormValue.ContractType === 1155 || FormValue.ContractType === "1155" ) &&
                    <div className="formgroupsec text-left">
                      <label className="text-left mb-3 receives">Enter Quantity</label>
                      <div className="fixedpricedropdownflex">
                      <input
                          type="text"
                          autoComplete="off"
                          className=""
                          placeholder="e.g.10"
                          onChange={onChange}
                          id="NFTQuantity"
                          value={FormValue.NFTQuantity}
                        />
                        {(FormValue.PutOnSaleType == "FixedPrice" ||
                          FormValue.PutOnSaleType == "TimedAuction") &&
                          ValidateError.NFTQuantity && (
                            <div className="error_msg">
                              {ValidateError.NFTQuantity}
                            </div>
                          )}
                        </div>
                        </div>
                      }
                  </div>
                </div>
                <div class="tab-pane" id="timeauction">
                  <div className="minimumbiddropdown mb-4">
                    <div className="formgroupsec">
                      <label>Minimum bid</label>
                      <div className="fixedpricedropdownflex">
                        <div>
                          <input
                            className=""
                            type="text"
                            placeholder="Enter minimum bid"
                          />
                        </div>
                        <div>
                          <p>BLKZ</p>
                        </div>
                      </div>
                      <p>
                        Bids below this amount won't be allowed. If you not
                        enter any amount we will consider as 0
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {FormValue.PutOnSaleType == "FixedPrice" && (
              <>
              <div className="d-flex justify-content-between">
                <div className="servicefee">
                  <p>Service Fee {web3.utils.fromWei(String(sellerFees))} %</p>
                </div>
                <div className="servicefee">
                  <p>You will Get {YouWillGet ?? 0}</p>
                </div>
                </div>
              </>
            )}

            <div className="connecwalletbtns mt-5 d-flex justify-content-between">
          
              <Button
                className="connectwallet secondary"
                onClick={BtnData == "start" ? FormSubmit :null}
              >
                {BtnData == "start" && text}
                {BtnData == "error" && text}
                {BtnData == "process" && "processing"}
                {BtnData == "done" && "Done"}
              </Button>
              <button className="cancelpayment primary" onClick={closePop}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show8}
      >
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={closePop}
          ></button>
          <Modal.Title id="contained-modal-title-vcenter">
            Follow Steps
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          { (owner.HashValue !== "not minted" && !isEmpty(owner.HashValue)) ?
          
          BtnData == "open" && (
            <div className="ifpsmetadata">
              <div className="ifpsmetadatasec  d-flex justify-content-start align-items-center">
                <i className="fa fa-check"></i>
                <div className="text-left ml-5">
                  <h6 className="receives">Approve Call</h6>
                  <p className="receives">This Process Take One Time Gas Fees</p>
                </div>
              </div>
              <Button
                className="connectwallet primary my-3"
                disabled={
                  TokenBtn == "process" || TokenBtn == "done" ? true : false
                }
                onClick={
                  TokenBtn == "start" || TokenBtn == "try"
                    ? TokenApproveCall
                    : null
                }
                disableRipple
              >
                {TokenBtn == "start" && "Start"}
                {TokenBtn == "process" && "In-Progress"}
                {TokenBtn == "try" && "Try-Again"}
                {TokenBtn == "done" && "Done"}
              </Button>
            </div>
          )

          :
          ""
          
          }
          

          <div className="ifpsmetadata mt-4">
            <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
              <i className="fa fa-check"></i>
              <div className="text-left ml-5">
                <h6  className="receives">Put on Sale</h6>
                <p  className="receives">Call contract method</p>
              </div>
            </div>
            <Button
              className="connectwallet primary my-3"
              disabled={
                Mintbtn == "process" || Mintbtn == "done" ? true : false
              }
              onClick={Mintbtn == "start" || Mintbtn == "try" ? ListCall : ""}
            >
              {Mintbtn == "start" && "Start"}
              {Mintbtn == "process" && "In-Progress"}
              {Mintbtn == "try" && "Try-Again"}
              {Mintbtn == "done" && "Done"}
              {Mintbtn == "init" && "Start"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
