import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Header from "../separate/Header1";
import Footer from "../separate/Footer1";
import leftcurve from '../assets/images/joyimg/leftcurve.png';
import rightcurve from '../assets/images/joyimg/rightcurve.png';
import rubyimg from "../assets/images/joyimg/ruby.png";
import diamondimg from "../assets/images/joyimg/Frame1.png";
import goldimg from "../assets/images/joyimg/goldbg.png";
import bronzeimg from "../assets/images/joyimg/bronze.png";
import silverimg from "../assets/images/joyimg/silver.png";
export default function Dummyartwork(props) {
console.log("priview",props)
var imgarr=[rubyimg,diamondimg,goldimg,bronzeimg,silverimg]
var title= [ "NFTname","Not For Sale","Staked"]
const [dummycards, setdummycards] = useState([
    { "id": 1, "name": "Joyfun", "amount": "0.001 BNB", "image": require("../assets/images/joyfunflowerart.png") }, { "id": 2, "name": "Joyfun", "amount": "0.038 BNB", "image": require("../assets/images/joyfunflowerart.png") }, { "id": 3, "name": "Joyfun", "amount": "0.096 BNB", "image": require("../assets/images/joyfunflowerart.png") }
])
    return (
        dummycards?.map((e)=>(
            <>
            <div className="inheaders">


    <div className="sc-product style1" 
    style={{background: `url(${imgarr[Math.floor(Math.random() * 3) + 1]})`}}
   
    >




      
    
      <div className="features">
        <div
          className="product-media  audImgVid"
        
        >
          
            <img src={e.image}/>
        

        
        </div>
       
       
      </div>
      {/* {console.log("productttt", product)} */}
      <div className="bottom pads">
        <div className="text-center fullname">
        {e.name}
        </div>
        <div>
          <p className="ETHno">{e?.amount} </p>
        </div>
        <div className="product-buttons buynowbuttoon">
          
              
              <span className="icon-btn-product"></span>
             BUY NOW
          </div>

      </div>


  </div>     
  </div>   
      </>
        )


    )
    )
}